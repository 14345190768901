//Customizable Area Start
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
//@ts-ignore
import profileReview from '../../../web/public/profileReview.jpg'
import CustomButton from "../../../components/src/CustomButton";
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import '../assets/DocumentReviewWeb.scss'
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')

import DocumentReviewWebController, {
  Props,
} from "./DocumentReviewWebController.web";
class DocumentReviewWeb extends DocumentReviewWebController {

  constructor(props: Props) {
    super(props);
  }
  
  render() {
    return (
     
      <div className="container">
      <div className="banner w-50">
        <img className="logo" src={Logo} />
        <img className="banner-img1" src={AuthBanner} />
        <p className="rights-reserved">
          Grow Cart © 2021. All Rights Reserved
        </p>
      </div>
      <div className="auth-form-wrapper w-50">
      <div className="auth-form">
                <div className="login-form">
                  <div className="review-img-div">
                  <img src={profileReview} alt="" className="review-img"/>
                  </div>
                  <div className="review-line-div">
                  <h4 className="review-line">Your profile is under review</h4>
                  </div>
                  <p className="extra-content">Your profile will be approved by the admin after verifying the information.</p>
                  <br /><br />
                  <div>
                    <CustomButton
                      className="login-button"
                      name="login-loading"
                      id="login-loading"
                      variant="contained"
                      disabled={this.state.disableBtn}
                      // loading={this.state.disableLogin}
                      onClick={(e) => {
                        this.getAccountData()
                         this.props?.history?.push("/")
                      }}
                    >
                      BACK TO HOMEPAGE
                    </CustomButton>
                  </div>
                </div>
              </div>
      </div>
    </div>
    );
  }
}
export default DocumentReviewWeb;
//Customizable Area End
