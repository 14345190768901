import React, { Component } from 'react'
import { View, StyleSheet, Text, ActivityIndicator, Platform } from 'react-native';
import COLOR_CONST, { FONTS } from '../../../../framework/src/AppFonts';
//@ts-ignore
import Spinner from 'react-native-loading-spinner-overlay';
import styles from './AppLoaderStyle';

export interface Props {
  navigation: any;
  id: string;
  isFetching: boolean;
}

export interface S {
  // Customizable Area Start
  isLoading: boolean;
  // Customizable Area End
}
export default class ApplicationLoader extends Component<Props, S> {
  constructor(props: any) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  render() {
    if (!this.props.isFetching) {
      return null;
    } else {
      return (
        <View style={styles.container}>
          {Platform.OS === 'ios' ? <ActivityIndicator size="large" color={COLOR_CONST.greencolor} /> :
            <Spinner
              cancelable
              color={COLOR_CONST.greencolor}
              visible={true}
              textStyle={{ color: '#fff' }}
            />}
        </View>
      )
    }
  }
}