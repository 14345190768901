import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  FlatList,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  Dimensions
  // Customizable Area End
} from "react-native";
import scale, { verticalScale } from "../../../framework/src/Scale";
import LandingPage2Controller, {
  Props,
  configJSON
} from "./LandingPage2Controller";
import styles from "./LandingPage2Style";
import * as IMG_CONST from "./assets";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import RNInput from "../../../components/src/RNInput";
import ModalWrapper from "../../../components/src/ModalWrapper";
import Typography from "../../../components/src/Typography";
import { Colors, dimensions } from "../../../components/src/constants";
import Header from "../../../components/src/RNHeader";
import { AppLoader, Skeleton } from "../../../components/src/Index";

import { translate } from "framework/src/Translation";


export default class LandingPage2 extends LandingPage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderHeader = () => {
    return (
      <View>
        <View style={styles.signupTextContainer}>
          <Image source={IMG_CONST.GROW_CART} style={styles.growCart} />
          <TouchableOpacity onPress={()=> this.props.navigation.navigate("MobileNotifications")}>
            <Image source={IMG_CONST.Notifiction} style={styles.NotifictionIcon} />
            {this.state.isUnreadNotificationsExists && <View style={styles.unread} />}
          </TouchableOpacity>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  totalNewOrdersEarningsView = () => {
    if(this.state.loading){
      return (
        <View style={styles.totalNewOrdersEarningsView}>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
        <View style={styles.NewOrderSkeltonMainViewStyle}>
        <Skeleton
          key={"1"}
          height={(dimensions.wp(98) - dimensions.wp(5)) / 2}
          width={(dimensions.wp(100) - dimensions.wp(5)) / 2}
          style={styles.totalsSkeleton}
        />
        </View>
        <View style={styles.NewOrderSkeltonMainViewStyle}>
        <Skeleton
          key={"2"}
          height={(dimensions.wp(80) - dimensions.wp(5)) / 2}
          width={(dimensions.wp(100) - dimensions.wp(5)) / 2}
          style={styles.totalsSkeleton}
        />
        </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
        <View style={styles.NewOrderSkeltonMainViewStyle}>
        <Skeleton
          key={"3"}
          height={(dimensions.wp(80) - dimensions.wp(5)) / 2}
          width={(dimensions.wp(100) - dimensions.wp(5)) / 2}
          style={styles.totalsSkeleton}
        />
        </View>
        <View style={styles.NewOrderSkeltonMainViewStyle}>
        <Skeleton
          key={"4"}
          height={(dimensions.wp(80) - dimensions.wp(5)) / 2}
          width={(dimensions.wp(100) - dimensions.wp(5)) / 2}
          style={styles.totalsSkeleton}
        />
        </View>
        </View>
        </View>
      )
    }
    return (
      <View style={styles.totalNewOrdersEarningsView}>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          <View style={styles.NewOrdersMainViewStyle}>
            <View style={styles.NewOrdersYollowLineStyle} />
            <View style={styles.NewOrdersTextViewStyle}>
              <Typography style={styles.countTextStyle}>{this.state.DashboardData?.new_orders}</Typography>
              <Typography style={styles.newOrdersTextStyle}>{translate("new_orders")}</Typography>
            </View>
          </View>
          <View style={styles.NewOrdersMainViewStyle}>
            <View style={styles.NewOrdersYollowLineStyle} />
            <TouchableOpacity onPress={()=> this.props.navigation.navigate("TotalOrTodaysEarnings",{isFrom:'todays'})} >
            <View style={styles.NewOrdersTextViewStyle}>
              <Typography style={styles.countTextStyle}>{'₹ '+this.state.DashboardData?.todays_earnings}</Typography>
              <Typography style={styles.newOrdersTextStyle}>{translate("todays_earnings")}</Typography>
            </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          <View style={styles.NewOrdersMainViewStyle}>
            <View style={styles.NewOrdersYollowLineStyle} />
            
            <View style={styles.NewOrdersTextViewStyle}>
              <Typography style={styles.countTextStyle}>{this.state.DashboardData?.total_orders}</Typography>
              <Typography style={styles.newOrdersTextStyle}>{translate("total_orders")}</Typography>
            </View>
          </View>
          <View style={styles.NewOrdersMainViewStyle}>
            <View style={styles.NewOrdersYollowLineStyle} />
            <TouchableOpacity onPress={()=> this.props.navigation.navigate("TotalOrTodaysEarnings",{isFrom:'total'})} >
            <View style={styles.NewOrdersTextViewStyle}>
              <Typography style={styles.countTextStyle}>{'₹ '+this.state.DashboardData?.total_earnings}</Typography>
              <Typography style={styles.newOrdersTextStyle}>{translate("total_earnings")}</Typography>
            </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  graphView = () => {
    return (
      <View>

        <View style={styles.grapWheeklyTextMainView}>
          <TouchableOpacity onPress={() => this.setState({ weeklyMonthlyPopup: true, dateMonthlyPopup: false, })} style={styles.grapWheeklyTextView}>
            <Typography style={styles.grapWheeklyText}>{this.state.selectedMonth ? this.state.selectedMonth : "Weekly"}</Typography>
            <Image source={IMG_CONST.DOWN_ARROW} style={styles.grapWheeklyTextImageIcon} />
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => this.setState({ dateMonthlyPopup: true, weeklyMonthlyPopup: false })} style={styles.grapWheeklyTextView}>
            <Text style={{ fontSize: scale(15), fontFamily: FONTS.PoppinsDisplayMedium, color: "gray" }}>{this.state.selectedDate ? this.state.selectedDate : "3 May - 9 May 2021"}</Text>
            <Image source={IMG_CONST.DOWN_ARROW} style={{ width: scale(10), height: scale(10), marginLeft: scale(5), tintColor: "gray" }} />
          </TouchableOpacity> */}
        </View>
        {this.state.weeklyMonthlyPopup && this.renderMonthViewFlatList()}
        {/* {this.state.dateMonthlyPopup && this.renderdateMonthlyPopupFlatList()} */}
        {this.renderWeeklyChart(this.state.chartWeekLetters)}
       
      </View>
    )
  }
  renderWeeklyChart = (weeks:any) => {
    const maxearning = Math.max(...weeks.map((week:any) => week.value));
    const divisible = (maxearning >= 10000 && maxearning<100000) ? 1000 : (maxearning >=100000 && maxearning<10000000) ? 100000 : maxearning<=10000 ? 100 : 0;
    const maxceil = (Math.ceil(maxearning/divisible)*divisible)/10;
    const maxvalue = Math.ceil(maxearning/divisible)*divisible
    console.log("Array Max Value>>",maxearning,maxceil,maxvalue,this.numFormatter(920));

    return (
      <View style={{ width: "94%", height: scale(250), flexDirection: "row", marginLeft: scale(15),marginRight: scale(20) }}>
          <View style={{ justifyContent: "space-evenly", height: scale(230) }}>
            {[10,9,8,7,6,5,4,3,2,1,0].map((i:number)=> {
              return (
              <Typography>{ '₹'+ this.numFormatter(i*maxceil) }</Typography>
              );
            })}
          </View>
          <ScrollView horizontal={true}>
            <View  style={{ flexDirection: 'row', justifyContent: 'space-around', width: "100%", height: scale(250) }} >
            {weeks.map((data:any)=>{
              let value = (Math.round((data.value/maxvalue)* 100)>92 && Math.round((data.value/maxvalue)* 100)<=100) ? Math.round((data.value/maxvalue)* 100)-8 : Math.round((data.value/maxvalue)* 100);
              console.log("da>>",data.label,data.value,value);
              return (
                <View style={{ width:dimensions.wp(12), justifyContent: 'flex-end', alignItems: 'center' }}>
                  <View style={{ width: scale(14),maxHeight:scale(220), height: value+'%', backgroundColor: "green", margin:0,padding:0, borderTopRightRadius: scale(8), borderTopLeftRadius: scale(8) }}></View>
                  <Typography style={{margin:0,padding:0}}>{data.label}</Typography>
                </View>
              );
            })}
            </View>
          </ScrollView>
        </View>
    );
  }

  weeklyMonthlyPopup = (item: any, index: any) => {
    return (
      <View style={{ backgroundColor: "#fff", }}>
        <TouchableOpacity style={{ marginTop: verticalScale(3), height: scale(30), marginLeft: scale(20), justifyContent: 'space-around' }} onPress={() => this.setState({ selectedMonth: item.month, weeklyMonthlyPopup: false, },()=>{
          this.getDashBoardData(this.state.selectedMonth);
        })}>
          <Typography style={{ fontFamily: FONTS.PoppinsDisplayRegular, fontSize: scale(14) }}>{item.month}</Typography>
        </TouchableOpacity>
      </View>
    )
  }

  renderMonthViewFlatList = () => {
    return (
      <View style={styles.renderMonthMainViewFlatList}>
        <FlatList
          data={this.state.renderMonthViewFlatList}
          renderItem={({ item, index }) => this.weeklyMonthlyPopup(item, index)}
        />
      </View>
    )
  }

  dateMonthlyPopup = (item: any, index: any) => {
    return (
      <View style={{ backgroundColor: "#fff", }}>
        <TouchableOpacity style={{ marginTop: verticalScale(5), height: scale(40), marginLeft: scale(20), justifyContent: 'space-around' }} onPress={() => this.setState({ selectedDate: item.date, dateMonthlyPopup: false, })}>
          <Typography style={{ fontFamily: FONTS.PoppinsDisplayRegular, fontSize: scale(15) }}>{item.date}</Typography>
        </TouchableOpacity>
      </View>
    )
  }

  renderdateMonthlyPopupFlatList = () => {
    return (
      <View style={styles.renderdateMonthlyPopupMainFlatList}>
        <FlatList
          data={this.state.renderdateMonthlyPopupFlatList}
          renderItem={({ item, index }) => this.dateMonthlyPopup(item, index)}
        />
      </View>
    )
  }

  recentlySoldProductsView = () => {
    if(this.state.loading){
      return [0,1,2].map((x) => (
        <Skeleton
          key={`${x}`}
          height={(dimensions.wp(80) - dimensions.wp(5)) / 2}
          width={(dimensions.wp(80) - dimensions.wp(5)) / 2}
          style={styles.product}
        />
      ));
    }
    return (
      <View>
        <View style={[styles.marginContainer, styles.row]}>
              <Typography size={15} style={{fontWeight:'bold'}}>{translate("recently_sold_products")}</Typography>
              <TouchableOpacity onPress={() => this.props.navigation.navigate("RecentlySoldProducts")}>
                <Typography color="orange">{translate("view_all")}</Typography>
              </TouchableOpacity>
            </View>
        <FlatList data={this.state.recentlySoldItems}
          horizontal
          renderItem={({ item, index }) => this.flatListView(item)} />
      </View>
    )
  }

  flatListView = (item: any) => {
    return (
      <TouchableOpacity key={item.id} style={styles.product} onPress={()=> this.props.navigation.navigate("MProductDetails",{id:item?.attributes?.id})}>
          <View style={styles.imageContainer}>
            <Image source={{uri: item.attributes?.images?.[0]?.url}} style={styles.productImage} />
          </View>
          <Typography lines={2} size={12} style={{width:(dimensions.wp(80) - dimensions.wp(5)) / 2}}>{item.attributes?.name}</Typography>
          <Typography size={11} color="greyText">{ item.attributes.value+' '+ item.attributes.quantity}</Typography>
          <Typography size={12}>{item.attributes.value? `₹ ${item.attributes.price}`: null}</Typography>
        </TouchableOpacity>
    )
  }

 

  render() {
    //console.log("data>>",JSON.stringify(this.state.DashboardData));
    return (
      <View style={styles.container}>
        {this.renderHeader()}
        <ScrollView>
          {this.totalNewOrdersEarningsView()}
          {this.graphView()}
          {this.recentlySoldProductsView()}
        </ScrollView>
        <AppLoader isFetching={this.state.loading} {...this.props} />
      </View>
    );
  }
}

// Customizable Area Start
// Customizable Area End
