// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from "js-cookie";
import { runEngine } from "../../../framework/src/RunEngine";
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

// export const configJSON = require("./config.js");

export interface Props {
  //   navigation: any;
  //   id: string;
  //   history: any
  activeTab: number;
}
interface S {
  progress: boolean;
  showLogoutModal: boolean;
  mobileMenu: boolean;
}
interface SS {
  id: any;
}

export default class BuyerSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      progress: false,
      showLogoutModal: false,
      mobileMenu: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  static contextType?: Context<any> | undefined = AppContext;
  async componentDidMount() {}

  setActiveTab = (num: number) => {};
  displaySidebar = (value: boolean) => {
    this.setState({ mobileMenu: value });
  };

  setShowLogoutModal = (bool: boolean) => {
    this.setState({ showLogoutModal: bool });
  };
}
// Customizable Area End
