import React, { PureComponent } from "react";
import { StyleSheet, Text, StyleProp, TextStyle } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { Colors, dimensions, ColorName, fonts, FontName } from "./constants";

type DefaultProps = Readonly<typeof defaultProps>;

type Props = {
  children: React.ReactNode;
  style: StyleProp<TextStyle>;
  color: ColorName;
  font: FontName;
} & Partial<DefaultProps>;

const defaultProps = {
  color: "black",
  size: 13,
  style: {},
  lines: 0,
  font: "REG",
  mb: 0,
};

class Typography extends PureComponent<Props & DefaultProps> {
  
  render() {
    const { lines, style, children, size, color, font, mb } = this.props;

    return (
      <Text
        numberOfLines={lines ? lines : 0}
        style={[getStyles(color, font, size, mb).textStyle, style]}
      >
        {children}
      </Text>
    );
  }

  static defaultProps = defaultProps;
}

const getStyles = (color: ColorName, font: FontName, size: number, mb: number) => {
  return StyleSheet.create({
    textStyle: {
      color: Colors[color],
      fontFamily: fonts[font],
      fontSize: RFValue(size),
      marginBottom: dimensions.hp(mb),
    },
  });
}

export default Typography;
