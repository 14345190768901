//Customizable Area Start
import React, { useState } from "react";
import Carousel from 'react-material-ui-carousel'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { withStyles, Theme, createStyles,useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "axios";
import '../assets/ProductsWeb.scss';
const closeIcon = require("../../../components/src/close.png").default
//@ts-ignore
import Cookies from 'js-cookie'
import { configJSON } from "./ProductQuickviewController";
import { toast } from "react-toastify";
const configURL = require('../../../framework/src/config');

const ShowProdDetails = (props:any)=>{
    const {rowInfo, setEditProd, setShowDetails, updateProdList, setUpdateProdList} = props;
    const [checked, setChecked] =useState(rowInfo.attributes.out_of_stock);
    const [loader,setLoader]  =useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const handleEditProd = () => {
        setShowDetails(false);
        setEditProd(true);
    }

    const handleDeleteProd = () => {
        setDeleteLoader(true)
        axios.delete(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowInfo.id}`,
        {headers:{
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "token": Cookies.get('Login_Token')
        }})
        .then(res=>{
            setShowDetails(false)
            setUpdateProdList(!updateProdList)
            setDeleteLoader(false)
            toast.success('Product Deleted Successfully!',{autoClose:2000})
        })
        .catch(err=>{
            toast.error(err.data.message)
            setShowDetails(false)
            setDeleteLoader(false)
        })
    }
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoader(true)
        axios.patch(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowInfo.id}`,{"catalogues": {
            "out_of_stock": !checked
        }},
            {headers:{
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": Cookies.get('Login_Token')
            }}
        )
        .then(res=>{
            setLoader(false)
            setChecked(!checked)
        })
        .catch(err=>{
            toast.error(err.message);
            setLoader(false)

        })
    };
    interface Styles extends Partial<Record<SwitchClassKey, string>> {
        focusVisible?: string;
      }
    interface Props extends SwitchProps {
        classes: Styles;
      }
    const SwitchToggle = withStyles((theme: Theme) =>
        createStyles({
            root: {
            width: "46px",
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
            },
            switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(24px)',
                color: theme.palette.common.white,
                '& + $track': {
                background: 'linear-gradient(90deg, #2D752F, #9EC955) !important',
                opacity: 1,
                border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: 'linear-gradient(90deg, #2D752F, #9EC955) !important',
                border: '6px solid #fff',
            },
            },
            thumb: {
            width: 18,
            height: 18,
            },
            track: {
            borderRadius: 24 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: 'grey',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }),
        )(({ classes, ...props }: Props) => {
        return (
            <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
            />
        );
        });
    return (
        <>
            <div className="detailsContainer">
            <DialogTitle id="form-dialog-title"><Typography variant={mobile?'subtitle2':"h5"} style={{color:'black', fontFamily:'Poppins', fontWeight:600}}>Product Details</Typography>
            </DialogTitle>
            <div className="detailsCloseIcon" onClick={()=>{setShowDetails(false)}}><img src={closeIcon} className="img-product-close" alt="" /></div>
            </div>
            <DialogContent>
            <Carousel
            className="img-slide"
            stopAutoPlayOnHover={false}
            animation='slide'
            interval={4000}
            navButtonsProps={{
                style: {
                    backgroundColor: 'black',
                }
            }} 
            indicatorIconButtonProps={{
                style: {
                    color: 'grey'       // 3
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: 'white' // 2
                }
            }}
            indicatorContainerProps={{
                style: {
                    marginTop: '-30px',
                }
        
            }}
            >
            {
                rowInfo.attributes.images?.map((link:any, i:number) => {
                    return (
                        <Paper key={link.id}>
                            <img src={link.url} alt="" className="prodImage" />
                        </Paper>
                    )
                } )
            }
            </Carousel>
            <Paper variant="outlined" className="outOfStockDiv">
                <Typography>Out of Stock Product?</Typography>
                <div className="outOfStockDivLoader">
                {loader && <CircularProgress size={20}/>}
                <FormControlLabel
                control={<SwitchToggle checked={checked} onChange={handleChange} name="checked" disabled={loader} />}
                label="" />
                </div>
            </Paper>
            <DialogContentText>
                <Typography style={{ fontWeight: 700, color:'black', fontFamily:'Poppins' }} variant="h5">{rowInfo.attributes.name}</Typography>
                <Typography gutterBottom>{rowInfo.attributes.value} {rowInfo.attributes.quantity}</Typography>
                <Typography style={{color:'black',fontWeight: 600,fontFamily:'Poppins'}}gutterBottom>&#8377;{rowInfo.attributes.price}.00</Typography>
                <Typography variant='body1' style={{ fontWeight: 600, color:'black', fontFamily:'Poppins' }}>Description</Typography>
                <Typography variant='caption' style={{color:'black', fontFamily:'Poppins', fontSize:'16px'}}>{rowInfo.attributes.description}</Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="DetailsButtonCon">
                <Button onClick={handleEditProd} variant='outlined' className="ButtonEdit">
                    Edit Product
                </Button>
                <Button onClick={handleDeleteProd} className="ButtonDelete" >
                {deleteLoader ? <CircularProgress size={24} className="loader" /> : 'Delete Product'}
                </Button>
            </DialogActions>
        </>
    )
}

export default ShowProdDetails
//Customizable Area End
