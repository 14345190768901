// Customizable Area Start
import React from "react";
import BuyerMyAccountController, { Props } from "./BuyerProductDetailController.web";
import '../assets/BuyerProductDetail.scss'
import BuyerProductDetailController from "./BuyerProductDetailController.web";
import BuyerNavbarWeb from "../../dashboard/src/BuyerNavbar.web";
import { Button, CircularProgress, Backdrop, Tooltip } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import DeleteIcon from "@material-ui/icons/Delete";

//@ts-ignore
import Cookies from 'js-cookie';
import ProductListSlider from "./ProductListSlider.web";
import Footer from "../../dashboard/src/Footer.web";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Link } from 'react-router-dom'
import { translate } from "framework/src/Translation";
const share = require('../assets/share.png').default
export default class BuyerProductDetail extends BuyerProductDetailController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const { productData } = this.state;
    const { id, name, price, quantity, value, images, description, category, out_of_stock }: typeof productData = productData;

    const localizedName = this.getLocalizedName(productData);
    const localizedDescription = this.getLocalizedDescription(productData);

    return (
      <>
        {/* @ts-ignore */}
       <Backdrop style={{zIndex: 2}} open={this.state.loading}>
          <CircularProgress />
        </Backdrop>
        {/* @ts-ignore */}
        <BuyerNavbarWeb isCartUpdate={this.state.cartUpdateFlag}/>
        <div className="product-breadcrumbs">
          <Link to='/'>
            <span>Home</span>&nbsp;&gt;&nbsp;
          </Link>
          <span onClick={() => {
            //@ts-ignore
            this.props.history.push({ pathname: `/products/${'category'}`, state: { id: category?.id, name: this.getLocalizedName(category) } })
          }}>{this.getLocalizedName(category)}</span>&nbsp;&gt;&nbsp;
          <span className="product-name">{localizedName}</span>
        </div>
        <div className="product-detail-container">
        {this.state.loading && <CircularProgress color="inherit" style={{margin:'20% 50%'}}/>}
          {!this.state.loading && <div className="product-detail-container">
            <div className="product-images-wrapper">
              {/* @ts-ignore */}
              {images?.length > 1 &&
                <div className="image-list">
                  {images?.map((image: any, index: number) =>
                    <img className={index === this.state.activeImageIndex ? 'active' : ''} onMouseEnter={() => this.setActiveImageIndex(index)} src={image?.url} />
                  )}
                </div >
              }
              <div className="main-image">
                <img src={images?.[this.state.activeImageIndex]?.url} />
              </div >
            </div >
            <div className="product-detail-wrapper">
              <div className="product-title">
                <Tooltip title={localizedName}>
                <span className='product-name'>{localizedName}
                </span>
                </Tooltip>
                &nbsp;
                &nbsp;
                <span onClick={() => this.copyLink()} className='share-icon'>
                  <img src={share} />
                </span>
              </div>
              <div className='quantity'>
                <p>{value}{quantity}</p>
              </div>
              <div className='price'>
                <p>₹ {price}</p>
              </div>
              <div className='stock'>
                {out_of_stock == true ? <p className="out-of-stock">Out Of Stock</p> :
                  <p className="in-stock">In Stock</p>}
              </div>
              {localStorage.getItem('user_type')==='buyer'?
              this.state.updatingCart ?  
                <div className='loading-button'>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled
                    // className={classes.button}
                    endIcon={<CircularProgress />}
                  >
                    Loading...
                  </Button>
                </div> :
              (this?.state?.foundItem?.id && this?.state?.foundItem?.attributes?.quantity != 0) ?
                <div className='added-to-cart'>
                  <button disabled={out_of_stock == true} onClick={() => this.updateCartItemQuantity(this?.state?.foundItem?.id, 'subtract', 1)} className={`remove ${out_of_stock == true ? 'disabled' : ''}`}>
                    <RemoveIcon fontSize="large" />
                  </button>
                  <span className='cart-added-text'>
                    {this?.state?.foundItem?.attributes?.quantity} {translate("items_in_cart")}
                  </span>
                  <button disabled={out_of_stock == true} onClick={() => this.updateCartItemQuantity(this?.state?.foundItem?.id, 'add', 1)} className={`add ${out_of_stock == true ? 'disabled' : ''}`}>
                    <AddIcon fontSize="large" />
                  </button>
                <Tooltip title={'Remove Item From Cart..!'}>
                  <button  onClick={() => this.removeCartItem(this?.state?.foundItem?.id)}  className="remove-wrapper">
                        <span><DeleteIcon /></span>
                        <span>{translate("remove_item")}</span>
                      </button>
                      </Tooltip>
                </div> :
                <div className='add-to-cart'>
                  <Button
                    disabled={out_of_stock == true}
                    className={`${out_of_stock == true ? 'disabled' : ''}`}
                    onClick={() => {
                      Cookies?.get('Login_Token') ? this.addItemToCart(id, 1) : this.props.history.push('/login')}
                    } 
                  >
                    {translate("add_to_cart")}
                  </Button>
                </div>
              :null}
              <div className='description'>
                <p className="des-lable">{translate("description")}</p>
                <p className="des-content">{localizedDescription}</p>
              </div>
            </div >
          </div >}
        </div>
        <div className="related-products-slider">
        <ProductListSlider showViewAllButton={false} onViewAllClick={() => { }} onProductClick={(productId) => { this.props.history.push(`/product-details/${productId}`); location.reload(); }} title={translate("related_products")} productList={this.state.relatedProductList} />
        </div>
        <Footer />
      </>
    );
  }
}
// Customizable Area End