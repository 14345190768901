import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Context } from "react";
import { AppContext } from "../../../components/src/context/AppContext";
import { set_user_data } from "../../../components/src/context/actions";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: {
    params: {
      token: string;
      apiToken: string;
      role: "buyer" | "merchant";
      fromProfile?: boolean;
      profileAction?: (s: boolean) => void;
      email: string;
      fullphonenumber:string
    }
  };
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isFetching: boolean;
  otp: any;
  inputBoxLength: any;
  isInvalidOtp: boolean;
  isResendOtp: boolean;
  isResendOtpClicked: any;
  token: string;
  email: string;
  phoneNumber:string;
  role: string;
  apiToken: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
   apiConfirmOTPCallId: string = "";
   apiReSendOTPCallId: string ="";
   otpTextInput: any = [];
   toastRef: any;
   // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      otp: [],
      inputBoxLength: 4,
      isInvalidOtp: false,
      isFetching: false,
      isResendOtp: false,
      isResendOtpClicked: true,
      token:'',
      role:'',
      email:'',
      phoneNumber:'',
      apiToken:''
    };
    // Customizable Area End
  }

   // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
   
    //console.log(this.props.route.params.role+"::"+this.props.route.params.token+"::"+this.props.route.params.email+"::"+this.props.route.params.fullphonenumber);
    console.log("Route>>",JSON.stringify(this.props.route.params));
    this.setState({role:this.props.route.params.role, apiToken: this.props.route.params.apiToken, token:this.props.route.params.token, email:this.props.route.params.email, phoneNumber:this.props.route.params.fullphonenumber});
    
  }
// Customizable Area End

  async receive(from: String, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiConfirmOTPCallId) {
          this.confirmOTPAPISuccessCallBack(responseJson);
        }
        else if(apiRequestCallId=== this.apiReSendOTPCallId)
        {
          this.ReSendOTPSuccessCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiConfirmOTPCallId) {
          this.confirmOTPAPIFailureCallBack(responseJson);
        }
        else if(apiRequestCallId=== this.apiReSendOTPCallId)
        {
          this.ReSendOTPFailCallBack(responseJson);
        }
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse);
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType?: Context<any> | undefined = AppContext;

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    // const token =
    //   "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzksImV4cCI6MTYzMzc3MDA1Nn0.t5w6tWVzcnLD-J9wTpKf8iVql5JKz3IKrSccg7408S869WeVRzmxWbwFe3UnpWPLf5fLbbY8_mrNN3IQH_JARw";
    const token =this.state.apiToken;
    const header: Record<string, string> = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //============================ OTP Input Function ==========================
  focusPrevious(key: any, index: any) {
    if (key === "Backspace" && index !== 0)
      this.otpTextInput[index - 1].focus();
  }

  focusNext(index: any, value: any) {
    if (index < this.otpTextInput.length - 1 && value) {
      this.otpTextInput[index + 1].focus();
    }
    if (index === this.otpTextInput.length - 1) {
      this.otpTextInput[index].blur();
    }
    const otp = this.state.otp;
    otp[index] = value.charAt(value.length - 1);
    this.setState({ isInvalidOtp: false, otp });
  }

  //============================ Confirm OTP API Call =========================
  confirmOTP = async () => {
    if (this.state.otp.length !== 4) {
      this.setState({ isInvalidOtp: true });
      return;
    }
    this.setState({ isFetching: true });
    let newOtp = this.state.otp.join("");
    let confirmOTPData = {
          token: this.state.token,
          pin: newOtp,
    };
    this.apiConfirmOTPCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `account_block/accounts/sms_confirmations`,
      body: confirmOTPData
    });
  };

  resetStateInfo = (res: any) => {
    this.setState({ otp: [], isFetching: false }, () => {
      // this.toastRef.show("Varification code has been sent on your email and Phone no.")
      setTimeout(() => {
        this.props.navigation.navigate("CustomForm2", {
          token: this.props.route.params.apiToken
        })
      }, 1000);
    })
  }

  confirmOTPAPISuccessCallBack = async (res: any) => {
    console.log('@@@ Get Send OTP Successs CallBack =============', res,this.state.role,this.context.state.auth);
    // Handle profile update
    if(this.props.route.params?.fromProfile && this.props.route.params.role === "buyer"){
      if(typeof this.props.route.params.profileAction === "function"){
        this.props.route.params.profileAction(true);
      }
      return;
    }
    else if(this.props.route.params?.fromProfile && this.props.route.params.role === "merchant")
    {
      console.log("Am here 1");
      this.setState({isFetching:false});
      this.props.navigation.navigate("MerchantProfile");
      return;
    }

    // Handle buyer
    if(this.props.route.params.role === "buyer"){
      const newState = {
        auth: !!res.meta.token,
        // token: res.meta.token,
        role: "buyer",
      }
      this.context.dispatch({ type: set_user_data, payload: newState });
      return;
    }
    else if(this.state.role=="merchant" && this.context.state.auth){
      console.log("role exists>>",res.data?.attributes?.activated);
      if(res.data?.attributes?.activated ){
        console.log("Is authenticated", res.data?.attributes?.activated);
      const newState = {
        auth: true
       
      }
      this.setState({isFetching:false});
      this.context.dispatch({ type: set_user_data, payload: newState },()=>{
        this.props.navigation.navigate("MerchantProfile");
      });
      return;
      
    }
    

    }
    

    // Handle merchant
    this.resetStateInfo(res);
  }

  confirmOTPAPIFailureCallBack = (error: any) => {
    console.log('@@@ Get Send OTP Failure CallBack =============', error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    })
  }

  ReSendOTPAPICall = async () => {
    this.setState({ isFetching:true});
    let sendOTPData = {
      data: {
        attributes: {
        full_phone_number: this.state.phoneNumber,
        email: this.state.email
        },
      }
    }
    this.apiReSendOTPCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `account_block/accounts/send_otps`,
      body: sendOTPData
    });
  }

  ReSendOTPSuccessCallBack = async (res:any)=> {
    this.setState({isFetching:false,isResendOtp: false,isResendOtpClicked:!this.state.isResendOtpClicked,token:res.data.attributes.token});
    this.toastRef.show("Varification code has been re-sent on your email and Phone no.");
  }

  ReSendOTPFailCallBack = async (res:any) => {
    this.setState({ isFetching: false,isResendOtp: false,isResendOtpClicked:!this.state.isResendOtpClicked }, () => {
      let errorMessage = String(this.parseApiCustomErrorResponse(res));
      this.toastRef.show(errorMessage);
    })
  }

  parseApiCustomErrorResponse(responseJson: any) {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors = '';
    errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          const strKey =key.replace(/_/g,' ');
          if (allerrors.length <= 0) {
            allerrors = `${strKey} ${value}`;
          } else {
            allerrors = `${allerrors}{\n}${strKey} ${value}`;
          }
        }
      });
    });
    return allerrors;
  }
  // Customizable Area End
}
