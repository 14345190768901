import React from "react";
import { View, Image, Modal, TouchableOpacity, StyleSheet } from "react-native";

// import images from "./images";
import { translate } from "framework/src/Translation";
import { Colors, dimensions } from "./constants";
import Typography from "./Typography";
import RNButton from "./RNButton";
import Scale from "./Scale";
import { normalize } from "react-native-elements";
import LinearGradient from "react-native-linear-gradient";

type Props = {
  visible: boolean;
  onClose: () => void;
  icon?: string;
  action?: { event: (d?: any) => void, name: string };
  header?: string;
  text?: string;
  children?: React.ReactNode;
  hideButtons?: boolean;
}

const ModalWrapper: React.FC<Props> = ({ onClose, visible, icon, action, header, text, children, hideButtons }) => {

  return(
    <Modal
      visible={visible}
      transparent
      animated
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <TouchableOpacity onPress={onClose} activeOpacity={1} style={styles.backDrop} />
        <View style={styles.content}>
          {/* {(typeof icon === "string") && <Image source={images[icon]} style={styles.icon} />} */}
          {(typeof header === "string") && <Typography size={18} font="SBD" mb={1.5} style={styles.text}>{header}</Typography>}
          {(typeof text === "string") && <Typography mb={1.5} style={styles.text}>{text}</Typography>}
          {children? children: null}
          <View style={styles.row}>
            {!hideButtons && (
              // <RNButton
              //   onPress={onClose}
              //   style={action? [styles.button, styles.cancelButton]: styles.cancelButton}
              //   gradientStyle={action? styles.button: {}}
              //   colors={[Colors.white, Colors.white]}
              //   textColor="black"
              // >
              //   {translate("cancel")}
              // </RNButton>
              <TouchableOpacity
                onPress={onClose}
                style={action? [styles.button, styles.cancelButton]: styles.cancelButton}
              >
                <Typography font="MED" style={styles.cancelText} color="black">{translate("cancel")}</Typography>
              </TouchableOpacity>
            )}
            {(typeof action?.event === "function") && (
              // <RNButton
              //   onPress={action.event}
              //   style={styles.button}
              //   gradientStyle={styles.button}
              // >
              //   {action.name}
              // </RNButton>
              <TouchableOpacity
                onPress={action.event}
              >
                <LinearGradient
                  start={{ x: 0.2, y: 0 }}
                  end={{ x: 0.6, y: 0 }}
                  colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
                  style={[styles.cancelButton, styles.button]}
                >
                  <Typography color="white" font="MED" style={styles.cancelText}>{action.name}</Typography>
                </LinearGradient>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.transparent
  },
  backDrop: {
    flex: 1,
    backgroundColor: `${Colors.black}66`
  },
  content: {
    width: "100%",
    backgroundColor: Colors.white,
    paddingHorizontal: dimensions.wp(5),
    paddingVertical: dimensions.wp(5),
    borderTopLeftRadius: dimensions.wp(5),
    borderTopRightRadius: dimensions.wp(5),
    overflow: "hidden"
  },
  icon: {
    width: dimensions.wp(15),
    height: dimensions.wp(15),
    alignSelf: "center",
    marginBottom: dimensions.hp(1.5)
  },
  text: {
    textAlign: "center"
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: dimensions.hp(2.5)
  },
  button: {
    width: dimensions.wp(43),
    borderWidth: 0
  },
  cancelButton: {
    paddingVertical: 0,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    height: Scale(55),
    marginBottom: normalize(10),
    borderRadius: Scale(10),
    borderColor: Colors.borderColor
  },
  cancelText: {
    textTransform: "uppercase",
    fontSize: Scale(19)
  }
});

export default ModalWrapper;