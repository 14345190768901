import React from "react";
import { TouchableOpacity, StyleSheet, Image, Platform } from "react-native";
import { LoginManager, AccessToken } from "react-native-fbsdk";

import icons from "./images";
import { translate } from "framework/src/Translation";
import { Colors, dimensions } from "./constants";
import scale, { verticalScale } from "framework/src/Scale";
import Typography from "./Typography";

const loginMedium = Platform.OS === "ios"? "browser": "web_only";

type Props = {
  action: (token: string) => void;
}

const FacebookButton: React.FC<Props> = ({ action }) => {

  const initFacebookAuth = () => {
    LoginManager.logOut?.();
    LoginManager.setLoginBehavior(loginMedium);
    LoginManager.logInWithPermissions(["public_profile", "email"])
      .then(async res => {
        if(res?.isCancelled){
          return;
        }
        const token = await AccessToken.getCurrentAccessToken();
        if(token && typeof action === "function"){
          action(token.accessToken);
        }
      })
      .catch(err => {
        console.log("fb auth catch err", err);
      });
  }

  return(
    <TouchableOpacity
      onPress={initFacebookAuth}
      style={styles.container}
    >
      <Image source={icons.facebook} style={styles.icon} />
      <Typography color="black" font="SBD" size={16}>{translate("continue_with_facebook")}</Typography>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: scale(335),
    height: verticalScale(60),
    borderRadius: scale(12),
    borderColor: Colors.borderColor,
    borderWidth: 2,
    marginTop: dimensions.hp(2),
    backgroundColor: Colors.white,
    alignSelf: "center"
  },
  icon: {
    width: scale(35),
    aspectRatio: 1,
    marginRight: dimensions.wp(4),
    marginLeft: dimensions.wp(12)
  },
  text: {
    fontSize: scale(15)
  }
});

export default FacebookButton;
