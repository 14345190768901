import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Validators from '../../../framework/src/Validators';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Keyboard } from "react-native";
import { Context } from "react";
import { AppContext } from "../../../components/src/context/AppContext";
import {
  GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from "@react-native-google-signin/google-signin";
import { set_user_data } from "../../../components/src/context/actions";
import { translate } from "framework/src/Translation";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: {
    params: {
      role: "buyer" | "seller"
    }
  }
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isFetching: boolean;
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  isInvalidName: boolean;
  isInvalidPhoneNumber: boolean;
  isInvalidEmail: boolean;
  isInvalidPassword: boolean;
  isInvalidConfirmPassword: boolean;
  apiToken: any,
  selectedRole:string,
  acceptedTerms: boolean;
  signupResponse: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
   apiEmailRegistrationCallId: string = "";
   socialLoginApiCallId: string = "";
   apiSendOTPCallId: string = "";
   toastRef: any;
   // Customizable Area End
   // Customizable Area Start
  arrayholder: any[];   
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelName: string;
  labelPhone: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isFetching: false,
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      isInvalidName: false,
      isInvalidPhoneNumber: false,
      isInvalidEmail: false,
      isInvalidPassword: false,
      isInvalidConfirmPassword: false,
      apiToken: null,
      selectedRole:'',
      acceptedTerms: false,
      signupResponse:{}
    };
    // Customizable Area End

  }
  
  async componentDidMount() {
    // Customizable Area Start
    this.setupGoogleConfiguration();
    const selRole = this.props.route.params.role;
    this.setState({selectedRole:selRole});
    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //console.log("@@@ API MESSAGE  LogIn Form =================", message);\
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        //console.log("@@@ if LogIn Form Page  =================",responseJson);
        if (apiRequestCallId === this.apiEmailRegistrationCallId) {
          this.signUpAPISuccessCallBack(responseJson);
        } else if(apiRequestCallId === this.socialLoginApiCallId) {
          this.googleLoginAPISuccessCallBack(responseJson);
        } else if(apiRequestCallId === this.apiSendOTPCallId) {
          this.sendOTPAPISuccessCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        //console.log("@@@ if Register fails  =================",responseJson);
        if (apiRequestCallId === this.apiEmailRegistrationCallId) {
          console.log("API Fails On Registration>>",responseJson);
          this.signUpAPIFailureCallBack(responseJson);
        } else if(apiRequestCallId === this.socialLoginApiCallId) {
          this.googleLoginAPIFailureCallBack(responseJson);
        } else if(apiRequestCallId === this.apiSendOTPCallId) {
          this.sendOTPAPIFailureCallBack(responseJson);
        }
      } else if (errorReponse) {
        console.log("@@@ API fail1====", errorReponse,responseJson);
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType?: Context<any> | undefined = AppContext;

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    //console.log("Token from context>>",this.context.state.token)
   // const token = this.context.state.token ;
   //const token = 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzksImV4cCI6MTYzMzc3MDA1Nn0.t5w6tWVzcnLD-J9wTpKf8iVql5JKz3IKrSccg7408S869WeVRzmxWbwFe3UnpWPLf5fLbbY8_mrNN3IQH_JARw';
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ SignUp API form====", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setupGoogleConfiguration = () => {
    //initial configuration
    GoogleSignin.configure({
      webClientId:
        "881631552946-94qrps5h5thtvk8p1oo275j6d0doraof.apps.googleusercontent.com",
    });
  };

  //================================= Google SignUP API Call =================================
  onPressGoogleLogin = async () => {
    try {
      await GoogleSignin.hasPlayServices({
        showPlayServicesUpdateDialog: true,
      });
      const userInfo = await GoogleSignin.signIn();
      const userToken = await GoogleSignin.getTokens();
      console.log("@@@ Google SignIn Response =========== ", userInfo, userToken);
      let socialLoginData = {
        data: {
          type: "social_account",
          attributes: {
            social_type: "google",
            user_type: this.state.selectedRole == "merchant" ? "merchant" : "buyer",
            access_token: userToken.idToken,
          },
        },
      };
      this.setState({ isFetching: true });
      this.socialLoginApiCallId = await this.apiCall({
        contentType: "application/json",
        method: "POST",
        endPoint: `account_block/accounts`,
        body: socialLoginData,
      });
    } catch (error: any) {
      console.log("@@@ Message ==============================", error);
      if (error.code === statusCodes.SIGN_IN_CANCELLED) {
        console.log("User Cancelled the Login Flow");
      } else if (error.code === statusCodes.IN_PROGRESS) {
        console.log("Signing In");
      } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
        console.log("Play Services Not Available or Outdated");
      } else {
        console.log("Some Other Error Happened");
      }
    }
  };

  loginWithFacebook = async (token: string) => {
    const facebookLoginData = {
      data: {
        type: "social_account",
        attributes: {
          social_type: "facebook",
          user_type: this.state.selectedRole == "merchant" ? "merchant" : "buyer",
          access_token: token,
        },
      },
    };
    this.setState({ isFetching: true });
    this.socialLoginApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "account_block/accounts",
      body: facebookLoginData,
    });
  }

  googleLoginAPISuccessCallBack = async (res: any) => {
    // Handle buyer
    const { id, attributes: { email, full_phone_number, user_type } } = res.data;
    if(user_type === "buyer"){
      if(res.data.attributes.activated) {
        this.setState({ isFetching: false}, () => {
          this.toastRef.show("You have already registered.");
        });
        return;
      }
      const newState = {
        auth: !!res.meta.token,
        id: id,
        email: email,
        mobile: full_phone_number,
        token: res.meta.token,
        role: user_type,
      }
      this.context.dispatch({ type: set_user_data, payload: newState });
      return;
    }

    // Handle merchant
    if(res.data.attributes.activated) {
      this.setState({ isFetching: false}, () => {
        this.toastRef.show("You have already registered.");
      })
    } else if(!res.data.attributes.is_document_uploaded){
      this.setState({ isFetching: false }, () => {
        this.props.navigation.navigate("CustomForm2", {
          token: res.meta.token
        })
      });
    } else if(!res.data.attributes.is_bank_details_filled){
      this.setState({ isFetching: false }, () => {
        this.props.navigation.navigate("BankAccount", {
          token: res.meta.token
        })
      });
    } else{
      this.setState({ isFetching: false }, () => {
        this.props.navigation.navigate("ReviewAndApproval", {
          token: res.meta.token
        })
      });
    }
    // this.resetStateInfo()
  };

  googleLoginAPIFailureCallBack = (error: any) => {
    console.log("@@@ Get google login Failure CallBack =============", error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    });
  };
  //================================= SignUP API Call =================================

  onPressSubmit = async () => {
    Keyboard.dismiss();
    if(this.state.acceptedTerms===false)
    {
      this.toastRef.show(translate("please_accept_termsandconditions_and_privacypolicy"));
      return;
    }

    if(this.state.name.trim().length === 0 && this.state.phoneNumber.trim().length === 0 && this.state.email.trim().length === 0 && this.state.password.trim().length === 0)
    {
      this.toastRef.show(translate("please_fill_all_required_fields"));
      return;
    }

    if (this.state.name.trim().length === 0) {
      this.setState({ isInvalidName: true })
      return;
    }
    if (this.state.phoneNumber.trim().length === 0 || this.state.phoneNumber.trim().length < 10) {
      this.setState({ isInvalidPhoneNumber: true })
      return;
    }
    if (this.state.email.trim().length === 0 || !Validators.isEmailValid(this.state.email)) {
      this.setState({ isInvalidEmail: true })
      return;
    }
    if (this.state.password.trim().length === 0) {
      this.setState({ isInvalidPassword: true })
      return;
    }
    if (this.state.confirmPassword.trim() !== this.state.password.trim()) {
      this.setState({ isInvalidConfirmPassword: true });
      return
    }
    this.setState({ isFetching: true });
    let signUpData = {
      data: {
        type: "email_account",
        attributes: {
        email: this.state.email.toLocaleLowerCase(),
        full_phone_number: "91" + this.state.phoneNumber.slice(-10),
        fullname: this.state.name,
        password: this.state.password,
        user_type: this.state.selectedRole == "merchant" ? "merchant" : "buyer",
        },
      }
    }
    this.apiEmailRegistrationCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `account_block/accounts`,
      body: signUpData
    });
  }

  resetStateInfo = (res: any) => {
    const { selectedRole, apiToken,email, phoneNumber } = this.state;
    this.setState({ name: '', phoneNumber: '', email: '', password: '',  confirmPassword: '', isFetching: false }, () => {
      this.toastRef.show("Varification code has been sent on your email and Phone no.")
      setTimeout(() => {
        this.props.navigation.navigate("OTPInputAuth", {
          token: res.attributes.token,
          apiToken: apiToken,
          role: selectedRole,
          email: email,
          fullphonenumber: "91" + phoneNumber.slice(-10)
        })
      }, 2000);
    })
  }

  signUpAPISuccessCallBack = async (res: any) => {
    console.log('@@@ Get SignUp Successs CallBack =============', res);
    this.setState({apiToken: res.meta.token,signupResponse: res }, () => {
      const { id, attributes: { email, full_phone_number, user_type } } = res.data;
      const newState = {
        token: res.meta.token,
        id: id,
        email: email,
        mobile: full_phone_number,
        role: user_type
      }
      this.context.dispatch({ type: "SET_USER_DATA", payload: newState });
      console.log("token set to context>>",res.meta.token);
      this.sendOTPAPICall();
    })
  }

  signUpAPIFailureCallBack = (error: any) => {
    console.log('@@@ Get SignUp Failure CallBack =============', error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiCustomErrorResponse(error));
      this.toastRef.show(errorMessage);
    })
  }
 parseApiCustomErrorResponse(responseJson: any) {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors = '';
    errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          const strKey =key.replace(/_/g,' ');
          if (allerrors.length <= 0) {
            allerrors = `${strKey} ${value}`;
          } else {
            allerrors = `${allerrors}{\n}${strKey} ${value}`;
          }
        }
      });
    });
    return allerrors;
  }
    //================================= Send OTP API Call =================================
    sendOTPAPICall = async () => {
      let sendOTPData = {
        data: {
          attributes: {
          full_phone_number: "91" + this.state.phoneNumber.slice(-10),
          email: this.state.email
          },
        }
      }
      this.apiSendOTPCallId = await this.apiCall({
        contentType: 'application/json',
        method: 'POST',
        endPoint: `account_block/accounts/send_otps`,
        body: sendOTPData
      });
    }

    sendOTPAPISuccessCallBack = async (res: any) => {
      console.log('@@@ Get Send OTP Successs CallBack =============', res.data);
      this.resetStateInfo(res.data);
    }
  
    sendOTPAPIFailureCallBack = (error: any) => {
      console.log('@@@ Get Send OTP Failure CallBack =============', error);
      this.setState({ isFetching: false }, () => {
        let errorMessage = String(this.parseApiErrorResponse(error));
        this.toastRef.show(errorMessage);
      })
    }
    // Customizable Area End
}
