export const imgPasswordVisible = require("../assets/ic_password_visible.png").default;
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png").default;
export const GROW_CART = require("../assets/GrowCart.png").default;
export const Notifiction = require("../assets/Notifiction.png").default;
export const DOWN_ARROW = require("../assets/DownArrow.png").default;
export const FLAT_LIST_IMAGE = require("../assets/FLATLISTIMAGE.png").default;

export const addressIcon = require("../assets/image_address.png").default;
export const cardsIcon = require("../assets/image_cards.png").default;
export const contactIcon = require("../assets/image_contact.png").default;
export const languageIcon = require("../assets/image_language.png").default;
export const apasswordIcon = require("../assets/image_password.png").default;
export const privacyIcon = require("../assets/image_privacy.png").default;
export const referralsIcon = require("../assets/image_referrals.png").default;
export const termsIcon = require("../assets/image_terms.png").default;
export const verifiedIcon = require("../assets/image_verified.png").default;
export const chevronRight = require("../assets/right_arrow.png").default;
export const bankDetailIcon = require("../assets/image_bank.png").default;
export const invoiceIcon = require("../assets/image_invoice.png").default;
export const threedotsIcon = require("../assets/threedotspng.png").default;