// Customizable Area Start
import React, { useEffect, useRef, useState } from 'react'

//@ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Modal, Backdrop, Fade, Button, Typography } from '@material-ui/core';
import ProductListSliderController, { Props } from "./ProductListSliderController.web";
const rightArrow = require('../assets/rightArrow.png').default;
const leftArrow = require('../assets/leftArrow.png').default;
const skel = require('../assets/skel.png').default;
const prodSkel = require('../assets/prodSkel.png').default;
export default class ProductListSlider extends ProductListSliderController {
  productSlider: any;
  constructor(props: Props) {
    super(props);
    this.productSlider = React.createRef();
  }

  render() {
    const { title, productList, showViewAllButton, onViewAllClick, onProductClick } = this.props;
    if(productList?.length) return (
      <div className='section-buyer'>
        <div className='feat-prod-container' >
          <div className='section-top'>
            <Typography
              style={{
                fontSize: '32px',
                //@ts-ignore
                fontWeight: '600',
              }}
            >
              {title}
            </Typography>
            {showViewAllButton && <Button className='view-all-btn' onClick={onViewAllClick}>View All</Button>}
          </div>
          <div className='prod-section'>
            <div className='wrapper1' ref={this.productSlider}>
              <img
                src={leftArrow}
                alt=''
                className='left_arrow'
                onClick={() => this.productSlider.current.scrollLeft -= 300}
              />
              {productList?.map(
                (data: any, id: number) => {
                  return (
                    <div onClick={() => onProductClick(data?.id)} key={id} className='prod-card'>
                      <LazyLoadImage
                        src={data.attributes.images[0].url}
                        alt=''
                        width={300}
                        height={250}
                        style={{
                          borderRadius: '12px',
                        }}
                        effect='opacity'
                        placeholderSrc={prodSkel}
                        afterLoad={() => {
                          let arr = [...this.state.featImgLoaded];
                          arr[id].load = true;
                          this.setState({
                            featImgLoaded: arr,
                          });
                        }}
                      />
                      {this?.state?.featImgLoaded?.[id]?.load && (
                        <>
                          <Typography className='card-prod-name'>
                            {this.getLocalizedName(data)}
                          </Typography>
                          <Typography className='card-prod-quan'>
                            {data.attributes.value}{' '}
                            {data.attributes.quantity}
                          </Typography>
                          <Typography className='card-prod-price'>
                            ₹{data.attributes.price}
                          </Typography>
                        </>
                      )}
                    </div>
                  );
                },
              )}
            </div>
            <img
              src={rightArrow}
              alt=''
              className='right_arrow'
              onClick={() => this.productSlider.current.scrollLeft += 300}
            />
          </div>
        </div>
      </div>
    );
    else return (<></>)
  }
}
// Customizable Area End