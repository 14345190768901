import { StyleSheet, Platform } from "react-native";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import scale, { verticalScale} from "../../../framework/src/Scale";

// Customizable Area Start
// Customizable Area End

export default StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR_CONST.whiteColor
  },

  profileReviewImage: {
    width: scale(300),
    height: scale(200)
  },

  underReviewText:{
    fontSize: scale(18),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    textAlign: 'center',
  },

  descriptionText:{
    width: scale(300),
    fontSize: scale(15),
    fontFamily: FONTS.PoppinsDisplayRegular,
    color: COLOR_CONST.blackColor,
    marginTop: verticalScale(8),
    textAlign: 'center',
  },

  loginButtonContainer: {
    position: "absolute",
    bottom: verticalScale(20),
    alignSelf: "center",
  },

  loginView: {
    width: scale(335),
    height: scale(55),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(10),
  },

  loginText: {
    fontSize: scale(15),
    color: COLOR_CONST.whiteColor,
    fontFamily: FONTS.PoppinsDisplayMedium,
  },
  // Customizable Area End
});
