import React from "react";
import { View, Image, Modal, TouchableOpacity, StyleSheet } from "react-native";

// import images from "./images";
import { translate } from "framework/src/Translation";
// import { Typography, RNButton, Colors, dimensions } from ".";
import scale from "./Scale";
import { normalize } from "react-native-elements";
import Typography from "./Typography";
import RNButton from "./RNButton";
import { Colors, dimensions } from "./constants";

type Props = {
  visible: boolean;
  onClose: () => void;
  icon?: string;
  action?: { event: (d?: any) => void, name: string };
  header?: string;
  text?: string;
  children?: React.ReactNode;
}

const ModalConfirmWrapper: React.FC<Props> = ({ onClose, visible, icon, action, header, text, children }) => {

  return(
    <Modal
      visible={visible}
      transparent
      animated
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <TouchableOpacity onPress={onClose} activeOpacity={1} style={styles.backDrop} />
        <View style={styles.content}>
          {/* {(typeof icon === "string") && <Image source={images[icon]} style={styles.icon} />} */}
          {(typeof header === "string") && <View style={{justifyContent:'space-between', flexDirection:'row'}}> 
          <Typography size={20} font="MED" mb={1.5} style={styles.text}>{header}</Typography> 
            <View> 
                <TouchableOpacity onPress={onClose}>
                    <Typography size={20} font="MED" mb={1.5} style={styles.text}>X</Typography>
                </TouchableOpacity>
            </View> 
        </View>}
          {(typeof text === "string") && <Typography mb={1.5} style={styles.text}>{text}</Typography>}
          {children? children: null}
          <View style={styles.row}>
            {/* <RNButton
              onPress={onClose}
              style={action? [styles.button, styles.cancelButton]: styles.cancelButton}
              gradientStyle={action? styles.button: {}}
              colors={[Colors.white, Colors.white]}
              textColor="black"
            >
              {translate("cancel")}
            </RNButton> */}
            <TouchableOpacity
                onPress={onClose}
                style={action? [styles.button, styles.cancelButton]: styles.cancelButton}
              >
                <Typography font="MED" style={styles.cancelText} color="black">{translate("cancel")}</Typography>
              </TouchableOpacity>
            {(typeof action?.event === "function") && (
              <RNButton
                onPress={action.event}
                style={styles.button}
                gradientStyle={styles.button}
              >
                {action.name}
              </RNButton>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.transparent
  },
  backDrop: {
    flex: 1,
    backgroundColor: `${Colors.black}66`
  },
  content: {
    width: "100%",
    backgroundColor: Colors.white,
    paddingHorizontal: dimensions.wp(5),
    paddingVertical: dimensions.wp(5),
    borderTopLeftRadius: dimensions.wp(5),
    borderTopRightRadius: dimensions.wp(5),
    overflow: "hidden",
    // alignContent: 'flex-start',
    // alignItems: 'flex-start'
  },
  icon: {
    width: dimensions.wp(15),
    height: dimensions.wp(15),
    alignSelf: "center",
    marginBottom: dimensions.hp(1.5)
  },
  text: {
    textAlign: "left"
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  button: {
    width: dimensions.wp(43),
  },
  cancelButton: {
    paddingVertical: 0,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    height: scale(60),
    marginBottom: normalize(10),
    borderRadius: scale(10),
    borderColor: Colors.borderColor
  },
  cancelText: {
    textTransform: "uppercase",
    fontSize: scale(18)
  }
});

export default ModalConfirmWrapper;