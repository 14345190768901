import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ImagePicker from "react-native-image-crop-picker";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFetching: boolean;
  panCardData: any;
  panCardImagePath: any;
  adharCardData: any;
  adharCardImagePath: any;
  isInvalidPANCard: boolean;
  isInvalidAdharCard: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class KycVerification8Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiBankDetailCallId: string = "";
  toastRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ] 
    this.state = {
      panCardData: null,
      panCardImagePath: null,
      adharCardData: null,
      adharCardImagePath: null,
      isInvalidPANCard: false,
      isInvalidAdharCard: false,
      isFetching: false
      
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    console.log("@@@ Props on KYC Verification Screen ========>", this.props.route.params)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    // super.componentWillUnmount();
    // this._unsubscribe.remove();
    // Customizable Area Start
    // Customizable Area Start 
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiBankDetailCallId) {
          this.companyDetailAPISuccessCallBack(responseJson);
        } 
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiBankDetailCallId) {
          this.companyDetailAPIFailureCallBack(responseJson);
        } 
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse)
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = this.props.route.params.token || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //============================== Upload Company KYC ============================

  onPressPickPanCard = () => {
    try {
      ImagePicker.openPicker({
        mediaType: 'photo',
        cropping: true,
        includeBase64: true,
        compressImageQuality: 0.3,
      }).then(async image  => {
        console.log("@@@ Uploaded PAN Card From Galary =============>", image);
        this.setState({ panCardData: image, panCardImagePath: image.path, isInvalidPANCard: false });
      });
    } catch (error) {
      console.log('@@@ Error opening image from Galary ==========', error);
    }
  };

  onPressPickAadharCard = () => {
    try {
      ImagePicker.openPicker({
        mediaType: 'photo',
        cropping: true,
        includeBase64: true,
        compressImageQuality: 0.3,
      }).then(async image  => {
        console.log("@@@ Uploaded Aadhar Card From Galary =============>", image);
        this.setState({ adharCardData: image, adharCardImagePath: image.path, isInvalidAdharCard: false });
      });
    } catch (error) {
      console.log('@@@ Error opening image from Galary ==========', error);
    }
  };

  //============================== Company Details API call ============================
  onPressNextButton = async () => {
    if (!this.state.panCardData || !this.state.panCardImagePath) {
      this.setState({ isInvalidPANCard: true })
      return;
    }
    if (!this.state.adharCardData || !this.state.adharCardImagePath) {
      this.setState({ isInvalidAdharCard: true })
      return;
    }
    this.setState({ isFetching: true });
    // this.props.navigation.navigate("BankAccount")
    let companyDetailData = {
      seller_account:{
        company_name: this.props.route.params.companyName,
        gstin_number: this.props.route.params.GSTNumber
      },
      image:{
        company_license_url:this.props.route.params.CompanyLicense,
        aadhar_card_uri: this.state.panCardData.data,
        pan_card_uri: this.state.adharCardData.data

      }
    }
    this.apiBankDetailCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_custom_form/seller_accounts`,
      body: companyDetailData
    });
  
  }

  companyDetailAPISuccessCallBack = async (res: any) => {
    console.log('@@@ Get Company Detail Successs CallBack =============', res.data);
    this.setState({ isFetching: false }, () => {
      this.props.navigation.navigate("BankAccount", {
        token: this.props.route.params.token
      })
    });
  }

  companyDetailAPIFailureCallBack = (error: any) => {
    console.log('@@@ Get Company Detail Failure CallBack =============', error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    })
  }

}
