import React from "react";
import ImageCropPicker from "react-native-image-crop-picker";
import { TouchableOpacity, View, Image, StyleSheet } from "react-native";

import icons from "./images";
import { Colors, dimensions } from "./constants";

type File = {
  type: string;
  uri: string;
  name: string;
  data: string;
}

type Props = {
  base64: any;
  uri: string;
  onChange: (f: File) => void;
}

const ImagePicker: React.FC<Props> = ({ uri, onChange }) => {

  const openPicker = () => {
    ImageCropPicker.openPicker({
      cropping: true,
      includeBase64: true
    }).then((img: any) => {
        onChange({
          type: img.mime,
          uri: `data:${img.mime};base64,${img.data}` || "",
          name: img.filename || "",
          data: img.data || ""
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  return(
    <View style={styles.container}>
      <TouchableOpacity onPress={openPicker} style={styles.touchArea}>
        <Image source={{ uri:uri }} style={styles.image} />
        {<Image source={icons.edit} style={styles.icon} />}
      </TouchableOpacity>
    </View>
  );
}

ImagePicker.defaultProps = {
  base64: false,
  uri: "",
  onChange: () => {}
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: dimensions.hp(.5),
    alignItems: "center"
  },
  touchArea: {
    height: dimensions.wp(30),
    width: dimensions.wp(30),
    borderRadius: dimensions.wp(15),
    position: "relative",
    backgroundColor: Colors.greyText
  },
  image: {
    height: dimensions.wp(30),
    width: dimensions.wp(30),
    borderRadius: dimensions.wp(15),
    resizeMode: "cover",
    overflow: "hidden"
  },
  icon: {
    height: dimensions.wp(7),
    width: dimensions.wp(7),
    resizeMode: "contain",
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 2
  }
});

export default ImagePicker;