// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../framework/src/RunEngine";

import {Language} from "../../framework/src/Translation"
import {setI18Config} from "../../framework/src/Translation"
import { AppContext  } from "../../components/src/context/AppContext";
import { Context } from "react";
import { set_user_data } from "../../components/src/context/actions";

//export const configJSON = require("./config.js");

export interface Props {
  branchName?:any
  keepProfile?:any
  deleteAllNotifications?:any
  setAddProd?:any
  setSearchedValue?:any
  searchedValue?:any
  setinSearchedValue?:any
  insearchedValue?:any
  totalLengthOfOrder?:any
  totalAmountOfOrder?:any
  addProd?:any
  history?:any
  keepNotificationView?:any
  profileData?:any
  changeIconValue?:any
  orderedSearchedValue?:any
  setOrderedSearchedValue?:any
  unreadNotif?:boolean;
}
interface S {
  progress:boolean;
  userDetail:any
  language: 'en' | 'hi' | 'kn'
  dropOpen: boolean;
  showLogoutModal: boolean;
  notificationVisible:boolean;
}
interface SS {
  id: any;
}

export default class NavbarControllerWeb extends BlockComponent<Props, S, SS> {
  apiUserCallId:any
  getNotificationApiCallId:string='';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      progress:false,
      userDetail:[],
      language: 'en',
      dropOpen: false,
      showLogoutModal: false,
      notificationVisible:true
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  static contextType?: Context<any> | undefined = AppContext;

  async componentDidMount() {
    if(Cookies?.get('Login_Token')){
      this.getUserDetails();
      this.getNotifications();
      this.initializeLanguage();
    }
  }

  initializeLanguage = () => {
    // Try to get language from localStorage first, then context, then default to 'en'
    const savedLanguage = localStorage.getItem('selectedLanguage') as Language;
    const contextLanguage = this.context.state.language;
    const finalLanguage = savedLanguage || contextLanguage || 'en';

    this.setState({ language: finalLanguage });
    setI18Config(finalLanguage);

    if (this.context.state.language !== finalLanguage) {
        this.context.dispatch({
            type: set_user_data,
            payload: { language: finalLanguage },
        });
    }
}

handleLanguageChange = (newLanguage: Language) => {
    localStorage.setItem('selectedLanguage', newLanguage);

    this.setState({ language: newLanguage });
    setI18Config(newLanguage);

    this.context.dispatch({
        type: set_user_data,
        payload: { language: newLanguage },
    });
}

  async componentDidUpdate(){
    this.state.notificationVisible;
  }
  handleDrawerToggle=()=>{
    this.props.changeIconValue(true);
}

  getNotifications=async(): Promise<boolean>=> {
    
    const header = {
      "Content-Type": "application/json",
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_notifications/notifications'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
  getUserDetails(): boolean {
     const id = Cookies.get('Login_Account_Id')
    const header = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "token":Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_custom_form/seller_accounts/'+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    try {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors  && !responseJson.error) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiUserCallId &&
            responseJson !== undefined
          ) {
              
              this.setState({userDetail:responseJson?.data})
          }
          if(apiRequestCallId === this.getNotificationApiCallId && responseJson !== undefined)
          {
              if(responseJson?.data?.length>0)
              {
                this.setState({notificationVisible:!this.state.notificationVisible})
              }
          }
        }

      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.error;
        
      }
    }
      
    } catch (error) {
    
    }
    
  }
}
// Customizable Area End