import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Context } from "react";
import { AppContext } from "../../../components/src/context/AppContext";
import { AppContext as ContextType } from "../../../components/src/context/types";
import internal from "stream";
import { Alert } from "react-native";
import { set_user_data } from "../../../components/src/context/actions";
// Customizable Area End

export const configJSON = require("./config");

type ApiRequestParams = {
  endPoint: string;
  headers?: Record<string, string>;
  body?: Record<string, any>;
  method: "GET" | "POST" | "PUT" | "DELETE";
}

type DashboardData = {
  data: any | {};
  new_orders: Number;
  todays_earnings: Number;
  total_orders: Number;
  total_earnings: Number;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  weeklyMonthlyPopup: any;
  renderMonthViewFlatList: any;
  selectedMonth: any
  dateMonthlyPopup: any;
  renderdateMonthlyPopupFlatList: any;
  selectedDate: any;
  data: any;
  loading: boolean;
  DashboardData: DashboardData;
  recentlySoldItems: any;
  chartWeekLetters: any;
  isUnreadNotificationsExists: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getDashboardApiCallId: string="";
  getRecentlySoldListApiCallId: string="";
  unreadNotificationsApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      weeklyMonthlyPopup: false,
      dateMonthlyPopup: false,
      selectedMonth: 'Weekly',
      selectedDate: '',
      loading: false,
      DashboardData: { new_orders:0, todays_earnings:0, total_earnings:0, total_orders:0, data:{} },
      recentlySoldItems:[],
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            data: [20, 45, 28, 80, 99, 43]
          }
        ]
      },
      renderMonthViewFlatList: [
        {
          id: 0,
          month: "Weekly"
        },
        {
          id: 1,
          month: "Monthly"
        },
        {
          id: 2,
          month: "Yearly"
        }],

      renderdateMonthlyPopupFlatList: [{
        id: 0,
        date: "3 May - 9 May 2021"
      },
      {
        id: 1,
        date: "5 May - 11 May 2021"
      },
      {
        id: 2,
        date: "16 May - 19 May 2021"
      }],
      chartWeekLetters: [],
      isUnreadNotificationsExists: false
      // Customizable Area Start
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashBoardData(this.state.selectedMonth);
    this.getRecentlySoldList();
    this.onBlur();
  }

  onBlur = () => {
    this.props.navigation.addListener("focus", () => {
      this.checkForUnreadNotifications();
    });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("api response>>",JSON.stringify(message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        console.log("api response>>",JSON.stringify(responseJson));

      if (responseJson && !responseJson.errors && responseJson.data) {
        if(apiCallId === this.getDashboardApiCallId){
          //console.log("dashboard data>>",responseJson);
          this.setState({ DashboardData: responseJson, loading: false,chartWeekLetters:responseJson.data });
        }
        else if(apiCallId === this.getRecentlySoldListApiCallId)
        {
          console.log("Recently Sold items>>",responseJson.data.length.toString());
          this.setState({recentlySoldItems:responseJson.data,loading:false});
        }
        else if(apiCallId === this.unreadNotificationsApiCallId){
          this.setState({ loading:false, isUnreadNotificationsExists: responseJson.data?.is_any_unread || false });
        }
        
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if(responseJson?.errors?.[0]?.token){
          this.context.dispatch({
            type: set_user_data,
            payload: {
              auth: false,
              token: ""
            }
          })
        }else
        {
        if(apiCallId === this.getDashboardApiCallId){
          Alert.alert("", "Profile update failed");
          this.setState({ loading: false });
        }
        else if(apiCallId == this.getRecentlySoldListApiCallId)
        {
          Alert.alert("", "unable to fetch recent items");
          this.setState({loading: false});
        }
      }
      setTimeout(() => this.setState({ loading: false }));
      }
    }
    // Customizable Area End
  }

  numFormatter=(num:number)=> {
    if(num > 999 && num < 100000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 100000){
        return (num/100000).toFixed(1) + 'L'; // convert to M for number from > 1 million 
    }else if(num < 999){
        return num; // if value < 1000, nothing to do
    }
}

  getDashBoardData = (selectedOption:string) => {
    let option = selectedOption==="Yearly" ? "year" : selectedOption==="Monthly" ? "month" : "week" ; 
    this.setState({loading:true});
    this.getDashboardApiCallId = this.makeApiRequest({
      method: "GET",
     // endPoint:  `/bx_block_dashboard/home_page?filter=${this.state.selectedMonth}`
     endPoint:  `bx_block_dashboard/home_page?filter=${option}`
    });
  }

  getRecentlySoldList = () => {
    this.setState({loading:true});
    this.getRecentlySoldListApiCallId = this.makeApiRequest({
      method: "GET",
     endPoint:  `/bx_block_dashboard/product`
    });
  }

  checkForUnreadNotifications = () => {
    this.unreadNotificationsApiCallId = this.makeApiRequest({
      endPoint: "bx_block_notifications/notifications/is_any_unread_notifications",
      method: "GET"
    });
  }

  static contextType: Context<ContextType> = AppContext;

  makeApiRequest = ({ endPoint, method, headers, body }: ApiRequestParams) => {
    this.setState({ loading: true });
    const defaultHeaders = {
      "Content-Type": "application/json",
      token: this.context.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if(body){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  // Customizable Area Start
  // Customizable Area End

}
