// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

export const configJSON = require('./config.js');
export const statesData = [
    {
      "label": "Andaman and Nicobar Islands",
      "value": "Andaman and Nicobar Islands"
    },
    {
      "label": "Andhra Pradesh",
      "value": "Andhra Pradesh"
    },
    {
      "label": "Arunachal Pradesh",
      "value": "Arunachal Pradesh"
    },
    {
      "label": "Assam",
      "value": "Assam"
    },
    {
      "label": "Bihar",
      "value": "Bihar"
    },
    {
      "label": "Chandigarh",
      "value": "Chandigarh"
    },
    {
      "label": "Chhattisgarh",
      "value": "Chhattisgarh"
    },
    {
      "label": "Dadra and Nagar Haveli",
      "value": "Dadra and Nagar Haveli"
    },
    {
      "label": "Daman and Diu",
      "value": "Daman and Diu"
    },
    {
      "label": "Delhi",
      "value": "Delhi"
    },
    {
      "label": "Goa",
      "value": "Goa"
    },
    {
      "label": "Gujarat",
      "value": "Gujarat"
    },
    {
      "label": "Haryana",
      "value": "Haryana"
    },
    {
      "label": "Himachal Pradesh",
      "value": "Himachal Pradesh"
    },
    {
      "label": "Jammu and Kashmir",
      "value": "Jammu and Kashmir"
    },
    {
      "label": "Jharkhand",
      "value": "Jharkhand"
    },
    {
      "label": "Karnataka",
      "value": "Karnataka"
    },
    {
      "label": "Kerala",
      "value": "Kerala"
    },
    {
      "label": "Lakshadweep",
      "value": "Lakshadweep"
    },
    {
      "label": "Madhya Pradesh",
      "value": "Madhya Pradesh"
    },
    {
      "label": "Maharashtra",
      "value": "Maharashtra"
    },
    {
      "label": "Manipur",
      "value": "Manipur"
    },
    {
      "label": "Meghalaya",
      "value": "Meghalaya"
    },
    {
      "label": "Mizoram",
      "value": "Mizoram"
    },
    {
      "label": "Nagaland",
      "value": "Nagaland"
    },
    {
      "label": "Odisha",
      "value": "Odisha"
    },
    {
      "label": "Puducherry",
      "value": "Puducherry"
    },
    {
      "label": "Punjab",
      "value": "Punjab"
    },
    {
      "label": "Rajasthan",
      "value": "Rajasthan"
    },
    {
      "label": "Sikkim",
      "value": "Sikkim"
    },
    {
      "label": "Tamil Nadu",
      "value": "Tamil Nadu"
    },
    {
      "label": "Telangana",
      "value": "Telangana"
    },
    {
      "label": "Tripura",
      "value": "Tripura"
    },
    {
      "label": "Uttar Pradesh",
      "value": "Uttar Pradesh"
    },
    {
      "label": "Uttarakhand",
      "value": "Uttarakhand"
    },
    {
      "label": "West Bengal",
      "value": "West Bengal"
    }
  ]

export interface Props {
	//   navigation: any;
	//   id: string;
	//   history: any
	openModal: string;
  addressID?: string;
	closeModal: string;
  newAddressAddedCallback?: any
}

interface Istate {
    value: string;
    label: string;
}
interface S {
	anchorState: boolean;
	name: string;
	phone_number: string;
	address: string;
	landmark: string;
	zip_code: string;
	locality: string;
	state: Istate | undefined | null;
	city: string;
    phoneErr: boolean;
    pincodeErr: boolean;
    localityErr: boolean;
    nameErr: boolean;
    addressErr: boolean;
    cityErr: boolean;
    stateErr: boolean;
    loader: boolean;
    cityState: any;
}
interface SS {
	id: any;
}

export default class AddNewAddressController extends BlockComponent<
	Props,
	S,
	SS
>{
    ApiAddAddressId: string = ''
    ApiGetEditAddressId: string = ''
    ApiSaveEditAddressId: string = ''
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
        this.handleAddAddress = this.handleAddAddress.bind(this);
        this.getEditAddress = this.getEditAddress.bind(this);
        this.saveEditAddress = this.saveEditAddress.bind(this);
		console.disableYellowBox = true;
		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
		];

		this.state = {
			anchorState: false,
            name: '',
            phone_number: '',
            address: '',
            landmark: '',
            zip_code: '',
            locality: '',
            state: null,
            city: '',
            phoneErr: false,
            pincodeErr: false,
            nameErr: false,
            localityErr: false,
            addressErr: false,
            cityErr: false,
            stateErr: false,
            loader:true,
            cityState: null,
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

  static contextType?: Context<any> | undefined = AppContext;

    handleAddAddress(){
        if(!this.state.name || !this.state.phone_number || !this.state.address || !this.state.zip_code || !this.state.locality || !this.state.state?.value || !this.state.city || this.state.phoneErr || this.state.pincodeErr){
            toast.error('No field should be empty or incorrect!')
            !this.state.name && this.setState({nameErr:true})
            !this.state.address && this.setState({addressErr:true})
            !this.state.locality && this.setState({localityErr:true})
            !this.state.city && this.setState({cityErr:true})
            !this.state.state?.value && this.setState({stateErr:true})
            !this.state.phone_number && this.setState({phoneErr:true})
            !this.state.zip_code && this.setState({pincodeErr:true})
        }
        else{
            const header = {
                "Content-Type": configJSON.ApiContentType,
                token: Cookies.get('Login_Token')
            };
            const attrs = {
                name: this.state.name,
                phone_number: this.state.phone_number,
                address: this.state.address,
                address_line_2: this.state.locality,
                city: this.state.city,
                state: this.state.state?.value,
                landmark: this.state.landmark || null,
                country: 'India',
                zip_code: this.state.zip_code,
            };
        
            const data = {
            type: "address",
            attributes: attrs
            };
        
            const httpBody = {
            data: data
            };
        
            const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
            this.ApiAddAddressId = requestMessage.messageId;
            requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addAddressEndPoint
            );
        
            requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
            );
        
            requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
            );
        
            requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.ApiPostMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        }
    }

    getEditAddress(id:any){
        this.setState({
            loader:true
        })
        const header = {
            "Content-Type": configJSON.ApiContentType,
            token: Cookies.get('Login_Token')
        };

        const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ApiGetEditAddressId = requestMessage.messageId;
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_address/addresses/' + id
        );
    
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );
    
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.ApiGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    saveEditAddress(id:any){
        const header = {
            "Content-Type": configJSON.ApiContentType,
            token: Cookies.get('Login_Token')
        };
        const attrs = {
            name: this.state.name,
            phone_number: this.state.phone_number,
            address: this.state.address,
            address_line_2: this.state.locality,
            city: this.state.city,
            state: this.state.state?.value,
            landmark: this.state.landmark|| null,
            country: 'India',
            zip_code: this.state.zip_code,
        };
    
        const data = {
        type: "address",
        attributes: attrs
        };
    
        const httpBody = {
        data: data
        };

        const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ApiSaveEditAddressId = requestMessage.messageId;
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_address/addresses/delivery_address_update/' + id
        );
    
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

	async receive(from: string, message: Message) {
		try {
			if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
				var responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage),
				);
				var errorReponse = message.getData(
					getName(MessageEnum.RestAPIResponceErrorMessage),
				);
				const apiid = message.getData(
					getName(MessageEnum.RestAPIResponceDataMessage),
				);
                if (responseJson && !responseJson.errors  && !responseJson.error) {
                    if(apiid === this.ApiAddAddressId){
                        this.setState({
                            anchorState:false
                        })
                        this.props.newAddressAddedCallback();
                    }
                    else if(apiid === this.ApiGetEditAddressId){
                        let data = responseJson.data.attributes
                        this.setState({
                            name:data.name,
                            phone_number: data.phone_number[0]==='+' ?
                                data.phone_number.substring(3) :
                                data.phone_number,
                            landmark: data.landmark,
                            zip_code:data.zip_code,
                            city: data.city,
                            state: {value:data.state, label:data.state},
                            address: data.address,
                            locality: data.address_line_2,
                            loader: false
                        })
                    }
                    else if(apiid === this.ApiSaveEditAddressId){
                        this.setState({
                            anchorState:false
                        })
                        this.props.newAddressAddedCallback();
                    }
                } else {
                  if(responseJson?.errors[0]){
                    toast.error(responseJson.errors[0].token)
                  }
                  
                }
			}
		} catch (error) {}
	}
}

// Customizable Area End