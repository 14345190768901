//Customizable Area Start
import React, { useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from "moment";
//@ts-ignore
import Cookies from 'js-cookie';
import 'react-table-v6/react-table.css';
import '../assets/OrderDetailWeb.scss';
import "../../../components/src/styles.scss";

import SideDrawerForDetailWeb from "../../../components/src/SideDrawerForDetailWeb.web";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomReactTable from "../../../components/src/CustomReactTableWithServerSidePagination";
import DashboardFooter from "../../../components/src/DashboardFooter";
import useDebounce from "../../../components/src/UseDebounce";
import { translate as t } from "framework/src/Translation";
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')

const OrderDetailWeb= (props:any) => {
  toast.configure();
  const [value, setValue] = useState(0);
  const [orderData, setorderData] = useState<any>([]);
  const [openDrawer, setopenDrawer] = useState<boolean>(false);
  const [orderMainData, setorderMainData] = useState<any>([]); 
  const [orderClickedData, setorderClickedData] = useState<any>([]);   
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [recordStartIndex, setRecordStartIndex] = useState<number>(0);
  const [recordEndIndex, setRecordEndIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStatus, setcurrentStatus] = useState<any>('');
  const [sideDrawerLoading, setsideDrawerLoading] = useState<boolean>(false);
  const { language } = useAppState();
  const refresh = useRefresh();

  const debouncedSearchedData = useDebounce(props?.orderedSearchedValue, 500);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const closeDrawer=()=>{
  setopenDrawer(false)  
  }
  const openDrawerFunc=()=>{
    setopenDrawer(true)
 }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
          //@ts-ignore
          sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setcurrentStatus('')
        setCurrentPage(1)
        getStatusData()
        break;
      case 1:
        setCurrentPage(1)
        setcurrentStatus('new')
        getStatusData('new')
        break;
      case 2:
        setCurrentPage(1)
        setcurrentStatus('processing')
        getStatusData('processing')
        break;
      case 3:
        setCurrentPage(1)
        setcurrentStatus('shipped')
        getStatusData('shipped')
        break;    
      case 4:
        setCurrentPage(1)
        setcurrentStatus('completed')
        getStatusData('completed')
        break;  
      case 5:
        setCurrentPage(1)
        setcurrentStatus('on_hold')
        getStatusData('on_hold')
        break; 
      case 6:
        setCurrentPage(1)
        setcurrentStatus('failed')
        getStatusData('failed')
        break;     
      default:
        break;
    }
    setValue(newValue);
   
  };

  useEffect(() => {
    if(currentStatus==='new'){
      getStatusData('new')
    }else if(currentStatus==='processing'){
      getStatusData('processing')
    }else if(currentStatus==='shipped'){
      getStatusData('shipped')
    }else if(currentStatus==='completed'){
      getStatusData('completed')
    }else if(currentStatus==='on_hold'){
      getStatusData('on_hold')
    }else if(currentStatus==='failed'){
      getStatusData('failed')
    }else{
      getStatusData()
    }
  },[currentPage]);

  useEffect(() => {
    getStatusDataBySearch()
  },[currentPage,debouncedSearchedData]);

  useEffect(() => {
    refresh();
  }, [language]);

  const getStatusDataBySearch=async ()=>{

    //@ts-ignore
    setorderData([])
    setLoading(true);
    try {   
      let orderUrl=''
      if(currentStatus){
        orderUrl=`${configJSON.allListStatusOrder}`+currentStatus
      }else{
       orderUrl=`${configJSON.allOrderEndPoints}`
      }
       await axios
      .get(`${configURL.baseURL}`+'/'+orderUrl,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        },
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          search:debouncedSearchedData
        }
      } )
      .then((res:any) => {
        setLoading(false);
        if(res.data){
          
          if(res?.data?.total_entries) {
          setTotalEntries(res.data.total_entries)
          props?.setTotalLengthOfOrderFunc(res.data.total_entries)
          // dispatch(GET_TOTAL_LIST(res.data.total_entries))
        }else{
          setTotalEntries(0)
          props?.setTotalLengthOfOrderFunc(0)
          // dispatch(GET_TOTAL_LIST(0))
        }
          if(res?.data?.start_from) {setRecordStartIndex(res.data.start_from)} else{  setRecordStartIndex(0)}
          if(res?.data?.end_to){ setRecordEndIndex(res.data.end_to)} else {setRecordEndIndex(0)}
        
        }
      setorderMainData(res?.data?.data)
      if(res?.data?.data?.length ===0){
        setLoading(false);
      }
      })
      .catch((err:any) => {

        if(err?.response?.data?.message==='No order record found.'){
          setorderMainData([])
          setTotalEntries(0)
          setRecordEndIndex(0)
          setRecordStartIndex(0)
        }
        if(err?.message==='Request failed with status code 404'){
        }
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
          }
      
        
        setLoading(false);
      }).finally(() => {
        setLoading(false);
       })
      
    } catch (error) {
      
    }

  }

  const changeStatus=async(status:any,id:any,number:any,index:any,alreadyStatus:any,getdata?:any)=>{
     // dispatch(CHANGE_ORDER_STATUS(status,number))
    try {
      const data={
        "data": {
          "type": "order",
          "attributes": {
             // "order_status_id":5,
              "status": status
          }
      }
      }
      await axios
      .put(`${configURL.baseURL}`+'/'+`${configJSON.statusUpdateEndpoint}`+id,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
       if(debouncedSearchedData){
        getStatusDataBySearch()
       }else if(getdata==='getdata'){
          getStatusData()
        }else{
          getStatusData(alreadyStatus)
        }
        toast.success('Status has been changed.',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
       })
      .catch((err:any) => {
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          }
      }).finally(() => {
       })
    } catch (error) {
      
    }

  }

  const getStatusData=async (valueStatus?:any)=>{

    //@ts-ignore
    setorderData([])
    setLoading(true);
    try {   
      let orderUrl=''
      if(valueStatus){
        orderUrl=`${configJSON.allListStatusOrder}`+valueStatus
      }else{
       orderUrl=`${configJSON.allOrderEndPoints}`
      }
       await axios
      .get(`${configURL.baseURL}`+'/'+orderUrl,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        },
        params: {
          page: currentPage,
          per_page: recordsPerPage
        }
      } )
      .then((res:any) => {
        setLoading(false);
        if(res.data){
          
          if(res?.data?.total_entries) {
          setTotalEntries(res.data.total_entries)
          props?.setTotalLengthOfOrderFunc(res.data.total_entries)
        }else{
          setTotalEntries(0)
          props?.setTotalLengthOfOrderFunc(0)
        }
          if(res?.data?.start_from) {setRecordStartIndex(res.data.start_from)} else{  setRecordStartIndex(0)}
          if(res?.data?.end_to){ setRecordEndIndex(res.data.end_to)} else {setRecordEndIndex(0)}
        
        }
      setorderMainData(res?.data?.data)
      if(res?.data?.data?.length ===0){
        setLoading(false);
      }
      })
      .catch((err:any) => {

        if(err?.response?.data?.message==='No order record found.'){
          setorderMainData([])
          setTotalEntries(0)
          setRecordEndIndex(0)
          setRecordStartIndex(0)
        }
        if(err?.message==='Request failed with status code 404'){
        }
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
        }
        if(err?.response?.data?.message){
        toast.error(err?.response?.data?.message,{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
          }   
        setLoading(false);
      }).finally(() => {
        setLoading(false);
       })
    } catch (error) {
    }
  }

  const getOrderData=async (id:any)=>{
    setorderClickedData([])
    try {   
      await axios
     .get(`${configURL.baseURL}`+'/bx_block_order_management/orders/'+id,{
       headers:{
         "Content-Type": "application/json;charset=UTF-8",
         "Access-Control-Allow-Origin": "*",
         "token":Cookies.get('Login_Token')
       }
     } )
     .then((res:any) => {
         setorderClickedData(res?.data?.data)
         setsideDrawerLoading(false)
     }).catch((err:any) => {
       if(err?.response?.data?.errors.message==='Order items are missing'){
        toast.error('Oops!! Order items are missing !!',{position: toast.POSITION.BOTTOM_RIGHT})
       }
       if(err?.message==='Request failed with status code 500'){
         toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
       }
       if(err?.response?.data?.errors[0]){
       toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
       }
       if(err?.response?.data?.errors[0]){
         toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
         }
       setsideDrawerLoading(false)
     }).finally(() => {
       setsideDrawerLoading(false)
      })
     
   } catch (error) {
     
   }
 
   }

  const columns = [{
    Header: t("order_id"),
    accessor: 'order_number',
    minWidth: 120,
    //@ts-ignore
    Cell: row => (
        <div className="order-id-clickable" onClick={()=>{
          setsideDrawerLoading(true)
          getOrderData(row?.original?.id)
          openDrawerFunc()
        }}>
        {row?.original?.attributes?.order_number}
        </div>
    )
  }, {
    Header: t("date"),
    accessor: 'order_date',
    minWidth: 120,
    //@ts-ignore
     Cell: row => (
            <div>
              {moment(new Date(row?.original?.attributes?.order_date)).format('DD/MM/YYYY')}
            </div>
  )
  }, {
    Header: t("time"),
    accessor: 'time',
    //@ts-ignore
    Cell: row => (
      <div>
        {moment(new Date(row?.original?.attributes?.order_date)).format('hh:mm A')}
      </div>
    )
  }, {
    Header: t("items"),
    accessor: 'order_items',
    width: 300,

    //@ts-ignore
    Cell: row => (
      <div>{
      row?.original?.attributes?.order_items.map((elem:any) => {
        return(
          <p>{elem?.attributes?.catalogue?.attributes?.name+' X'+elem?.attributes?.quantity}</p>
        )
      })}
      </div>
    )
  }, {
    Header: t("total_amount"),
    accessor: 'amount',
    //@ts-ignore
    Cell: row => (
      <div>₹ {row?.original?.attributes?.amount}
      </div>
    )
  }, {
    Header: t("status"),
    accessor: 'status',
    //@ts-ignore
    Cell: (row) => (

      <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id={"demo-simple-select-value"+row?.index}
                value={row?.original?.attributes?.status}
                //@ts-ignore
                className={row?.original?.attributes?.status==='new'&&'new-status'||row?.original?.attributes?.status==='processing'&&'processing-status'||row?.original?.attributes?.status==='shipped'&&'shipped-status'||row?.original?.attributes?.status==='completed'&&'completed-status'||row?.original?.attributes?.status==='on_hold'&&'on_hold-status'||row?.original?.attributes?.status==='failed'&&'failed-status'}
                disableUnderline
                onChange={(e:any)=>{
                    if(value===0){
                    changeStatus(e.target.value,row?.original?.attributes?.id,row?.original?.attributes?.order_number,row?.index,row?.original?.attributes?.status,"getdata")
                    }else{
                      changeStatus(e.target.value,row?.original?.attributes?.id,row?.original?.attributes?.order_number,row?.index,row?.original?.attributes?.status)  
                    }
                  }}
              >
                <MenuItem value={'new'}>{t("new")}</MenuItem>
                <MenuItem value={'processing'} >{t("processing")}</MenuItem>
                <MenuItem value={'shipped'} >{t("shipped")}</MenuItem>
                <MenuItem value={'completed'}>{t("completed")}</MenuItem>
                <MenuItem value={'on_hold'} >{t("on_hold")}</MenuItem>
              </Select>
            </FormControl>
            </Box>
    )
  }]

  return (
  <div>
      <Box 
      //@ts-ignore
      // sx={{ width:"100%" }}
      className="table-box"
     >
      <Box 
      //@ts-ignore
      sx={{ borderBottom: 1, borderColor: 'divider',marginRight:"30px",marginTop:"30px"  }}>
        <Tabs value={value} 
        //@ts-ignore
        onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t("all")} {...a11yProps(0)} />
          <Tab label={t("new")} {...a11yProps(1)} />
          <Tab label={t("processing")} {...a11yProps(2)} />
          <Tab label={t("shipped")} {...a11yProps(3)} />
          <Tab label={t("completed")} {...a11yProps(4)} />
          <Tab label={t("on_hold")} {...a11yProps(5)} />
          <Tab label={t("failed")} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={value}>
      <CustomReactTable id='order-list' data={orderMainData} loading={loading}
      noDataText={t("no_orders_yet")} recordsPerPage={recordsPerPage}
            columns={columns} totalEntries={totalEntries} recordStartIndex={recordStartIndex} recordEndIndex={recordEndIndex} currentPage={currentPage} changePage={(e, page) => setCurrentPage(page)}
            //@ts-ignore
            openDrawerFunc={openDrawerFunc}/>
        <DashboardFooter/>
      </TabPanel>
    </Box>
    <SideDrawerForDetailWeb  openDrawer={openDrawer} closeDrawer={closeDrawer} orderClickedData={orderClickedData} changeStatus={changeStatus} currentStatus={currentStatus} sideDrawerLoading={sideDrawerLoading}/>
  </div>
  
  );
};
export default OrderDetailWeb;
//Customizable Area End
