// Customizable Area Start
import React, { useEffect, useState } from 'react'
import SidebarWeb from '../../../components/src/SidebarWeb.web'
//@ts-ignore
import Cookies from 'js-cookie';
import NavbarWeb from '../../../components/src/NavbarWeb.web';
import OrderDetailWeb from '../../OrderDetailView/src/OrderDetailWeb.web';
import ProductsWeb from '../../ProductQuickview/src/ProductsWeb.web';
import moment from "moment";
import InvoiceBilling from '../../InvoiceBilling/src/InvoiceBilling.web';
import NotificationsControllerWeb, { NotificatiosType, Props } from "./NotificationsControllerWeb.web";
import {Divider, CircularProgress} from '@material-ui/core';
import '../assets/styles.scss';
import DashboardFooter from '../../../components/src/DashboardFooter';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import FooterWeb from '../../dashboard/src/Footer.web';
const notificationImage = require('../assets/notificationGreen.jpg').default
import { translate } from 'framework/src/Translation';
export default class NotificationsWeb extends NotificationsControllerWeb {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const NotificationsContentComp = () => {
      return (
        <div className='notifications-wrapper'>
          {!this.state.loader ? <>{this.state.allNotification?.length !== 0 ? (
            this.state.allNotification.map((notification: NotificatiosType, index: number) => {
              const { attributes } = notification
              const { push_notificable_id, remarks, product_image, created_at } = attributes;
              return (
                <div className='notifications-list' key={push_notificable_id}>
                  <>
                    <div className='notification'>
                      <img src={product_image?.url || notificationImage} className='notif-img' />
                      <div className='typography'>
                        <div className='detail'>
                          <span className='status'>{remarks}</span>
                        </div>
                        <p className='time'>{moment(created_at).fromNow()}</p>
                      </div>
                    </div>
                    {(index !== this.state.allNotification?.length - 1) && (this.state.allNotification?.length > 1) && <Divider variant="middle" />}
                  </>
                </div>
              )
            })
          ) : (<h4 className='h4-noti'>{translate("no_notification_found")}</h4>)
          }</>
          :
          <CircularProgress style={{margin:'150px 45%'}}/> }
        </div>
      )
    }

    if (localStorage?.getItem('user_type') === 'merchant') {
      return (
        <div className="containerDas">
          <div className="sidebar-notifications-wrapper">
            <SidebarWeb
              openSidebar={this.state.menu}
              changeIconValue={this.showSidebar}
              setBranchName={this.setBranchName}
              keepNotificationView={this.state.keepNotificationView}
              history={this.props?.history}
            />
          </div>
          <div className="content-notifications-wrapper merchant">
            <NavbarWeb
              changeIconValue={this.showSidebar}
              branchName={this.state.branchName} history={this.props?.history}
              deleteAllNotifications={this.deleteAllNotifications}
              keepNotificationView={this.state.keepNotificationView}
            />
            <div style={{margin:"30px"}}>
            <NotificationsContentComp />
            <div className="footer-merchant-section">
              <DashboardFooter />
            </div>
            </div>
            
          </div>
        </div>
      )
    } else return (
      <>
        {/* @ts-ignore */}
        <BuyerNavbarWeb  visibleCartIcon={this.state.showIcon}  />
        <div className="buyer-notifications-wrapper buyer">
          <div className='notification-title-wrapper'>
            <h3 className='notification-title'>{translate("notifications")}</h3>
            {this.state.allNotification.length !==0 && <h3 className="notification-clear-all" onClick={() => 
            {
                this.deleteAllNotifications() 
            }}>
              {translate("clear_all")}
            </h3>}
            </div>
          <NotificationsContentComp />
        </div>
        <div className="footer-buyer-section">
          <FooterWeb />
        </div>
      </>
    )
  }
}
// Customizable Area End