import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Context } from "react";
import { AppContext } from "../../../components/src/context/AppContext";
import { AppContext as ContextType } from "../../../components/src/context/types";

type ApiRequestParams = {
  endPoint: string;
  headers?: Record<string, string>;
  body?: Record<string, any>;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  all: any;
  newOrders: any;
  processing: any;
  shipped: any;
  completed: any;
  onHold: any;
  failed: any;
  OrderDetails:any;
  searchTerm: string;
  loading: boolean;
  pageCount: number;
  pageNo: number;
  totalOrders: number;
  currentTab: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getOrdersApiCallId: any;
  updateOrderApiCallId: any;
  toastRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      all: true,
      newOrders: false,
  processing: false,
  shipped: false,
  completed: false,
  onHold: false,
  failed: false,
      pageNo: 1,
      pageCount:15,
      OrderDetails:[],
      
      // Customizable Area Start
      searchTerm:'',
      loading: false,
      totalOrders:0,
      currentTab:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    this.onFocus();
  }

  onFocus = () => {
    this.props.navigation.addListener("focus", () => {

      this.setState({
        all: true,
        newOrders: false,
  processing: false,
  shipped: false,
  completed: false,
  onHold: false,
  failed: false,
  currentTab: "",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("","",this.state.pageNo,this.state.pageCount);
      });
      
    });
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if(apiCallId === this.getOrdersApiCallId){
          this.setState({loading:false});
          if(this.state.pageNo===1)
          {
            this.setState({OrderDetails:[]},()=>{
              console.log("Am here in pageno 1");
            });
          }
          if(responseJson?.data)
          {
            if(this.state.searchTerm=="")
            {
            this.setState({totalOrders: responseJson.total_entries,OrderDetails:this.state.pageNo===1 ? responseJson.data : [...this.state.OrderDetails, ...responseJson.data]});
            //this.setState({totalOrders: responseJson.total_entries,OrderDetails: responseJson.data });
            }
            else if(this.state.searchTerm!="")
            {
              this.setState({totalOrders: 0,OrderDetails:[]},()=>{
                this.setState({totalOrders: responseJson.total_entries,OrderDetails:this.state.pageNo===1 ? responseJson.data : [...this.state.OrderDetails, ...responseJson.data]});
              })
              
            }
          }
          
          // responseJson?.message ? this.setState({loading:false}) : responseJson.data ?
          // this.setState({loading:false,totalOrders: responseJson.total_entries, OrderDetails: this.state.pageNo===1 ? responseJson.data : [...this.state.OrderDetails, ...responseJson.data]},()=>{
          //   //console.log("Orders response>>",JSON.stringify(this.state.OrderDetails));
          // }) : this.setState({loading:false});
        }
        else if(apiCallId=== this.updateOrderApiCallId)
        {
          console.log("Update Order Response>>",responseJson);
          responseJson.data ? this.setState({loading:false,OrderDetails:[],pageNo:1},
            ()=>{
              this.getOrdersData(this.state.currentTab, this.state.searchTerm,this.state.pageNo,this.state.pageCount);
            }) : null;
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End

  }

 

  // Customizable Area Start
  static contextType: Context<ContextType> = AppContext;

  makeApiRequest = ({ endPoint, method, headers, body }: ApiRequestParams) => {
    //this.setState({ loading: true });
    const defaultHeaders = {
      "Content-Type": "application/json",
      token: this.context.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if(body){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  getOrdersData = (category: string,searchterm:string,page:number,per_page:number) => {
    
    let enpoint = '';
    if(category!="" && searchterm !="")
    {
      enpoint=`bx_block_order_management/orders/order_listing?page=${page}&per_page=${per_page}&filter[status]=${category}&search=${searchterm}`;
    }
    else if(category!="" && searchterm =="")
    {
      this.setState({loading:true});
      enpoint=`bx_block_order_management/orders/order_listing?page=${page}&per_page=${per_page}&filter[status]=${category}`;
    }
    else if(category=="" && searchterm !="")
    {
      enpoint=`bx_block_order_management/orders/order_listing?page=${page}&per_page=${per_page}&search=${searchterm}`;
    }
    else if(category=="" && searchterm =="")
    {
      this.setState({loading:true});
      enpoint=`bx_block_order_management/orders/order_listing?page=${page}&per_page=${per_page}`;
    }

    this.getOrdersApiCallId = this.makeApiRequest({
      method: "GET",
      endPoint:  enpoint
    });
  }

  updateOrder =(orderStatus: string,orderId:number) => {
    this.setState({ loading: true });
    let body:Record<string, any> = {
      data: {
        type: "order",
        attributes: {
                status: orderStatus
            }
        }
    }

    this.updateOrderApiCallId = this.makeApiRequest({
      method: "PUT",
      endPoint: `bx_block_order_management/orders/${orderId}`,
      body
    });
  }
  
  

  // Customizable Area End

}
