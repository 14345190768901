// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
export const configJSON = require('./config.js');
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

// export const configJSON = require("./config.js");

export interface Props {
	//   navigation: any;
	//   id: string;
	//   history: any
}
interface S {
    ordersData: any;
    loader: boolean;
    searchVal: string;
    isFilterModalOpen: boolean;
    page: number;
    ordersEnd: boolean;
    filterDuration: number;
    filterFrequency: string;
}
interface SS {
	id: any;
}

export default class BuyerOrdersController extends BlockComponent<
	Props,
	S,
	SS
> {
	ApiGetOrdersId: string = '';
    ApiSearchOrdersId: string = '';
    ApiFilterOrdersId: string = '';
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		this.getBuyersOrders = this.getBuyersOrders.bind(this);
        this.searchOrders = this.searchOrders.bind(this);
        this.filterOrders = this.filterOrders.bind(this);
        this.callback = this.callback.bind(this)
		console.disableYellowBox = true;

		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
		];

		this.state = {
            ordersData: [],
            loader: true,
            searchVal: '',
            isFilterModalOpen: false,
            page: 1,
            ordersEnd: false,
            filterDuration: 1,
            filterFrequency: ''
        };
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

    static contextType?: Context<any> | undefined = AppContext;

    callback = (entries:any, observer:any) => {
        if(entries[0].isIntersecting){
            this.setState({
                page: this.state.page + 1
            })
            entries.forEach((entry:any) => {
                if(entry.isIntersecting){
                    this.getBuyersOrders()
                }
            });
        }
      };

    async componentDidMount() {
        this.getBuyersOrders()
    }

    componentDidUpdate(prevProps:any, prevState:any){
        if(this.state.loader !== prevState.loader && !this.state.loader && !this.state.ordersEnd){
        let loaderDiv:any = document.getElementById('loader')
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
          }
          
          let observer = new IntersectionObserver(this.callback, options);
          //@ts-ignore
          observer.observe((loaderDiv));
          return true
    }
    }

	getBuyersOrders() {
		const header = {
			'Content-Type': 'application/json',
			token: Cookies.get('Login_Token'),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.ApiGetOrdersId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_order_management/orders/order_listing?page=${this.state.page}&per_page=12`,
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET',
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

    searchOrders() {
		const header = {
			'Content-Type': 'application/json',
			token: Cookies.get('Login_Token'),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.ApiSearchOrdersId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_order_management/orders/order_listing?search=' + this.state.searchVal,
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET',
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

    filterOrders(){
        const header = {
			'Content-Type': 'application/json',
			token: Cookies.get('Login_Token'),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.ApiFilterOrdersId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_order_management/orders/order_listing?filter[duration]=${this.state.filterDuration}&filter[frequency]=${this.state.filterFrequency}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET',
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
    }

    async receive(from: string, message: Message) {
        try {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                var responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage),
                );
                var errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage),
                );
                const apiid = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage),
                );
                if (responseJson && !responseJson.errors  && !responseJson.error) {
                    if(apiid === this.ApiGetOrdersId){
                        this.setState({
                            ordersData:responseJson?.data ? [...this.state.ordersData, ...responseJson.data] : [],
                            loader: false,
                            ordersEnd: responseJson.end_to === responseJson.total_entries
                        })
                    }
                    else if (apiid === this.ApiSearchOrdersId){
                        if(responseJson.message){
                            this.setState({
                                ordersData:[],
                            })
                        }
                        else{
                            this.setState({
                                ordersData:responseJson.data,
                            })
                        }
                    }
                    else if(apiid === this.ApiFilterOrdersId){
                        if(responseJson.message){
                            this.setState({
                                ordersData:[],
                            })
                        }
                        else{
                            this.setState({
                                ordersData:responseJson.data,
                            })
                        }
                    }
                } else {
                  if(responseJson?.errors[0]){
                    toast.error(responseJson.errors[0].token)
                  }
                  else{
                    toast.error('Something went wrong')
                  }
                }
            }
        } catch (error) {}
    }
}
// Customizable Area End