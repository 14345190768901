import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";

import * as IMG_CONST from "./assets";
import styles from "./KycVerification8Style";
import LinearGradient from "react-native-linear-gradient";
import KycVerification8Controller, {
  Props,
} from "./KycVerification8Controller";
import FocusAwareStatusBar from "../../../components/src/FocusAwareStatusBar";
import Toast, { DURATION } from "react-native-easy-toast";
import ApplicationLoader from "../../../components/src/AppLoader/src/AppLoader";
import { translate } from "framework/src/Translation";
//Customizable Area End

export default class KycVerification8 extends KycVerification8Controller {
  renderHeader = () => {
    return (
      <View>
        <View style={styles.signupTextContainer}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.backArrowButton}
            onPress={() => this.props.navigation.goBack()}
          >
            <Image style={styles.backIcon} source={IMG_CONST.BACK_ARROW} />
          </TouchableOpacity>
          <Text style={styles.signupText}>{translate("kyc_verification")}</Text>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  renderUploadFolder = () => {
    return (
      <View style={styles.emailInputContainer}>
        <Text style={styles.emailText}>{translate("upload_pan_card")}</Text>
        <View
          style={[
            styles.emailField,
            { borderWidth: this.state.panCardImagePath ? 0 : 1 },
          ]}
        >
          {this.state.panCardImagePath ? (
            <View>
              <Image
                style={styles.uploadedImage}
                resizeMode="contain"
                source={{ uri: this.state.panCardImagePath }}
              />
              <TouchableOpacity
                onPress={() => this.setState({panCardData: null, panCardImagePath: null})}
                style={styles.crossIconButton}
              >
                <Image
                  source={IMG_CONST.CROSS_ICON}
                  style={styles.crossImg}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => this.onPressPickPanCard()}
              >
                <Image
                  style={styles.uploadIcon}
                  resizeMode="contain"
                  source={IMG_CONST.UPLOAD_ICON}
                />
              </TouchableOpacity>
              <Text style={styles.uploadText}>{translate("upload_pan_card")}</Text>
            </View>
          )}
        </View>
        {this.state.isInvalidPANCard && (
            <View style={{alignSelf: 'flex-start'}}>
              <Text
                style={styles.errorText}>
                {translate("please_upload_company_pan_card")}
              </Text>
            </View>
          )}
        <Text style={styles.emailText}>{translate("upload_aadhar_card")}</Text>
        <View
          style={[
            styles.emailField,
            { borderWidth: this.state.adharCardImagePath ? 0 : 1 },
          ]}
        >
          {this.state.adharCardImagePath ? (
            <View>
              <Image
                style={styles.uploadedImage}
                resizeMode="contain"
                source={{ uri: this.state.adharCardImagePath }}
              />
              <TouchableOpacity
                onPress={() => this.setState({adharCardData: null, adharCardImagePath: null})}
                style={styles.crossIconButton}
              >
                <Image
                  source={IMG_CONST.CROSS_ICON}
                  style={styles.crossImg}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => this.onPressPickAadharCard()}
              >
                <Image
                  style={styles.uploadIcon}
                  resizeMode="contain"
                  source={IMG_CONST.UPLOAD_ICON}
                />
              </TouchableOpacity>
              <Text style={styles.uploadText}>{translate("upload_aadhar_card")}</Text>
            </View>
          )}
        </View>
        {this.state.isInvalidAdharCard && (
            <View style={{alignSelf: 'flex-start'}}>
              <Text
                style={styles.errorText}>
                {translate("please_upload_companys_aadhar_card")}
              </Text>
            </View>
          )}
      </View>
    );
  };

  renderSubmitButton = () => {
    return (
      <TouchableOpacity
        onPress={() => this.onPressNextButton()}
        style={styles.loginButtonContainer}
      >
        <LinearGradient
          start={{ x: 0.3, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={styles.loginView}
        >
          <Text style={styles.loginText}>{translate("next")}</Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"white"}
          isFocused={true}
        />
        {this.renderHeader()}
        {this.renderUploadFolder()}
        {this.renderSubmitButton()}
        <ApplicationLoader
              isFetching={this.state.isFetching}
              {...this.props}
            />
        <Toast
              ref={(toast) => (this.toastRef = toast)}
              position={"bottom"}
              positionValue={150}
              textStyle={{ fontwidth: "bold", color: "#ffffff" }}
              fadeOutDuration={1000}
            />
      </View>
    );
  }
}
