// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history: any
}
interface S {
  openDeleteModal: boolean;
  allAddresses: any;
  loader: boolean;
  isSearchModalOpen: boolean;
  clickedRowId: string;
  selectedAddrId: string;
  deleteLoader: boolean;
  defaultChange: boolean;
}
interface SS {
  id: any;
}

export default class BuyerMyAccountController extends BlockComponent<
	Props,
	S,
	SS
> {
    apiGetAddressId: string = ''
    apiMakePrimaryId: string = ''
    apiDeleteAddressId: string = ''
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		console.disableYellowBox = true;
		this.getAddresses = this.getAddresses.bind(this);
		this.deleteAddress = this.deleteAddress.bind(this);
        this.makePrimary = this.makePrimary.bind(this);
		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
		];

		this.state = {
			openDeleteModal: false,
            allAddresses: [],
            loader: true,
            isSearchModalOpen: false,
            clickedRowId: '',
            selectedAddrId: '',
            deleteLoader: false,
            defaultChange: false
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	static contextType?: Context<any> | undefined = AppContext;

    async componentDidMount() {
       this.getAddresses();
    }

    shouldComponentUpdate(nextProps:any, nextState:any){
        if(nextState.defaultChange !== this.state.defaultChange)
        this.getAddresses()
        return true;
    }

	getAddresses(): boolean {
        this.setState({
            loader:true
        })
		const header = {
			'content-type': configJSON.ApiContentType,
			token: Cookies.get('Login_Token'),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.apiGetAddressId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getAddressEndpoint,
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.ApiGetMethodType,
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

    makePrimary(id:any) {
        const header = {
			'content-type': configJSON.ApiContentType,
			token: Cookies.get('Login_Token'),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.apiMakePrimaryId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.makePrimaryAddressEndPoint + id,
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.ApiPutMethodType,
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
    }

    deleteAddress(id:string){
        this.setState({
            deleteLoader:true
        })
        const header = {
			'content-type': configJSON.ApiContentType,
			token: Cookies.get('Login_Token'),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.apiDeleteAddressId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.deleteAddressEndpoint + id,
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.ApiDeleteMethodType,
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
    }
	async receive(from: string, message: Message) {
		try {
			if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
				var responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage),
				);
				var errorReponse = message.getData(
					getName(MessageEnum.RestAPIResponceErrorMessage),
				);
				const apiid = message.getData(
					getName(MessageEnum.RestAPIResponceDataMessage),
				);
                if (responseJson && !responseJson.errors  && !responseJson.error) {
                    if(apiid===this.apiGetAddressId){
                        this.setState({
                            allAddresses: responseJson.data,
                            loader: false
                        })
                    }
                    else if(apiid === this.apiMakePrimaryId){
                        this.setState({
                            defaultChange: !this.state.defaultChange
                        })
                        toast.success('Default Address changed successfully!')
                    }
                    else if(apiid === this.apiDeleteAddressId){
                        this.setState({
                            deleteLoader:false,
                            defaultChange: !this.state.defaultChange,
                            openDeleteModal: false
                        })
                        toast.success('Address deleted successfully!')
                    }
                } else {
                  if(responseJson?.errors[0]){
                    toast.error(responseJson.errors[0].token)
                  }
                  
                }
			}
		} catch (error) {}
	}
}
// Customizable Area End