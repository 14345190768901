// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import './app.scss';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Notifications from '../../blocks/Notifications/src/Notifications.web';
import LanguageSupport from '../../blocks/LanguageSupport/src/LanguageSupport';
import SavedCards from '../../blocks/SavedCards/src/SavedCards';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import CarouselDisplay from '../../blocks/CarouselDisplay/src/CarouselDisplay';
import CustomForm2 from '../../blocks/CustomForm2/src/CustomForm2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import Referrals from '../../blocks/Referrals/src/Referrals';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import ApiIntegration8 from '../../blocks/ApiIntegration8/src/ApiIntegration8';
import DiscountsOffers from '../../blocks/DiscountsOffers/src/DiscountsOffers';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import KycVerification8 from '../../blocks/KycVerification8/src/KycVerification8';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import ShippingOptions from '../../blocks/ShippingOptions/src/ShippingOptions';
import Search from '../../blocks/Search/src/Search';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import OpenApi from '../../blocks/OpenApi/src/OpenApi';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Payments from '../../blocks/Payments/src/Payments';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2';
import ProductQuickview from '../../blocks/ProductQuickview/src/ProductQuickview';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import LogoDesign2 from '../../blocks/LogoDesign2/src/LogoDesign2';
import GeolocationReporting from '../../blocks/GeolocationReporting/src/GeolocationReporting';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import CfInventoryManagement from '../../blocks/CfInventoryManagement/src/CfInventoryManagement';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ForgotPasswordweb from '../../blocks/forgot-password/src/ForgotPasswordWeb.web';
import OtpResetWeb from '../../blocks/forgot-password/src/OtpReset.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPasswordWeb.web';
import DocumentSubmitCompanyDetailWeb from '../../blocks/email-account-registration/src/DocumentSubmitCompanyDetailWeb.web';
import DocumentReviewWeb from '../../blocks/email-account-registration/src/DocumentReviewWeb.web';
import SignupOptions from '../../blocks/email-account-registration/src/SignUpOptions.web';
import TrendingWeb from '../../blocks/email-account-login/src/TrendingWeb.web';
import DashboardWeb from '../../blocks/dashboard/src/DashboardWeb.web';
import UserProfileWeb from '../../blocks/dashboard/src/UserProfileWeb.web';
import NotificationsFirebase from '../../web/src/Notifications';
import DashboardBuyer from '../../blocks/dashboard/src/DashboardBuyer.web';
import AboutUsWeb from '../../blocks/dashboard/src/AboutUsWeb.web';
import TermsAndConditionWeb from '../../blocks/dashboard/src/TermsAndConditionWeb.web';
import PrvacyPolicyWeb from '../../blocks/dashboard/src/PrvacyPolicyWeb.web';
import MyAccountBuyer from '../../blocks/LandingPage2/src/BuyerMyAccount.web';
import BuyerProductDetail from '../../blocks/catalogue/src/BuyerProductDetail.web';
import ProductsListing from '../../blocks/catalogue/src/ProductsListing.web';
import CartPage from '../../blocks/ShoppingCart/src/CartPage.web';
import SearchProductList from '../../blocks/catalogue/src/SearchProductList.web';
import BuyerProfile from '../../blocks/info-page/src/BuyerProfile.web';
import BuyerOrders from '../../blocks/info-page/src/BuyerOrders.web';
import BuyerChangePass from '../../blocks/info-page/src/BuyerChangePass.web';
import BuyerSavedCards from '../../blocks/info-page/src/BuyerSavedCards.web';
import BuyerReferral from '../../blocks/info-page/src/BuyerReferral.web';
import ContactUsBuyer from '../../blocks/dashboard/src/ContactUsBuyer.web';
import TodaysEarningWeb from '../../blocks/dashboard/src/TodaysEarningWeb.web'
import TotalEarningsWeb from '../../blocks/dashboard/src/TotalEarningsWeb.web'


const routeMap = {
  Notifications: {
    component: Notifications,
    path: '/Notifications',
    userType: 'public'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport',
    userType: 'public'
  },
  SavedCards: {
    component: SavedCards,
    path: '/SavedCards',
    userType: 'public'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications',
    userType: 'public'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus',
    userType: 'public'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
    userType: 'public'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics',
    userType: 'public'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
    userType: 'public'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
    userType: 'public'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus',
    userType: 'public'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails',
    userType: 'public'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    userType: 'public'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView',
    userType: 'public'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals',
    userType: 'public'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications',
    userType: 'public'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary',
    userType: 'public'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
    userType: 'public',
    flag: 'signup'
  },
  KycVerification8: {
    component: KycVerification8,
    path: '/KycVerification8',
    userType: 'public'
  },
  Search: {
    component: Search,
    path: '/Search',
    userType: 'public'
  },
  LandingPage2: {
    component: LandingPage2,
    path: '/LandingPage2',
    userType: 'public'
  },
  ProductQuickview: {
    component: ProductQuickview,
    path: '/ProductQuickview',
    userType: 'public'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart',
    userType: 'buyer'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    userType: 'buyer'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
    userType: 'public'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
    userType: 'public'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
    userType: 'public'
  },
  ForgotPasswordweb: {
    component: ForgotPasswordweb,
    path: '/forgotPass',
    exact: true,
    userType: 'public'
  },
  OtpResetWeb: {
    component: OtpResetWeb,
    path: '/otpReset',
    exact: true,
    userType: 'public'
  },
  ResetPasswordWeb: {
    component: ResetPasswordWeb,
    path: '/resetPass',
    exact: true,
    userType: 'public'
  },
  DocumentSubmitCompanyDetailWeb: {
    component: DocumentSubmitCompanyDetailWeb,
    path: '/docsCompany',
    exact: true,
    userType: 'merchant'
  },
  DocumentReviewWeb: {
    component: DocumentReviewWeb,
    path: '/profileReview',
    exact: true,
    userType: 'merchant'
  },
  SignupOptionsWeb: {
    component: SignupOptions,
    path: '/signup-options',
    exact: true,
    userType: 'public'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/otp-verification',
    exact: true,
    userType: 'public'
  },
  // TrendingWeb: {
  //   component: TrendingWeb,
  //   path: '/',
  //   exact: true,
  // },
  DashboardWeb: {
    component: DashboardWeb,
    path: '/dashboard',
    exact: true,
    userType: 'merchant'
  },
  TodaysEarningWeb: {
    component: TodaysEarningWeb,
    path: '/TodaysEarning',
    exact: true,
    userType: 'merchant'
  },
  TotalEarningsWeb: {
    component: TotalEarningsWeb,
    path: '/TotalEarnings',
    exact: true,
    userType: 'merchant'
  },
  UserProfileWeb: {
    component: UserProfileWeb,
    path: '/profile',
    exact: true,
    userType: 'merchant'
  },
  DashboardBuyer: {
    component: DashboardBuyer,
    path: '/',
    exact: true,
    userType: 'public',
  },
  AboutUsWeb: {
    component: AboutUsWeb,
    path: '/about',
    exact: true,
    userType: 'public'
  },
  TermsAndConditionWeb: {
    component: TermsAndConditionWeb,
    path: '/terms',
    exact: true,
    userType: 'public'
  },
  PrvacyPolicyWeb: {
    component: PrvacyPolicyWeb,
    path: '/privacy',
    exact: true,
    userType: 'public'
  },
  ContactUsBuyer: {
    component: ContactUsBuyer,
    path: '/contact-us',
    exact: true,
    userType: 'buyer'
  },
  MyAccountBuyer: {
    component: MyAccountBuyer,
    path: '/account/addresses',
    exact: true,
    userType: 'buyer'
  },
  ProductsListing: {
    component: ProductsListing,
    path: '/products/:id',
    userType: 'public'
  },
  BuyerProductDetail: {
    component: BuyerProductDetail,
    path: '/product-details/:productId',
    // exact: true,
    userType: 'public'
  },
  CartPage: {
    component: CartPage,
    path: '/cart',
    exact: true,
    userType: 'buyer'
  },
  SearchProductList: {
    component: SearchProductList,
    path: '/all-products',
    exact: true,
    userType: 'public'
  },
  BuyerProfile: {
    component: BuyerProfile,
    path: '/account/profile',
    exact: true,
    userType: 'buyer'
  },
  BuyerOrders: {
    component: BuyerOrders,
    path: '/account/my-orders',
    exact: true,
    userType: 'buyer'
  },
  BuyerChangePass: {
    component: BuyerChangePass,
    path: '/account/change-password',
    exact: true,
    userType: 'buyer'
  },
  BuyerSavedCards: {
    component: BuyerSavedCards,
    path: '/account/saved-cards',
    exact: true,
    userType: 'buyer'
  },
  BuyerReferral: {
    component: BuyerReferral,
    path: '/account/referrals',
    exact: true,
    userType: 'buyer'
  }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });


class App extends Component {
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div>
        <NotificationsFirebase />
        <View>
          <WebRoutesGenerator routeMap={ routeMap}/>
          <ModalContainer props={this.props} />
        </View>  
      </div>
    );
  }
}

export default App;