//Customizable Area Start
import React from 'react';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerChangePassController from './BuyerChangePassController.web';
import BuyerSidebar from './BuyerSidebar.web';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	TextField,
	Button,
	CircularProgress,
    Box,
    Modal
} from '@material-ui/core';
import { configJSON } from '../../info-page/src/BuyerChangePassController.web';
import '../assets//BuyerChangePass.scss';
import FooterWeb from '../../dashboard/src/Footer.web';
const pass_changed = require('../assets/pass_changed.png').default
import { translate } from 'framework/src/Translation';

const styles = () => ({
	noBorder: {
		border: '1px solid #cacaca',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class BuyerChangePass extends BuyerChangePassController {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
					{/* @ts-ignore */}
					<BuyerSidebar activeTab={5} />

					<div className='profile-container'>
						<div>
							<Typography className='top-heading'>{translate("change_password")}</Typography>
						</div>
						<div className='buyer-changePass-con'>
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>
									{translate("current_password")}
								</Typography>
								<TextField
									id='old_password'
									className='profile-field'
									placeholder={translate("current_password")}
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									InputProps={{
										//@ts-ignore
										className: this.props.classes.input,
										classes: {
											//@ts-ignore
											notchedOutline: this.props.classes.noBorder,
										},
									}}
									type={'password'}
									value={this.state.oldPassword}
									variant='outlined'
									onChange={(e) => {
										if (configJSON.passwordRegex.test(e.target.value)) {
											this.setState({
												oldErr: false,
											});
										} else {
											this.setState({
												oldErr: true,
											});
										}
										this.setState({
											oldPassword: e.target.value,
										});
									}}
								/>
								{this.state.oldErr && (
									<div className='err-text'>{configJSON.passwordInValid}</div>
								)}
							</div>
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>
									{translate("new_password")}
								</Typography>
								<TextField
									id='new_password'
									className='profile-field'
									placeholder={translate("new_password")}
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									InputProps={{
										//@ts-ignore
										className: this.props.classes.input,
										classes: {
											//@ts-ignore
											notchedOutline: this.props.classes.noBorder,
										},
									}}
									value={this.state.newPassword}
									variant='outlined'
									type={'password'}
									onChange={(e) => {
										this.setState({
											newPassword: e.target.value,
										});
										if (configJSON.passwordRegex.test(e.target.value)) {
											this.setState({
												newErr: false,
											});
										} else {
											this.setState({
												newErr: true,
											});
										}
                                        if(this.state.oldPassword === e.target.value && !this.state.oldErr){
											this.setState({
												newSameErr: true,
											});
										} else {
											this.setState({
												newSameErr: false,
											});
										}
										if (e.target.value !== this.state.confirmNewPassword) {
											this.setState({
												confirmNewErr: true,
											});
										} else {
											this.setState({
												confirmNewErr: false,
											});
										}
									}}
								/>
								{this.state.newErr && (
									<p className='err-text'>{configJSON.passwordInValid}</p>
								)}
                                {this.state.newSameErr && (
									<p className='err-text'>New password can't be same as the current one</p>
								)}
							</div>
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>
									{translate("confirm_new_password")}
								</Typography>
								<TextField
									id='confirm_new_password'
									className='profile-field'
									placeholder={translate("confirm_new_password")}
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									InputProps={{
										//@ts-ignore
										className: this.props.classes.input,
										classes: {
											//@ts-ignore
											notchedOutline: this.props.classes.noBorder,
										},
									}}
									value={this.state.confirmNewPassword}
									variant='outlined'
									type={'password'}
									onChange={(e) => {
										this.setState({
											confirmNewPassword: e.target.value,
										});
										if (configJSON.passwordRegex.test(e.target.value)) {
											this.setState({
												confirmNewErr: false,
											});
										} else {
											this.setState({
												confirmNewErr: true,
											});
										}
										if (this.state.newPassword !== e.target.value) {
											this.setState({
												confirmNewErr: true,
											});
										} else {
											this.setState({
												confirmNewErr: false,
											});
										}
									}}
								/>
								{this.state.confirmNewErr && (
									<div className='err-text'>
										Confirm password does not match
									</div>
								)}
							</div>
						</div>
						<Button
							variant='outlined'
							className='profile-save-btn'
							onClick={() => {
								this.updatePassword();
							}}
							disabled={this.state.loader}
						>
							{this.state.loader ? (
								<CircularProgress
									style={{
										width: '40px',
										height: '40px',
										margin: '0 auto',
									}}
								/>
							) : (
								translate("update_password")
							)}
						</Button>
					</div>
				</div>
                <FooterWeb />
				{this.state.changePassModal && (
					//@ts-ignore
					<Modal
						open={this.state.changePassModal}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box className='box-modal'>
							<div className='div-modal'>
								<img src={pass_changed} alt='' />
							</div>
							<h4 className='div-modal-h4'>{translate("password_changed")}</h4>
							<Typography>
								<p className='p-tag-modal'>
									{translate("password_changed_successfully")}
								</p>
							</Typography>
							<div
								style={{
									textAlign: 'center',
									marginBottom: '25px',
									display: 'flex',
                                    justifyContent: 'space-between'
								}}
							>
								<Button
									variant='contained'
									className='modal-btn'
									onClick={() => {
										this.setState({
                                            changePassModal: false
                                        })
									}}
								>
									{translate("done")}
								</Button>
							</div>
						</Box>
					</Modal>
				)}
			</>
		);
	}
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerChangePass));
//Customizable Area End
