//Customizable Area Start
import React, { useEffect, useState } from "react";
//@ts-ignore
import Cookies from 'js-cookie';
import '../assets/DashboardWeb.scss'
import "../../../components/src/styles.scss";
import {toast} from 'react-toastify';
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import OrderDetailWeb from "../../OrderDetailView/src/OrderDetailWeb.web";
import ProductsWeb from "../../ProductQuickview/src/ProductsWeb.web";
import 'react-toastify/dist/ReactToastify.css';
import InvoiceBilling from "../../InvoiceBilling/src/InvoiceBilling.web";
import HomePageWeb from "./HomePageWeb.web";
toast.configure()
export const configJSON = require("./config");
const DashboardWeb= (props:any) => {
  const [branchName, setbranchName] = useState<any>('1');
  const [menu,setMenu] = useState<boolean>(false);
  const [addProd, setAddProd] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [orderedSearchedValue,setOrderedSearchedValue] = useState<any>("");
  const [insearchedValue, setinSearchedValue] = useState<any>('')
  const [totalLengthOfOrder, settotalLengthOfOrder] = useState<any>('');
  const [loader, setLoader] = useState(true)
  const [flag,setFlag] = useState(true)
  const setBranchName =(num:any)=>{
    setFlag(!flag)
    setbranchName(num)
  }

  const token = Cookies.get('Login_Token')
  useEffect(() => {
    if(!token){
      props?.history?.push("/login")
    }else{
      if(localStorage.getItem('user_type')==='merchant' && Cookies.get('account_activated') !== 'yes'){
        Cookies.remove('Login_Token')
        props?.history?.push("/")
        toast.error("Your profile is under review! please login after some time")
      }
        setLoader(false)
    }
    console.log("I am inside dashboard component")
  },[]);
  const setTotalLengthOfOrderFunc=(length:any)=>{
    settotalLengthOfOrder(length) 
  }
  const showSidebar=(childData:any)=>{
    setMenu(childData);
  }
  return (
    (!loader && <div className="containerDas">
    <div className="bannerDas">
    <SidebarWeb openSidebar={menu} changeIconValue={showSidebar}  history={props?.history} setBranchName={setBranchName} activeBranchState={props?.location?.state}/>
    </div>
    <div className="auth-form-wrapper w-50">
         
        <NavbarWeb  changeIconValue={showSidebar}   branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} history={props?.history} searchedValue={searchedValue} setSearchedValue={setSearchedValue} insearchedValue={insearchedValue} setinSearchedValue={setinSearchedValue} orderedSearchedValue={orderedSearchedValue} setOrderedSearchedValue={setOrderedSearchedValue} unreadNotif={flag}/>
        {
          branchName==='1'&&
          <HomePageWeb navigation={undefined} id={""} />
        }
        {
          branchName==='2'&&
        <OrderDetailWeb setTotalLengthOfOrderFunc={setTotalLengthOfOrderFunc} searchedValue={searchedValue} setSearchedValue={setSearchedValue} orderedSearchedValue={orderedSearchedValue} setOrderedSearchedValue={setOrderedSearchedValue}/>
        }
        {
        branchName==='3'&&
        <ProductsWeb addProd={addProd} setAddProd={setAddProd} searchedValue={searchedValue} setSearchedValue={setSearchedValue}/>
        }
        {
        branchName==='4'&&
        <InvoiceBilling insearchedValue={insearchedValue} setinSearchedValue={setinSearchedValue}/>
        }
     </div>
  </div>)
  
  );
};
export default DashboardWeb;
//Customizable Area End