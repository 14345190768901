// Customizable Area Start
import React, { useEffect, useState } from "react";
import "../assets/HomePageWeb.scss";
import HomePageWebController, { Props } from "./HomePageWebController.web";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import Chart from "react-apexcharts";
// import carrot from "./../assets/carrot.jpg";
// import tomato from "./../assets/tomato.jpeg";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import MenuItem  from "@material-ui/core/MenuItem";
import { borderRadius } from "react-select/src/theme";
import DashboardFooter from "../../../components/src/DashboardFooter";
import { translate } from "framework/src/Translation";
export default class HomePageWeb extends HomePageWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    this.getDashboardData(this.state.duration);
    this.getRecentSoldProduct();
  }
  
  render() {
    return (
      <div className="home-content">
        <div className="overview-boxes">
          
          <div className="box">
          {!this.state.loader&&
            <>
            <div className="box-header">{translate("new_orders")}</div>
            <div className="number">{this.state?.newOrders}</div>
            </>
            }
         </div>
          <div className="box">
            {!this.state.loader&&
            
            <Link style={{cursor:"pointer"}} to={{pathname:"/TodaysEarning",state:this.state?.todaysEarning}} >
            <div className="box-header">{translate("todays_earnings")}</div>
            <div className="number">&#x20b9; {this.state?.todaysEarning}</div>
            </Link>
            }
          </div>
          <div className="box">
            {!this.state.loader &&
            <>
            <div className="box-header">{translate("total_orders")}</div>
            <div className="number">{this.state?.totalOrders}</div>
            </>
            }
          </div>
          <div className="box">
            {!this.state.loader &&
            <Link style={{cursor:"pointer"}} to={{pathname:"/TotalEarnings",state:this.state?.totalEarnings}}>
            <div className="box-header">{translate("total_earnings")}</div>
            <div className="number">&#x20b9; {this.state?.totalEarnings}</div>
            </Link>
            }
          </div>
        </div>
        <div className="main">
          <div className="earning">
            <div className="earning-title">{translate("earnings")}</div>
            <div className="earning-period">
              <FormControl variant="outlined">
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state?.duration}
                    onChange={(e)=>this.earningDuration(e)}
                    >
                    <MenuItem value="week">{translate("weekly")}</MenuItem>
                    <MenuItem value="month">{translate("monthly")}</MenuItem>
                    <MenuItem value="year">{translate("yearly")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="earning-chart">
                 <Chart options={this.state?.options}  series={this.state?.series} type="bar"/> 
              </div>
          </div>
          <div className="top-sold">
            <div className="recent-title">{translate("recently_sold_products")}</div>
            {!this.state.loader?
            <>{this?.state?.recentSoldProduct.length!==0?
            <List className="product-list">
             
              {this.state?.recentSoldProduct?.map((recentProductDetail: any) => {
                const {attributes} = recentProductDetail;
                const {name, images, quantity, value, sold_quantity,id} = attributes;
                return (
                  <Link to={`/product-details/${id}`}>
                  <ListItem alignItems="flex-start">
                    <img className="imgs" alt="carrot" src={images?.[0]?.url} />
                    <ListItemText
                      className="list-text"
                      primary={name}
                      secondary={
                        <>
                          <Typography className="product-quantity" variant="body1">
                            {value} {quantity}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="sold-quantity"
                          >
                            QTY: {sold_quantity}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  </Link>
                );
              })}
                <Link to='products/recently-sold'>
                  <Button style={{ border: '2px solid #58953D', borderRadius: 10, height: 50 }} variant="outlined" className="btns">
                    {translate("view_all")}
                  </Button>
                </Link>
            </List>:
            <div className="empty-list"><Typography className="product-fonts">{translate("no_recent_sold_product_found")}</Typography></div>
             }</>:<CircularProgress style={{margin:'150px 45%'}}/> }
                       
          </div>
        </div>
        <div className="home-footer">
          <DashboardFooter/>
        </div>
      </div>
    );
  }
}
// Customizable Area End
