// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
export const configJs = require("./config.js");

export interface Props {
  match: any,
  location: any,
  history: any
}
interface S {
  dashboardData: any;
  loader: boolean;
  listing: string;
  catLoading: boolean;
  count: number;
  productsListEnd: boolean;
}
interface SS {
  id: any;
}

export default class ProductsListingController extends BlockComponent<Props, S, SS> {
    apiProductListingId:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.callback = this.callback.bind(this)
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        dashboardData: [],
        loader: true,
        listing: '',
        catLoading: true,
        count: 1,
        productsListEnd: false,
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
   callback = (entries:any, observer:any) => {
    if(entries[0].isIntersecting){
        this.setState({
            count: this.state.count + 1
        })
        entries.forEach((entry:any) => {
            if(entry.isIntersecting){
                this.getProducts()
            }
        });
    }
  };

  async componentDidMount() {
    // if(Cookies?.get('Login_Token')){
        if(this.props.location.state || this.props.match.params.id==='top' || this.props.match.params.id==='featured' || this.props.match.params.id==='recently-sold'){
        this.setState({
            catLoading: false,
            // loader: true
        })
        this.getProducts()
        }
        else if(!this.props.location.state &&  this.props.match.params.id==='category'){
        this.props.history.push('/')
        // }
    }
  }

  shouldComponentUpdate(nextProps:any, nextState:any){
    if((this.props.location.state?.id !== nextProps.location.state?.id) || (this.props.match.params.id !== nextProps.match.params.id)){
      this.setState({
            count:1,
            loader:true,
            dashboardData: [],
        })
        setTimeout(() => {
            this.getProducts()
        },0);
        return true;
    }
   else return true
    }

    componentDidUpdate(prevProps:any, prevState:any){
        if(this.state.loader !== prevState.loader && !this.state.loader && !this.state.productsListEnd){
        let loaderDiv:any = document.getElementById('loader')
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
          }
          let observer = new IntersectionObserver(this.callback, options);
          //@ts-ignore
          observer.observe((loaderDiv));
          return true
    }
    }

    // componentWillUnmount() {
    //   this.setState({    dashboardData: [],
    //     loader: true,
    //     listing: '',
    //     catLoading: true,
    //     count: 1,
    //     productsListEnd: false,})
    // } 

  getProducts(): boolean {
    let pathID = this.props.match.params.id;
    let apiEndPoint = pathID === 'category' ? `bx_block_catalogue/catalogues?page=${this.state.count}&per_page=20&filter[category]=${this.props.location.state.id}`: pathID === 'recently-sold' ? configJs.getRecentlySoldProductsApiEndPoint(this.state.count, 20) : 'bx_block_dashboard/product?per_page=60';
    const header = pathID === 'recently-sold' ? {
      'Content-Type': 'application/json',
      token: Cookies.get('Login_Token')
    } : {
      'Content-Type': 'application/json'
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiProductListingId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET',
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

  async receive(from: string, message: Message) {
    try {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors  && !responseJson.error) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiProductListingId &&
            responseJson !== undefined
          ) {
                if(this.props.match.params.id==='top'){
                    this.setState({
                        dashboardData:responseJson.top_products.data,
                        loader:false,
                        productsListEnd:true
                    })
                }
                else if(this.props.match.params.id==='featured'){
                    this.setState({
                        dashboardData:responseJson.featured_products.data,
                        loader:false,
                        productsListEnd:true
                    })
                }
                else if(this.props.match.params.id==='category'){
                    if(this.state.dashboardData.length===0 || responseJson.data.length===0 || responseJson.data[0].attributes.category.id !== this.state.dashboardData[0].attributes.category.id){
                        this.setState({
                            dashboardData: responseJson.data,
                            loader:false,
                            productsListEnd: responseJson.end_to === responseJson.total_entries
                        })
                    }
                    else
                    {
                        this.setState({
                            dashboardData:[...this.state.dashboardData, ...responseJson.data],
                            loader:false,
                            productsListEnd: responseJson.end_to === responseJson.total_entries
                        })
                    }
                }
               else if(this.props.match.params.id==='recently-sold'){
                if(this.state.dashboardData.length===0 || responseJson.data.length===0 || responseJson.data?.[0]?.id === this.state.dashboardData?.[0]?.id){
                  this.setState({
                      dashboardData: responseJson.data,
                      loader:false,
                      productsListEnd: responseJson.end_to === responseJson.total_entries
                  })
              }
              else
              {
                  this.setState({
                      dashboardData:[...this.state.dashboardData, ...responseJson.data],
                      loader:false,
                      productsListEnd: responseJson.end_to === responseJson.total_entries
                  })
              }
              }
            } 
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.error;
        toast.error(errors)
        
      }
    }
      
    } catch (error) {
    
    }
    
  }
}
// Customizable Area End

