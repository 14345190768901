import { StyleSheet, Platform } from "react-native";
import { Colors, dimensions } from "../../../components/src/constants";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import scale, { verticalScale } from "../../../framework/src/Scale";

export default StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: COLOR_CONST.whiteColor,
    },
  
    signupTextContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      // margin: verticalScale(20),
      justifyContent: "space-between",
      marginTop: verticalScale(20),
      marginLeft: scale(20),
      marginRight: scale(20),
      marginBottom: scale(5)
    },
  
    backArrowButton: {
      width: scale(30),
      height: scale(35),
    },
  
    growCart: {
      width: scale(130),
      height: scale(30),
    },
  
    NotifictionIcon: {
      width: scale(20),
      height: scale(20),
  
    },
  
    signupText: {
      fontSize: scale(20),
      fontFamily: FONTS.PoppinsDisplaySemiBold,
      color: COLOR_CONST.blackColor,
      marginBottom: verticalScale(15),
    },
  
    horizontalLine: {
      width: scale(375),
      height: scale(2),
      marginTop: verticalScale(7),
      backgroundColor: COLOR_CONST.borderColor,
    },
  
    totalNewOrdersEarningsView: {
      width: "100%",
      height: scale(180),
      justifyContent: "space-evenly",
      marginTop: scale(5),
  
    },
  
    NewOrdersMainViewStyle: {
      width: dimensions.wp(45),
      height: scale(80),
      borderRadius: scale(10),
      overflow: "hidden",
      flexDirection: "row",
      alignItems: "center",
      borderWidth: 0.2,
      borderColor: "gray",
  
    },
  
    NewOrdersYollowLineStyle: {
      width: scale(7),
      height: scale(80),
      backgroundColor: "#ebc634"
    },
  
    NewOrdersTextViewStyle: {
      height: scale(50),
      //justifyContent: "",
      marginLeft: scale(12)
    },
    countTextStyle: {
      fontSize: scale(16),
      fontFamily: FONTS.PoppinsDisplaySemiBold
    },
  
    newOrdersTextStyle: {
      fontSize: scale(15)
    },
  
    grapWheeklyTextMainView: {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: verticalScale(15)
    },
  
    grapWheeklyTextView: {
      flexDirection: "row",
      alignItems: "center",
    },
  
    grapWheeklyText: {
      fontSize: scale(15),
      fontFamily: FONTS.PoppinsDisplayMedium
    },
  
    grapWheeklyTextImageIcon: {
      width: scale(10),
      height: scale(10),
      marginLeft: scale(5)
    },
  
    recentlySoldProductsTextMainView: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: scale(15),
      marginRight: scale(15),
      marginTop: verticalScale(15)
    },
  
    recentlySoldProductsText: {
      fontFamily: FONTS.PoppinsDisplayBold,
      fontSize: scale(15)
    },
  
    recentlySoldProductsViewAllText: {
      fontFamily: FONTS.PoppinsDisplayBold,
      fontSize: scale(15),
      color: "#f7b720"
    },
  
    flatListMainView: {
      marginLeft: scale(15),
      marginTop: verticalScale(10)
    },
  
    flatListImageMainView: {
      width: scale(150),
      height: scale(150),
      borderRadius: scale(10),
      backgroundColor: "red",
      overflow: "hidden"
    },
  
    flatListImageView: {
      width: scale(150),
      height: scale(150),
    },
  
    flatListItemNameText: {
      fontSize: scale(13),
      fontFamily: FONTS.PoppinsDisplaySemiBold,
      marginTop: verticalScale(10)
    },
  
    flatListItemWeightText: {
      fontSize: scale(13),
      color: "gray"
    },
  
    flatListItemQtyText: {
      fontSize: scale(13),
      fontFamily: FONTS.PoppinsDisplayRegular
    },
  
    renderMonthMainViewFlatList: {
      width: scale(150),
      left: scale(15),
      borderRadius: scale(10),
      position: 'absolute',
      top: verticalScale(40),
      zIndex: 9999,
      overflow: 'hidden',
      // borderWidth: 0.2
    },
  
    renderdateMonthlyPopupMainFlatList: {
      width: scale(200),
      right: scale(15),
      borderRadius: scale(10),
      position: 'absolute',
      top: verticalScale(40),
      zIndex: 9999,
      overflow: 'hidden',
      // borderWidth: 0.2,
    },
    product: {
      marginLeft: dimensions.wp(5)
    },
    imageContainer: {
      borderRadius: dimensions.wp(3),
      overflow: "hidden",
      height: (dimensions.wp(80) - dimensions.wp(5)) / 2,
      marginBottom: dimensions.hp(1)
    },
    productImage: {
      width: (dimensions.wp(80) - dimensions.wp(5)) / 2,
      aspectRatio: 1
    },
    totalsSkeleton: {
      marginLeft: dimensions.wp(5)

    },
    NewOrderSkeltonMainViewStyle: {
      width: dimensions.wp(45),
      height: scale(80),
      borderRadius: scale(10),
      overflow: "hidden",
      flexDirection: "row",
      alignItems: "center",
      borderWidth: 0.2,
      borderColor: "gray",
  
    },
    marginContainer: {
      marginHorizontal: dimensions.wp(5)
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: dimensions.hp(3),
      marginBottom: dimensions.hp(1)
    },
    graphStyle:{
      marginVertical: scale(8)
    },
    unread: {
      position: "absolute",
      height: dimensions.hp(1.4),
      width: dimensions.hp(1.4),
      borderRadius: dimensions.hp(.70),
      backgroundColor: Colors.orange,
      top: 0,
      right: 0,
      borderColor: Colors.white,
      borderWidth: 2
    }
  });