import { StyleSheet, Platform } from "react-native";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import scale, { verticalScale } from "../../../framework/src/Scale";

// Customizable Area Start
// Customizable Area End

export default StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    backgroundColor: COLOR_CONST.whiteColor,
  },

  signupTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: verticalScale(40),
    marginLeft: scale(20),
  },

  backArrowButton: {
    width: scale(30),
    height: scale(35),
  },

  backIcon: {
    width: scale(10),
    height: scale(20),
  },

  signupText: {
    fontSize: scale(20),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    marginBottom: verticalScale(15),
  },

  horizontalLine: {
    width: scale(375),
    height: scale(2),
    backgroundColor: COLOR_CONST.borderColor,
  },

  emailInputContainer: {
    marginTop: verticalScale(20),
    marginLeft: scale(20),
  },

  enterEmailText: {
    fontSize: Platform.OS === "ios" ? scale(15) : scale(14),
    fontFamily: FONTS.PoppinsDisplayRegular,
    color: COLOR_CONST.blackColor,
    marginBottom: verticalScale(15),
  },

  emailText: {
    fontSize: scale(16),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    alignSelf: "flex-start",
    marginTop: verticalScale(15),
  },

  emailField: {
    alignItems: "center",
    justifyContent: "center",
    width: scale(335),
    height: verticalScale(200),
    borderRadius: scale(12),
    borderColor: COLOR_CONST.loginTextColor,
    borderStyle: "dashed",
    marginTop: verticalScale(8),
    backgroundColor: COLOR_CONST.whiteColor,
    // overflow: "hidden",
  },

  panCardContainer:{
    width: scale(335),
    height: verticalScale(250),
    borderRadius: scale(12),
  },

  uploadIcon: {
    width: scale(30),
    height: scale(35),
  },

  loginButtonContainer: {
    position: "absolute",
    bottom: verticalScale(20),
    alignSelf: "center",
  },

  loginView: {
    width: scale(335),
    height: scale(55),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(10),
  },

  loginText: {
    fontSize: scale(15),
    color: COLOR_CONST.whiteColor,
    fontFamily: FONTS.PoppinsDisplayMedium,
  },

  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR_CONST.whiteColor,
  },

  resetSuccessIcon: {
    width: scale(61),
    height: scale(61),
  },

  resetPasswordText: {
    fontSize: scale(20),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    marginTop: verticalScale(15),
  },

  resetPasswordChangedText: {
    fontSize: Platform.OS === "ios" ? scale(15) : scale(14),
    fontFamily: FONTS.PoppinsDisplayRegular,
    color: COLOR_CONST.blackColor,
  },

  uploadText: {
    fontSize: scale(16),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.loginTextColor,
    alignSelf: "flex-start",
    marginTop: verticalScale(10),
  },

  uploadedImage:{
    width: scale(335),
    height: verticalScale(200),
    borderRadius: scale(12),
  },

  crossIconButton: {
    height: scale(30),
    width: scale(30),
    position: "absolute",
    alignSelf: "flex-end",
    top: verticalScale(-15),
    right: scale(-5),
  },

  crossImg: {
    height: scale(30),
    width: scale(30),
  },

  errorText: {
    fontSize: scale(12),
    fontFamily: FONTS.PoppinsDisplayMedium,
    color: "red",
    marginLeft: scale(15),
    marginTop: verticalScale(10),
  },
  // Customizable Area End
});
