import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Keyboard } from "react-native";
import * as Validators from "../../../framework/src/Validators";
import dynamicLinks from "@react-native-firebase/dynamic-links";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFetching: boolean;
  email: string;
  isInvalidEmail: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForgotPasswordCallId: string = "";
  apiSendDeepLinkCallId: string = "";
  toastRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    this.state = {
      email: "",
      isInvalidEmail: false,
      isFetching: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    //Customizable Area Start
    super.componentDidMount();
    //Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiForgotPasswordCallId) {
          this.forgotPasswordAPISuccessCallBack(responseJson);
        } else if (apiRequestCallId === this.apiSendDeepLinkCallId) {
          this.sendDeepLinkAPISuccessCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiForgotPasswordCallId) {
          this.forgotPasswordAPIFailureCallBack(responseJson);
        } else if (apiRequestCallId === this.apiSendDeepLinkCallId) {
          this.sendDeepLinkAPIFailureCallBack(responseJson);
        }
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse);
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  //Customizable Area Start
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token =
      "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzksImV4cCI6MTYzMzc3MDA1Nn0.t5w6tWVzcnLD-J9wTpKf8iVql5JKz3IKrSccg7408S869WeVRzmxWbwFe3UnpWPLf5fLbbY8_mrNN3IQH_JARw";
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressSubmitButton = async () => {
    // const getLink = await this.generateLink()
    // console.log("@@@ Generated Deep Link ==========>",getLink)
    Keyboard.dismiss();
    if (
      this.state.email.trim().length === 0 ||
      !Validators.isEmailValid(this.state.email)
    ) {
      this.setState({ isInvalidEmail: true });
      return;
    }
    this.setState({ isFetching: true });
    let forgotEmail = {
      data: {
        attributes: {
          email: this.state.email.toLocaleLowerCase(),
        },
      },
    };
    this.apiForgotPasswordCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_forgot_password/get_token`,
      body: forgotEmail,
    });
  };

  forgotPasswordAPISuccessCallBack = async (res: any) => {
    console.log("@@@ Forgot Password Successs CallBack =============", res);
    this.setState({ isFetching: false, email:'' }, async () => {
      const email = res.data.attributes.email;
      const token = res.meta.token;
      const getLink = await this.generateLink(email, token);
      this.sendDeepLinkOnEmail(email, getLink);
      console.log("@@@ Generated Deep Link ==========>", getLink);
    });
    // this.resetStateInfo()
  };

  forgotPasswordAPIFailureCallBack = (error: any) => {
    console.log("@@@ Forgot Password Failure CallBack =============", error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    });
  };

  generateLink = async (email: any, token: any) => {
    try {
      var link = await dynamicLinks().buildShortLink(
        {
          link: `https://seedcartecommerce.page.link/H3Ed?id=${email.trim()}&token=${token}&title=ForgotPassword`,
          domainUriPrefix: "https://seedcartecommerce.page.link",
          android: {
            packageName: "com.SeedCartECommerce",
            minimumVersion: "18",
          },
          // ios: {
          //     appStoreId: '123456789',
          //     bundleId: 'com.SeedCartECommerce',
          //     minimumVersion: '18'
          // },
        },
        dynamicLinks.ShortLinkType.DEFAULT
      );
      return link;
    } catch (error) {
      console.log("error raised", error);
    }
  };

  sendDeepLinkOnEmail = async (email: any, link: any) => {
    let deepLinkData = {
      data: {
        attributes: {
          email: email.toLocaleLowerCase(),
          url: link
        },
      },
    };
    this.apiSendDeepLinkCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_forgot_password/forgot_passwords`,
      body: deepLinkData,
    });
  };

  sendDeepLinkAPISuccessCallBack = async (res: any) => {
    console.log("@@@ Send Deep Link Successs CallBack =============", res);
  };

  sendDeepLinkAPIFailureCallBack = (error: any) => {
    console.log("@@@ Send Deep Link Failure CallBack =============", error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    });
  };
  //Customizable Area End
}
