import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ImagePicker from "react-native-image-crop-picker";
// Customizable Area Start
//import DocPicker from "react-native-document-picker";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFetching: boolean;
  companyName: string;
  GSTNumber: string;
  companyLicenseData: any;
  companyLicenseImagePath:any;
  isInvalidCompanyLicense: boolean;
  isInvalidCompanyName: boolean;
  isInvalidGSTNumber: boolean;
  compLicenseFileType: string;
  compLicenseFileUrl : string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomForm2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  toastRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);  
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      companyName: "",
      GSTNumber: "",
      companyLicenseData: null,
      companyLicenseImagePath: null,
      isInvalidCompanyLicense: false,
      isInvalidCompanyName: false,
      isInvalidGSTNumber: false,
      isFetching: false,
      compLicenseFileType:'',
      compLicenseFileUrl: ''
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    console.log("@@@ Params on company details screen =========>", this.props.route.params)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    // super.componentWillUnmount();
    // this._unsubscribe.remove();
    // Customizable Area Start
    // Customizable Area Start 
  }

  // Customizable Area End

  // handleSelect = () => {
  //   DocPicker.pickSingle({
  //     type: [DocPicker.types.pdf, DocPicker.types.images]
  //   }).then(doc => {
  //     if(doc.type?.includes?.("pdf")){
  //       this.setState({ compLicenseFileType: "pdf", compLicenseFileUrl: doc.uri });
  //     }
  //     else if(doc.type?.includes?.("image")){
  //       this.setState({ compLicenseFileType: "img", compLicenseFileUrl: doc.uri });
  //     }
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          // this.getLogInSuccesscallBack(responseJson);
        } 
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          // this.getLogInFailureCallBack(responseJson);
        } 
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse)
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = this.props.route.params.token || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //============================== Upload Company KYC ============================

  onPressPickCompanyLicense = () => {
    try {
      ImagePicker.openPicker({
        mediaType: 'any',
        cropping: true,
        includeBase64: true,
        compressImageQuality: 0.3,
      }).then(async image  => {
        console.log("@@@ Uploaded PAN Card From Galary =============>", image);
        this.setState({ companyLicenseData: image, companyLicenseImagePath: image.path, isInvalidCompanyLicense: false });
      });
    } catch (error) {
      console.log('@@@ Error opening image from Galary ==========', error);
    }
  };

  //============================== Company Detail API call =========================

  onPressNextButton = () => {
    if (this.state.companyName.trim().length === 0) {
      this.setState({ isInvalidCompanyName: true })
      return;
    }
    if (this.state.GSTNumber.trim().length === 0) {
      this.setState({ isInvalidGSTNumber: true })
      return;
    }
    if (!this.state.companyLicenseImagePath || !this.state.companyLicenseImagePath) {
      this.setState({ isInvalidCompanyLicense: true })
      return;
    }
    if(this.state.isInvalidGSTNumber)
    {
      return;
    }
    this.props.navigation.navigate('KycVerification8', {
      companyName: this.state.companyName,
      GSTNumber: this.state.GSTNumber,
      CompanyLicense: this.state.companyLicenseData.data,
      token: this.props.route.params.token
    })
  }

}
