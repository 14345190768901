import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  FlatList,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import * as IMG_CONST from "./assets";
import { Dropdown } from "react-native-material-dropdown";
import styles from "./OrderSummaryStyle";
import scale, { verticalScale } from "../../../framework/src/Scale";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import OrderSummaryController, {
  Props,
  configJSON,
} from "./OrderSummaryController";
import { SafeAreaView } from "react-native-safe-area-context";
// import { Typography, RNInput,dimensions, AppLoader } from "../../../components/src";
import RNInput from "../../../components/src/RNInput";
import Typography from "../../../components/src/Typography";
import { Colors, dimensions } from "../../../components/src/constants";
import { AppLoader } from "../../../components/src/Index";
import icons from "../../../components/src/images";
import { DOWN_ARROW } from '../../../blocks/LandingPage2/src/assets';
import moment from "moment";
import Toast from "react-native-easy-toast";
import { translate } from "framework/src/Translation";

export default class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  toggleTabsButtons = (index: any) => {
    if (index === 0) {
      this.setState({
        all: true,
        newOrders: false,
  processing: false,
  shipped: false,
  completed: false,
  onHold: false,
  failed: false,
  currentTab: "",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    } else if (index === 1) {
      this.setState({
        all: false,
        newOrders: true,
  processing: false,
  shipped: false,
  completed: false,
  onHold: false,
  failed: false,
  currentTab:"new",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("new",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    } else if (index === 2) {
      this.setState({
        all: false,
        newOrders: false,
  processing: true,
  shipped: false,
  completed: false,
  onHold: false,
  failed: false,
  currentTab:"processing",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("processing",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    } else if (index === 3) {
      this.setState({
        all: false,
        newOrders: false,
  processing: false,
  shipped: true,
  completed: false,
  onHold: false,
  failed: false,
  currentTab:"shipped",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("shipped",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    } else if (index === 4) {
      this.setState({
        all: false,
        newOrders: false,
  processing: false,
  shipped: false,
  completed: true,
  onHold: false,
  failed: false,
  currentTab:"completed",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("completed",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    } else if(index===5) {
      this.setState({
        all: false,
        newOrders: false,
  processing: false,
  shipped: false,
  completed: false,
  onHold: true,
  failed: false,
  currentTab:"on_hold",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("on_hold",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    }
    else if(index===6) {
      this.setState({
        all: false,
        newOrders: false,
  processing: false,
  shipped: false,
  completed: false,
  onHold: false,
  failed: true,
  currentTab:"failed",
  pageNo:1,
  OrderDetails:[],
  searchTerm:'',
  totalOrders:0
      },()=>{
        this.getOrdersData("failed",this.state.searchTerm ? this.state.searchTerm :"",1,this.state.pageCount);
      });
    }
  };

  renderToggleContainer = () => {
    const {
      all, newOrders,
  processing,
  shipped,
  completed,
  onHold,
  failed
    } = this.state;
    return (
      <View style={{ backgroundColor: "#fff", marginTop: verticalScale(15) }}>
        <ScrollView horizontal style={styles.buttonView} contentContainerStyle={{alignItems: "center"}}>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(0);
            }}
          >
            <Typography size={16} style={[all ? styles.AboutText : styles.ServicesText]}>
              {translate("all_orders")}
            </Typography>
            <View
              style={[all ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(1);
            }}
          >
            <Typography size={16} style={[newOrders ? styles.AboutText : styles.ServicesText]}>
              {translate("new")}
            </Typography>
            <View
              style={[newOrders ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(2);
            }}
          >
            <Typography size={16}
              style={[processing ? styles.AboutText : styles.ServicesText]}
            >
              {translate("processing")}
            </Typography>
            <View
              style={[processing ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(3);
            }}
          >
            <Typography size={16} style={[shipped ? styles.AboutText : styles.ServicesText]}>
              {translate("shipped")}
            </Typography>
            <View style={[shipped ? styles.editLine : styles.editBottomLine]} />
          </TouchableOpacity>

          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(4);
            }}
          >
            <Typography size={16} style={[completed ? styles.AboutText : styles.ServicesText]}>
              {translate("completed")}
            </Typography>
            <View
              style={[completed ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(5);
            }}
          >
            <Typography size={16} style={[onHold ? styles.AboutText : styles.ServicesText]}>
              {translate("on_hold")}
            </Typography>
            <View
              style={[onHold ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => {
              this.toggleTabsButtons(6);
            }}
          >
            <Typography size={16} style={[failed ? styles.AboutText : styles.ServicesText]}>
              {translate("failed")}
            </Typography>
            <View
              style={[failed ? styles.editLine : styles.editBottomLine]}
            />
          </TouchableOpacity>
          <View style={{alignItems: "center"}} >
          <Typography size={16} style={styles.ServicesText}>
            </Typography>
            <View
              style={[styles.editBottomLine]}
            />
          </View>
        </ScrollView>
      </View>
    );
  };
  renderEmptyComponent = () => {
    if(this.state.loading) return null;
    return(
      <View style={styles.empty}>
        <Typography color="greyText">{translate("no_orders_found")}</Typography>
      </View>
    )
  }
  renderAllComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }
  renderNewComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }
  renderProcessingComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }
  renderShippedComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8),marginBottom:scale(120)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          
          </View>
    );
  }
  renderCompletedComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }
  renderOnHoldComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }
  renderFailedComponent = () => {
    if(this.state.loading) return null;
    const { OrderDetails, totalOrders } = this.state;
    return (
      <View style={{marginHorizontal:scale(15),marginTop:scale(15),paddingTop:scale(8)}}>
        <Typography size={18}
          style={{
            color: "gray",
            marginTop: scale(5),
          }}
        >
         {totalOrders+ " "+translate("orders")}
        </Typography>
        <FlatList
            data={OrderDetails}
            renderItem={this.OrderDetails}
            onEndReached={this.loadMoreData}
            onEndReachedThreshold ={0.1}
            ListEmptyComponent={this.renderEmptyComponent}
            ListFooterComponent={() => OrderDetails.length>=3 ? <View style={{height:scale(300)}} ></View> : <View style={{height:scale(150)}} ></View>}
          />
          </View>
    );
  }

  OrderDetails = ({item,index}: {item: any, index: any}) => {
    const dt = moment(item?.attributes?.placed_at, "YYYY-MM-DD HH:mm:ss")
    return (
      <View style={{ marginTop: verticalScale(15) }}>
        <TouchableOpacity 
        onPress={()=> this.props.navigation.navigate("MOrderDetails", { id: item.id })} 
        >
            <Typography color={"black"} >{translate("orderid_caps")+ " "+item?.attributes?.order_number}
            </Typography>
            <Typography color={"greyText"} >{ dt.format('dddd')+ " "+dt.format("h:mm A")+"("+dt.format("DD-MM-YYYY")+")"}</Typography>
            {(item.attributes?.order_items && item.attributes?.order_items?.length>0)  ? 
            item.attributes?.order_items.map((itm:any)=>(
              <Typography color={"black"} >{itm.attributes?.catalogue.attributes?.name+" X "+itm.attributes?.quantity }</Typography>
            )) : null}
            <Typography color={"black"} >{ translate("total_amount")+" : ₹ "+ item.attributes?.total}</Typography>
        </TouchableOpacity>
        <View
          style={{
            width: scale(350),
            height: scale(1),
            borderWidth: 0.3,
            borderColor: COLOR_CONST.lightgreycolor,
            alignSelf: "center",
            marginTop: verticalScale(15),
          }}
        />
      </View>
    );
  };
  loadMoreData=()=>{
    const lastpageNo = this.state.totalOrders/this.state.pageCount;
    console.log("last pageno>>",Math.ceil(lastpageNo));
    if(this.state.pageNo< Math.ceil(lastpageNo))
    {
    this.setState({pageNo:this.state.pageNo+1},()=>{
      this.getOrdersData(this.state.currentTab, this.state.searchTerm,this.state.pageNo,this.state.pageCount);
    });
  }
    
  }

  render() {
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.container}>
        <Typography style={styles.orderText}>{translate("orders")}</Typography>
        {this.renderToggleContainer()}
        <RNInput
            value={this.state.searchTerm}
            onChange={(text:string)=>{this.setState({searchTerm:text},()=>{
              this.state.searchTerm.length >= 3 ? this.getOrdersData(this.state.currentTab, this.state.searchTerm,this.state.pageNo,this.state.pageCount) : null;
            })}}
            placeholder={translate("search_orders")}
            icon={icons.search}
            pressAction={()=>{
              this.state.searchTerm.length >= 3 ? this.getOrdersData(this.state.currentTab, this.state.searchTerm,this.state.pageNo,this.state.pageCount) : null;
            }}
            containerStyle={[styles.input,styles.card, styles.elevation]}
          />
        
        {this.state.all && this.renderAllComponent()}
        {this.state.newOrders && this.renderNewComponent()}
        {this.state.processing && this.renderProcessingComponent()}
        {this.state.shipped && this.renderShippedComponent()}
        {this.state.completed && this.renderCompletedComponent()}
        {this.state.onHold && this.renderOnHoldComponent()}
        {this.state.failed && this.renderFailedComponent()}
          <AppLoader isFetching={this.state.loading} {...this.props} />
        <Toast
          ref={(toast) => (this.toastRef = toast)}
          position={"bottom"}
          positionValue={150}
          textStyle={{ fontwidth: "bold", color: "#ffffff" }}
          fadeOutDuration={1000}
        />
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
