// Customizable Area Start
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import "../assets/LogOutModalWeb.scss";
import Divider from '@material-ui/core/Divider';
//@ts-ignore
import Cookies from 'js-cookie';
import {logOut} from "./assets"
import LogOutModalWebController, { Props } from "./LogOutModalWebController.web";
import { translate } from "framework/src/Translation";
export default class LogOutModalWeb extends LogOutModalWebController {
  constructor(props: Props) {
    super(props);
  }
    
  render() {
    return (
      <Modal
      //@ts-ignore
      open={this.props?.openLogoutModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
     
    >
      <Box className="logout-box-modal">
      <div className="div-modal">
      <img src={logOut}/>
      </div>
       <h4 className="div-modal-h4">{translate("logout")}</h4>
        <Typography >
          <p  className="p-tag-modal">{translate("are_you_sure")}</p>
        </Typography>
        <div className="logout-divs">
        <Button variant="contained" className="modal-cancel-btn" onClick={()=>{
          //@ts-ignore
        this.props?.closeLogoutModal()
       }} >
              {translate("cancel")}
        </Button>
        
        {
        //@ts-ignore
        this.state.progress?<Button variant="contained" className="modal-bttn" disabled={this.state.progress}
               >
                <CircularProgress style={{color:'#FFFFFF'}}/>
              </Button>:(
              <Button
                variant="contained" className="modal-bttn" onClick={() => {
                  this.handleLogout()
                }} >
                {translate("logout")}
              </Button>
             )}
        </div>
      </Box>
    </Modal>
    );
  }
}
// Customizable Area End