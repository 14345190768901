import React, { useState } from 'react';
import { TextInput, StyleSheet, View, Image, KeyboardTypeOptions, StyleProp, ViewStyle, ImageProps, TouchableOpacity } from 'react-native';
import { normalize } from 'react-native-elements';

import { error } from "./assets";
import { Colors, dimensions, fonts } from "./constants";
import Typography from "./Typography";

type Props = {
  placeholder?: string;
  label?: string;
  value: string;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  disabled?: boolean;
  autoCorrect?: boolean;
  keyboardType?: KeyboardTypeOptions;
  autoCapitalize?: any;
  secureEntry?: boolean;
  errorText?: any;
  touched?: any;
  maxLength?: number;
  multiline?: boolean;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  icon?: ImageProps["source"];
  backIcon?: ImageProps["source"];
  pressAction?: () => void;
}

const RNInput:React.FC<Props> = ({
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
  autoCorrect,
  keyboardType,
  autoCapitalize,
  secureEntry,
  errorText,
  touched,
  maxLength,
  multiline,
  style,
  icon,
  backIcon,
  pressAction,
  containerStyle
}) => {

  const [focused, setFocused] = useState<boolean>(false);

  const showError = (errorText && touched);

  const inputMargin = { marginBottom: showError? dimensions.hp(0.5): dimensions.hp(1) };

  const updateFocus = () => {
    setFocused(f => !f);
  }

  return(
    <>
      {label && (
        <Typography
          color="black"
          style={styles.label}
        >{label}</Typography>
      )}
      <View style={[styles.inputContainer, inputMargin, containerStyle]}>
        { icon && (
          <TouchableOpacity onPress={pressAction}>
            <Image source={icon} style={styles.icon} />
          </TouchableOpacity>
        )}
        <TextInput
          placeholder={placeholder}
          placeholderTextColor={Colors.greyText}
          value={value}
          onChangeText={(text) => onChange?.(text)}
          onBlur={e => {
            onBlur?.(e);
            updateFocus();
          }}
          blurOnSubmit={false} 
          autoCorrect={autoCorrect ?? true}
          secureTextEntry={!!secureEntry}
          keyboardType={keyboardType || 'default'}
          autoCapitalize={autoCapitalize || 'none'}
          textContentType="oneTimeCode"
          maxLength={maxLength}
          multiline={multiline || false}
          editable={disabled? false: true}
          style={[styles.input, style]}
          onFocus={() => {
            updateFocus();
            onFocus?.();
          }}
        />
        {backIcon && (
          <Image source={backIcon} style={styles.backIcon} />
        )}
      </View>
      {showError && (
        <View style={styles.errorContainer}>
          <Image source={error} style={styles.errorLogo} />
          <Typography color="red" size={11}>{errorText}</Typography>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    width: '100%',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: `${Colors.greyText}88`,
    borderRadius: normalize(7),
    overflow: "hidden",
    paddingHorizontal: dimensions.wp(3),
    alignItems: "center",
    height: normalize(45),
  },
  input: {
    color: Colors.black,
    fontFamily: fonts.REG,
    height: "100%",
    fontSize: 15,
    flex: 1,
    textAlignVertical: 'top',
    
  },
  label: {
    marginBottom: dimensions.hp(1.25)
  },
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: dimensions.hp(2),
  },
  errorLogo: {
    width: normalize(12),
    height: normalize(12),
    resizeMode: "contain",
    marginRight: normalize(5)
  },
  icon: {
    height: normalize(18),
    width: normalize(18),
    tintColor: Colors.greyText,
    marginRight: normalize(5),
    resizeMode: "contain"
  },
  backIcon: {
    height: normalize(12),
    width: normalize(12),
    tintColor: Colors.greyText,
    marginRight: normalize(5),
    resizeMode: "contain"
  }
});

export default RNInput;