import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
  TextInput,
} from "react-native";

import * as IMG_CONST from "./assets";
import styles from "./ForgotPasswordStyle";
import LinearGradient from "react-native-linear-gradient";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import FocusAwareStatusBar from "../../../components/src/FocusAwareStatusBar";
import Toast, { DURATION } from "react-native-easy-toast";
import ApplicationLoader from "../../../components/src/AppLoader/src/AppLoader";
import { translate } from "../../../framework/src/Translation";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  renderHeader = () => {
    return (
      <View>
        <View style={styles.signupTextContainer}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.backArrowButton}
            onPress={() => this.props.navigation.goBack()}
          >
            <Image style={styles.backIcon} source={IMG_CONST.BACK_ARROW} />
          </TouchableOpacity>
          <Text style={styles.signupText}>{translate("forgot_password")}</Text>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  renderEmailTextInput = () => {
    return (
      <View style={styles.emailInputContainer}>
        <Text style={styles.enterEmailText}>
          {translate("enter_your_registered_email")}
        </Text>
        <Text style={styles.emailText}>{translate("email_id")}</Text>
        <View style={styles.emailField}>
          <TextInput
            style={styles.inputEmailStyle}
            placeholder={translate("email_id")}
            placeholderTextColor="#848484"
            value={this.state.email}
            onChangeText={(text) =>{
              let emailreg =/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
              if(emailreg.test(text))
              {
                this.setState({ isInvalidEmail: false, email: text }, () => {});
              }
              else{
                this.setState({ isInvalidEmail: true, email: text }, () => {});
              }
            }
            }
          />
        </View>
        {this.state.isInvalidEmail && (
          <View style={{ alignSelf: "flex-start" }}>
            <Text style={styles.errorText}>{this.state.email.length===0 ? translate("please_enter_email_id") : translate("enter_your_valid_email_address")}</Text>
          </View>
        )}
      </View>
    );
  };

  renderSubmitButton = () => {
    return (
      <TouchableOpacity
        // onPress={() => this.props.navigation.navigate("NewPassword")}
        onPress={() => this.onPressSubmitButton()}
        style={styles.loginButtonContainer}
      >
        <LinearGradient
          start={{ x: 0.2, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={styles.loginView}
        >
          <Text style={styles.loginText}>{translate("submit")}</Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  };
  //Customizable Area End

  render() {
    //Customizable Area Start
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"white"}
          isFocused={true}
        />
        {this.renderHeader()}
        {this.renderEmailTextInput()}
        {this.renderSubmitButton()}
        <ApplicationLoader isFetching={this.state.isFetching} {...this.props} />
        <Toast
          ref={(toast) => (this.toastRef = toast)}
          position={"bottom"}
          positionValue={150}
          textStyle={{ fontwidth: "bold", color: "#ffffff" }}
          fadeOutDuration={1000}
        />
      </View>
      //Customizable Area End
    );
  }
}

//Customizable Area Start
//Customizable Area End
