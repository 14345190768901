// Customizable Area Start
import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../src/SideDrawerForDetailWeb.scss'
//@ts-ignore
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ProductDialog from "../../blocks/ProductQuickview/src/ProductDialog.web";
import { translate as t } from "framework/src/Translation";
import { useAppState, useRefresh } from "./context/AppContext";
//export const configJSON = require("./config");
const configURL = require('../../framework/src/config')
const closeIcon = require("../src/close.png").default
toast.configure();
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SideDrawerForDetailWeb(props: any) {
    const [orderDataStatus, setorderDataStatus] = useState<any>('');
    const [loading, setloading] = useState<boolean>(false);
    const date = new Date(props?.orderClickedData?.attributes?.order_date);
    const orderDate = moment(date).format('DD-MMMM-YYYY')
    const address = props?.orderClickedData?.attributes?.delivery_addresses[0];
    const { language } = useAppState();
    const refresh = useRefresh();

    useEffect(() => {
        refresh();
      }, [language]);

    const list = (anchor: Anchor) => (
        <>
            {(props?.openDrawer && !props?.sideDrawerLoading) ?
                (
                    <div className='list-div'>
                        {/* order details */}
                        <div className="side-header">
                            <List>
                                <ListItem >
                                    <ListItemText primary={'Order Details'} className='title-line' />
                                </ListItem>
                            </List>
                            <div className='div-close'>
                                <img src={closeIcon} alt="" className='img-close' onClick={() => {
                                    props?.closeDrawer()
                                }} />
                            </div>
                        </div>
                        <div className='order-detail-div'>
                            <div style={{ display: 'flex' }}>
                                <h4 className='title-list'>{t("order_id")}</h4>
                                <div className='detail-list'>
                                    <h4>{props?.orderClickedData?.attributes?.order_number}</h4>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <h4 className='title-list'>{t("order_date")}</h4>
                                <div className='detail-list'>
                                    <h4>{orderDate}</h4>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <h4 className='title-list'>{t("order_total")}</h4>
                                <div className='detail-list'>
                                    <h4>{props?.orderClickedData?.attributes?.total || '0.0'}</h4>
                                </div>
                            </div>
                        </div>
                        {/* Items */}
                        <div>
                            <h2 className='item-title'>{t("items")} ({props?.orderClickedData?.attributes?.order_items?.length})</h2>
                            <div className='item-Delivery'>
                                <Typography className='item-title-h4'>Estimated Delivery Date:July 25, 2021</Typography>
                                {props?.orderClickedData?.attributes?.order_items?.map((orderItem: any, index: number) => {
                                    return (
                                        <>
                                            <div className='div-item'>
                                                <img src={orderItem?.attributes?.catalogue?.attributes?.images[0].url} alt="" style={{
                                                    width: '70px',
                                                    height: '70px',
                                                    borderRadius: '8px'
                                                }} />
                                                <div className='detail-item-div'>
                                                    <h4>{orderItem?.attributes?.catalogue?.attributes?.name}</h4>
                                                    <h3 className='qty-line'>{t("quantity")} : {orderItem?.attributes?.quantity}</h3>
                                                </div>
                                                <h4 className='value-item'>&#8377; {orderItem?.attributes?.total_price}</h4>
                                            </div>
                                            {props?.orderClickedData?.attributes?.order_items?.length !== index + 1 && <hr className='hr-line' />}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        {/* shipping info */}
                        <div>
                            <h2 className='item-title-shipping'>Shipping Address</h2>
                            <div className='item-shipping'>
                                <h4 className='item-shipping-h4'>{`${address?.address}, ${ address?.address_line_2}, ${address?.landmark }, ${address?.zip_code}, ${address?.city}, ${address?.state}.`  || 'Address Not Available'}</h4>
                            </div>
                        </div>
                        {/* payments info */}
                        <div>
                            <h2 className='item-title-shipping'>Payment Information</h2>
                            <div className='item-payment'>
                                <h2 className='item-title-payment'>Payment Method</h2>
                                <h4 className='item-payment-h4'>Credit / Debit Card</h4>
                                <hr className='hr-line' />

                                <h2 className='item-title-payment'>Billing Address</h2>

                                <h4 className='item-payment-h4'>
                                {`${address?.address}, ${ address?.address_line_2}, ${address?.landmark }, ${address?.zip_code}, ${address?.city}, ${address?.state}.`  || 'Address Not Available'}
                                    {/* Gr Flr, 98/2, Neelkanth, new villa park road
                                    Below Flyover, New Marine Lines, St thomas school
                                    Mumbai - 400002,
                                    Maharashtra, India */}
                                    </h4>
                            </div>
                        </div>
                        {/* Order summary */}
                        <div>
                            <h2 className='item-title-shipping'>Order Summary</h2>
                            <div className='order-detail-pay-div'>
                                <div style={{ display: 'flex' }}>
                                    <h4 className='title-list-pay'>Items</h4>
                                    <div className='detail-list'>
                                        <h4>&#8377; {props?.orderClickedData?.attributes?.total || '0.0'}</h4>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h4 className='title-list-pay'>Shipping charges</h4>
                                    <div className='detail-list'>
                                        <h4>&#8377; {props?.orderClickedData?.attributes?.shipping_total || '0.0'}</h4>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h4 className='title-list-pay'>Tax</h4>
                                    <div className='detail-list'>
                                        <h4>&#8377; {props?.orderClickedData?.attributes?.total_tax || '0.0'}</h4>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h4 className='title-list-pay'>Total</h4>
                                    <div className='detail-list'>
                                        <h4>&#8377; {props?.orderClickedData?.attributes?.total || '0.0'}</h4>
                                    </div>
                                </div>
                                <hr className='hr-line' />
                                <div style={{ display: 'flex' }}>
                                    <h4 className='title-list-pay-last'>Order Total</h4>
                                    <div className='detail-list-total'>
                                        <h4>&#8377; {props?.orderClickedData?.attributes?.total || '0.0'}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                //@ts-ignore
                                className={props?.orderClickedData?.attributes?.status === 'new' && 'status-New-Change' || props?.orderClickedData?.attributes?.status === 'processing' && 'status-Processing-Change' || props?.orderClickedData?.attributes?.status === 'shipped' && 'status-Shipped-Change' || props?.orderClickedData?.attributes?.status === 'completed' && 'status-Completed-Change' || props?.orderClickedData?.attributes?.status === 'on_hold' && 'status-OnHold-Change' || props?.orderClickedData?.attributes?.status === 'failed' && 'status-Failed-Change'}     >
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={
                                            props?.orderClickedData?.attributes?.status === 'new' && 'new' || props?.orderClickedData?.attributes?.status === 'processing' && 'processing' || props?.orderClickedData?.attributes?.status === 'shipped' && 'shipped' || props?.orderClickedData?.attributes?.status === 'completed' && 'completed' || props?.orderClickedData?.attributes?.status === 'on_hold' && 'on_hold' || props?.orderClickedData?.attributes?.status === 'failed' && 'failed'
                                        }
                                        //@ts-ignore
                                        className={props?.orderClickedData?.attributes?.status === 'new' && 'new-status new-status-drop drop-Down' || props?.orderClickedData?.attributes?.status === 'processing' && 'processing-status processing-status-drop drop-Down' || props?.orderClickedData?.attributes?.status === 'shipped' && 'shipped-status shipped-status-drop drop-Down' || props?.orderClickedData?.attributes?.status === 'completed' && 'completed-status completed-status-drop drop-Down' || props?.orderClickedData?.attributes?.status === 'on_hold' && 'on_hold-status on_hold-status-drop drop-Down' || props?.orderClickedData?.attributes?.status === 'failed' && 'failed-status failed-status-drop drop-Down'}
                                        disableUnderline
                                        onChange={(e: any) => {
                                            if (!props?.currentStatus) {
                                                props?.changeStatus(e.target.value, props?.orderClickedData?.id, props?.orderClickedData?.attributes?.order_number, null, props?.orderClickedData?.attributes?.status, "getdata")
                                            } else {
                                                props?.changeStatus(e.target.value, props?.orderClickedData?.id, props?.orderClickedData?.attributes?.order_number, null, props?.orderClickedData?.attributes?.status)
                                            }
                                            props?.closeDrawer()
                                        }}
                                    >
                                        <MenuItem value={'new'}>New</MenuItem>
                                        <MenuItem value={'processing'} >Processing</MenuItem>
                                        <MenuItem value={'shipped'} >Shipped</MenuItem>
                                        <MenuItem value={'completed'}>Completed</MenuItem>
                                        <MenuItem value={'on_hold'} >On_Hold</MenuItem>
                                        {/* <MenuItem value={'failed'} >Failed</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                ) : ((props?.openDrawer && props?.sideDrawerLoading) &&
                    <CircularProgress style={{ margin: 'auto' }} />
                )}
            {(props.addProd || props.editProd || props.showDetails) &&
                <ProductDialog
                    rowInfo={props.rowInfo}
                    addProd={props.addProd}
                    setAddProd={props.setAddProd}
                    showDetails={props.showDetails}
                    setShowDetails={props.setShowDetails}
                    editProd={props.editProd}
                    setEditProd={props.setEditProd}
                    updateProdList={props.updateProdList}
                    setUpdateProdList={props.setUpdateProdList}
                    allCategories={props.allCategories}
                    setAllCategories={props.setAllCategories}
                />
            }</>
    );

    return (
        <div>
            <React.Fragment >
                <div className='main-div'>
                    <Drawer
                        anchor={'right'}
                        open={props?.openDrawer || props.addProd || props.showDetails || props.editProd}
                        // onBackdropClick={() => props?.closeDrawer()}
                    >
                        {list('right')}
                    </Drawer>
                </div>
            </React.Fragment>
        </div>
    );
}
// Customizable Area End