import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  FlatList
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import * as IMG_CONST from "./assets";
import scale, { verticalScale } from "../../../framework/src/Scale";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import ProductQuickviewController, {
  Props,
  configJSON
} from "./ProductQuickviewController";

export default class ProductQuickview extends ProductQuickviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  renderHeader = () => {
    return (
      <View>
        <View style={styles.signupTextContainer}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.backArrowButton}
            onPress={() => this.props.navigation.goBack()}
          >
            <Image style={styles.backIcon} source={IMG_CONST.BACK_ARROW} />
          </TouchableOpacity>
          <Text style={styles.signupText}>Add New Product</Text>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  productView = () => {
    return (
      <View style={{ marginTop: verticalScale(10) }}>
        <View style={{ marginLeft: scale(15) }}>
          <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>Product Category</Text>
          <View style={{ width: scale(340), height: scale(50), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}>Product Category</Text>
            <TouchableOpacity style={{ width: 20, height: 20, backgroundColor: "red", marginRight: scale(15) }}></TouchableOpacity>
          </View>
        </View>
        <View style={{ marginLeft: scale(15), }}>
          <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Product Name</Text>
          <View style={{ width: scale(340), height: scale(50), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <TextInput placeholder="Product Name" style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}/>
          </View>
        </View>
      </View>
    )
  }

  uploadMediaView = () => {
    return (
      <View style={{ marginLeft: scale(15) }}>
        <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Upload Media</Text>
        <View style={{
          width: scale(100),
          height: scale(100),
          marginTop: verticalScale(10),
          borderColor: '#de9b0b',
          borderWidth: 3,
          justifyContent: 'center',
          alignItems: "center",
          borderStyle: 'dotted',
        }}>
          <Image source={IMG_CONST.UPLOAD} style={{ width: scale(20), height: scale(30), marginBottom: scale(5) }} />
          <Text style={{ color: "#de9b0b", fontSize: scale(15), fontFamily: FONTS.PoppinsDisplaySemiBold, marginTop: scale(5) }}>UPLOAD</Text>
        </View>
      </View>
    )
  }

  descriptionView = () => {
    return (
      <View style={{ marginLeft: scale(15), marginBottom: scale(100) }}>
        <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Description</Text>
        <View style={{ width: scale(340), height: scale(130), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), }}>
          <TextInput placeholder="Description" style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15), marginTop: verticalScale(15) }} />
        </View>
      </View>
    )
  }

  renderSubmitButton = () => {
    return (
      <TouchableOpacity
        onPress={() => {}}
        style={styles.loginButtonContainer}
      >
        <LinearGradient
          start={{ x: 0.3, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={styles.loginView}
        >
          <Text style={styles.loginText}>ADD PRODUCT</Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  };


  quantityValueView = () => {
    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <View>
            <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Quantity</Text>
            <View style={{ width: scale(160), height: scale(50), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <Text style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}>{this.state.selectedWeight ? this.state.selectedWeight : "Quantity"}</Text>
              <TouchableOpacity onPress={() => this.setState({ productWeightPopup: true, })} style={{ width: 20, height: 20, backgroundColor: "red", marginRight: scale(15) }}></TouchableOpacity>
            </View>
          </View>
          <View>
            <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Value</Text>
            <View style={{ width: scale(160), height: scale(50), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <Text style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}>Value</Text>
            </View>
          </View>
        </View>
        <View style={{ marginLeft: scale(15) }}>
          <Text style={{ marginTop: verticalScale(10), fontFamily: FONTS.PoppinsDisplaySemiBold }}>Price</Text>
          <View style={{ width: scale(340), height: scale(50), marginTop: verticalScale(10), borderWidth: 1, borderColor: "#e8e6e6", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={{ color: "gray", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}>Price</Text>
          </View>
        </View>
        {this.state.productWeightPopup && this.renderMonthViewFlatList()}
        {this.uploadMediaView()}
      </View>
    )
  }

  weeklyMonthlyPopup = (item: any, index: any) => {
    return (
      <View style={{ backgroundColor: "#fff", }}>
        <TouchableOpacity style={{ marginTop: verticalScale(5), height: scale(40), marginLeft: scale(20), justifyContent: 'space-around' }} onPress={() => this.setState({ selectedWeight: item.Weight, productWeightPopup: false, })}>
          <Text style={{ fontFamily: FONTS.PoppinsDisplayRegular, fontSize: scale(15) }}>{item.Weight}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderMonthViewFlatList = () => {
    return (
      <View style={styles.renderMonthMainViewFlatList}>
        <FlatList
          data={this.state.productWeightFlatList}
          renderItem={({ item, index }) => this.weeklyMonthlyPopup(item, index)}
        />
      </View>
    )
  }

  render() {
    return (
      // Customizable Area Start
      <View style={styles.container}>
        {this.renderHeader()}
        <ScrollView>
          {this.productView()}
          {this.quantityValueView()}
          {this.descriptionView()}
        </ScrollView>
        {this.renderSubmitButton()}
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff"
  },

  signupTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: verticalScale(40),
    marginLeft: scale(20),
  },

  backArrowButton: {
    width: scale(30),
    height: scale(35),
  },

  backIcon: {
    width: scale(10),
    height: scale(20),
  },

  signupText: {
    fontSize: scale(20),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    marginBottom: verticalScale(15),
  },

  horizontalLine: {
    width: scale(375),
    height: scale(2),
    backgroundColor: COLOR_CONST.borderColor,
  },

  loginButtonContainer: {
    position: "absolute",
    bottom: verticalScale(20),
    alignSelf: 'center'
  },

  loginView: {
    width: scale(335),
    height: scale(55),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: scale(10)
  },

  loginText: {
    fontSize: scale(15),
    color: COLOR_CONST.whiteColor,
    fontFamily: FONTS.PoppinsDisplayMedium
  },

  renderMonthMainViewFlatList: {
    width: scale(160),
    left: scale(15),
    borderRadius: scale(10),
    borderWidth: 2,
    position: 'absolute',
    top: verticalScale(85),
    zIndex: 9999,
    overflow: 'hidden',
    // borderWidth: 0.2
  },
});
// Customizable Area End
