//Customizable Area Start
import React, { useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
//@ts-ignore
import 'react-table-v6/react-table.css'
import '../assets/ProductsWeb.scss'
import "../../../components/src/styles.scss";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const configURL = require('../../../framework/src/config')
//@ts-ignore
import Cookies from 'js-cookie'
import axios from "axios";
import ProductTable from "./ProductTable.web";
import { threeDots } from "./assets";
import useDebounce from "../../../components/src/UseDebounce";
import SideDrawerForDetailWeb from "../../../components/src/SideDrawerForDetailWeb.web";
import { translate as t } from "framework/src/Translation";
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';
const trash = require('../../../components/src/trash.jpg').default
export const configJSON = require("./config");
toast.configure()

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const ProductsWeb= (props:any) => {
  const {addProd, setAddProd, searchedValue, setSearchedValue} = props;
  const [value, setValue] = useState(0);
  const [loader,setLoader] = useState(false)
  const [data,setData] = useState([])
  const [showDetails,setShowDetails] = useState(false)
  const [editProd,setEditProd] = useState(false)
  const [showDetailsLoader,setShowDetailsLoader] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [rowData,setRowData] = useState<any>(null)
  const [allRowsData, setAllRowsData] = useState<any>()
  const [rowIndex,setRowIndex] = useState<any>()
  const [updateProdList, setUpdateProdList] = useState(false)
  const [allCategories, setAllCategories] = useState<any>(null);
  const [categoriesLoader, setCategoriesLoader] = useState(true);
  const [categoryId, setCategoryId] = useState<any>()
  const [totalEntries, setTotalEntries] = useState<any>(0)
  const [recordStartIndex,setRecordStartIndex] = useState(0)
  const [recordEndIndex, setRecordEndIndex] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal,setOpenModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)

  const { language } = useAppState();
  const refresh = useRefresh();

  const debouncedSearchedData = useDebounce(searchedValue, 500);
  let responseJSON = []
  const columns = [
    {
        Header: t("product"),
        accessor: 'name',
        minWidth:200,
        getProps: (state: any, rowInfo: any, column: any, instance: any) => {
            return {
                onClick: (e: any) => {						
                    showProductDetails(rowInfo.original);
                },
            };
        },
        style: { cursor: 'pointer' },
    },
    {
        Header: t("quantity"),
        accessor: 'quantity',
        style: { cursor: 'auto' },
    },
    {
        Header: t("value"),
        accessor: 'value',
        style: { cursor: 'auto' },
    },
    {
        Header: t("unit_price"),
        accessor: 'price',
        style: { cursor: 'auto' },
    },
    {
        Header: '',
        accessor: 'moreInfo',
        maxWidth:50,
        getProps: (state: any, rowInfo: any, column: any, instance: any) => {
            return {
                onClick: (e: any) => {	
                    setRowIndex(rowInfo.original.index)
                },
            };
        },
        Cell: (row:any) => (
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
                onClick={() => {
                    !showMenu ? setShowMenu(true): setShowMenu(false);
                }}
                >
                <img src={threeDots} alt="" width={30} style={{ cursor: "pointer" }} />
                {showMenu && row.original.index === rowIndex && (
                    <div
                    className={`moreInfoCon ${rowIndex===4 ? 'four' : rowIndex===5 ? 'five': 'others'}`}
                    >
                    <div className="overlay"></div>
                    <Typography style={{margin:"5px 5px", cursor:'pointer', padding:'5px'}}
                    //@ts-ignore
                     onClick={()=>{
                        setRowData(allRowsData[rowIndex])
                        setEditProd(true);

                    }}
                     >{t("edit_product")}</Typography>
                    <Typography style={{margin:"5px 5px", cursor:'pointer', padding:'5px'}}
                    //@ts-ignore
                    onClick={()=>{
                        setRowData(allRowsData[rowIndex])
                        setDeleteModal(true);
                        setOpenModal(true);
                        setShowMenu(false);

                    }}
                    >{t("delete_product")}</Typography>
                    <Typography style={{margin:"5px 5px", cursor:'pointer', padding:'5px'}} 
                    onClick={()=>{
                        setRowData(allRowsData[rowIndex])
                        setDeleteModal(false);
                        setOpenModal(true);
                        setShowMenu(false);

                    }}>{t("out_of_stock_product")}?</Typography>
                    </div>
                )}
            </div>
        ),
        style: { cursor: 'auto', },
    },
];
    const closeModal = ()=>{
        setOpenModal(false)
    }

  useEffect(()=>{
    requestProducts();
  },[
        updateProdList,
        value,
        currentPage,
        debouncedSearchedData
    ])

  useEffect(() => {
    axios.get(`${configURL.baseURL}/${configJSON.categoriesEndPoint}`,{headers:{
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "token": Cookies.get('Login_Token')
    }})
    .then(res=>{
        let categData:any = []
        res.data.data.map((data:any)=>(
            categData.push({id:data.id,label:data.attributes.name,value:data.attributes.name})
        ))
        setAllCategories(categData)
        setCategoriesLoader(false)
    })
    .catch((err:any) => {
        try {
          if(err?.response?.data?.errors[0]?.token){
            toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
          } else if(err?.message==='Request failed with status code 500'){
            toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
          }else if(err?.message==='Request failed with status code 400'){
            toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
          }
          setCategoriesLoader(false)
        } catch (error) {
          
        }
    })
}, [])

    useEffect(()=>{
        if(showDetailsLoader){
            const element:any = document.querySelector('body');
		    element.style.opacity = '0.5';
        }else{
            const element:any = document.querySelector('body');
		    element.style.opacity = '1';
        }
    },[showDetailsLoader])

    useEffect(() => {
        refresh();
      }, [language]);

    const handleDeleteProd = () => {
        setDeleteLoader(true)
        axios.delete(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowData.id}`,
        {headers:{
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "token": Cookies.get('Login_Token')
        }})
        .then(res=>{
            setDeleteLoader(false)
            setUpdateProdList(!updateProdList)
            setOpenModal(false)
            toast.success('Product Deleted Successfully!',{autoClose:2000})
        })
        .catch(err=>{
            setDeleteLoader(false)
            setOpenModal(false)
            toast.error(err.data.message)
        })
    }

    const outOfStockCall = () => {
        setDeleteLoader(true)
        axios.patch(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowData.id}`,{"catalogues": {
            "out_of_stock": true
        }},
            {headers:{
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": Cookies.get('Login_Token')
            }}
        )
        .then(res=>{
            setDeleteLoader(false)
            setOpenModal(false)
            toast.success(`'${rowData.attributes.name}' is now out of stock!`,{autoClose:3000})
        })
        .catch(err=>{
            toast.error(err.message);
            setDeleteLoader(false)
            setOpenModal(false)
        })
    };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
          //@ts-ignore
          sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      //@ts-ignore
    setCategoryId(event.target.parentNode.id)
    setCurrentPage(1)
    setValue(newValue);
  };

// Show Product Details
const showProductDetails =(rowData:any)=>{
    setShowDetailsLoader(true)
    axios.get(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowData.id}`,{
        headers:{
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "token": Cookies.get('Login_Token')
        }
    })
    .then(res=>{
        setRowData(res.data.data);
        setShowDetailsLoader(false)
        setShowDetails(true)
    })
    .catch(err=>{
        try {
            if(err?.response?.data?.errors[0]?.token){
              toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
            } else if(err?.message==='Request failed with status code 500'){
              toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
            }else if(err?.message==='Request failed with status code 400'){
              toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
            }
          } catch (error) {
            
          }
    })
}

// display products on table
const requestProducts=()=>{
    let prodCall = value===0 ? `${configURL.baseURL}/${configJSON.productApiEndPoint}?page=${currentPage}&per_page=6&search=${debouncedSearchedData}` : `${configURL.baseURL}/${configJSON.productApiEndPoint}?filter[category]=${categoryId}&page=${currentPage}&per_page=6&search=${debouncedSearchedData}`
    setLoader(true)
    axios.get(prodCall,{
        headers:{
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "token": Cookies.get('Login_Token')
        }
    })
    .then(res=>{
        responseJSON = res.data.data;
        let tableData:any = [];
        responseJSON.map((data:any,id:any)=>{
            let eachData = {
                index: id,
                id: data.id,
                name: <div style={{display:"flex",alignItems:"center"}}><img style={{width:"40px",height:"40px",borderRadius:"50%", marginRight:"10px",backgroundColor:"#ddd"}} src={data.attributes.images && data.attributes.images[0].url} />{data.attributes.name}</div>,
                quantity: data.attributes.quantity,
                value: data.attributes.value,
                price: '₹ ' + data.attributes.price,
            }
            return tableData.push(eachData);
        })
        setData(tableData);
        setTotalEntries(res.data.total_entries)
        setRecordStartIndex(res.data.start_from)
        setRecordEndIndex(res.data.end_to)
        setAllRowsData(responseJSON)
        setLoader(false)
    })
    .catch(err=>{
        try {
            if(err?.response?.data?.errors[0]?.token){
              toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
            } else if(err?.message==='Request failed with status code 500'){
              toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
            }else if(err?.message==='Request failed with status code 404'){
              toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
            }
          } catch (error) {
            
          }
        setLoader(false)
    })
}

return (
  <div>
      <Box 
    //   sx={{ width: '100%' }} 
      
      className="table-box">
      <Box sx={{ borderBottom: 10, borderColor: 'divider',marginRight:"30px",marginTop:"30px" }}>
        <Tabs value={value} 
        //@ts-ignore
        onChange={handleChange} aria-label="Categories">
            {allCategories && <Tab label={'All'} {...a11yProps(999)}/>}
            {allCategories?.map((tab:any)=>{
                return <Tab label={tab.value} {...a11yProps(tab.id)} />
            })}
        </Tabs>
      </Box>
      {!categoriesLoader && 
      <TabPanel value={value} index={value}>
        <ProductTable 
            data={data}
            loader={loader} 
            columns={columns}
            totalEntries={totalEntries}
            recordStartIndex={recordStartIndex}
            recordEndIndex={recordEndIndex}
            currentPage={currentPage}
            searchedValue={searchedValue}
            changePage={(e:any,page:any)=>{ setCurrentPage(page)}} />
      </TabPanel>
      }
    </Box>
        <SideDrawerForDetailWeb
            rowInfo={rowData}
            addProd={addProd}
            setAddProd={setAddProd}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            editProd={editProd}
            setEditProd={setEditProd}
            updateProdList={updateProdList}
            setUpdateProdList={setUpdateProdList}
            allCategories={allCategories}
            setAllCategories={setAllCategories}
        />
        {openModal && 
            //@ts-ignore
            <Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="change-delet-box-modal">
            <div className="div-modal">
            {deleteModal && <img src={trash} alt="" />}
            </div>
            <h4 className="div-modal-h4">{deleteModal ? t("delete_product") : t("oos")}</h4>
            <Typography >
                <p  className="p-tag-modal">{deleteModal ? `${t("are_you_sure_want_to_delete")} '${rowData.attributes.name}'?`: `${t("are_you_sure_you_want_to_flag")} '${rowData.attributes.name}' ${t("as_out_of_stock")}`}</p>
            </Typography>
            <div className="change-delet-box-div">
            <Button variant="contained" className="modal-cancel-btn" onClick={()=>{     closeModal()
            }} >
                {t("cancel")}
            </Button>&nbsp;
            <Button variant="contained" className="modal-delet-btn" onClick={()=>{
                deleteModal ? handleDeleteProd() : outOfStockCall()}}>
                    {deleteLoader ? <CircularProgress/> : deleteModal ? t("delete") : t("oos")}
                </Button>
            </div>
            </Box>
        </Modal>
 }
  </div>
  );
};
export default ProductsWeb;
//Customizable Area End
