import { StyleSheet, Platform } from 'react-native';
import scale, { verticalScale } from '../../../../framework/src/Scale';
import COLOR_CONST, { FONTS } from '../../../../framework/src/AppFonts';

export default StyleSheet.create({

    container: {
        width:"100%",
        height:"100%",
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        position: 'absolute',
        backgroundColor: 'transparent',
    },

});
