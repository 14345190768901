import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
//@ts-ignore
import Cookies from 'js-cookie';
import { Redirect } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  keepMeSign:string;
  disableLogin:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      keepMeSign:configJSON.keepMeLoggedIn,
      disableLogin:false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    
    // Customizable Area Start

    if(Cookies.get('Login_Token')){
        localStorage.getItem('user_type')==='buyer' ?
        //@ts-ignore
        this.props.history.push('/') : this.props.history.push('/dashboard');
    }

    // Customizable Area End
  }
  // Customizable Area Start
  doLogin(values: any) {
   // this.setState({ loginLoader: true })
    this.doEmailLogIn(values)
  }

  doEmailLogIn(values:any): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password,
      device_id: Cookies?.get('push_notification_token')
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({disableLogin:true})

    return true;
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJson && 
        (typeof responseJson.errors === 'undefined' || !responseJson.errors) && 
        (typeof responseJson.success === 'undefined' || responseJson.success)
      )  {
        console.log("printing response in emailLoginController", responseJson)
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiEmailLoginCallId &&
            responseJson !== undefined
          ) {
                var email = responseJson.data.attributes.email
                var phone_number = responseJson.data.attributes.full_phone_number
                var is_otp_verified = responseJson.data.attributes.is_otp_verified
                var is_document_uploaded = responseJson.data.attributes.is_document_uploaded
                var is_bank_details_filled = responseJson.data.attributes.is_bank_details_filled
                var activated = responseJson.data.attributes.activated
                let userType = responseJson.data.attributes.user_type
                Cookies.set('Login_Email', email)
                Cookies.set('Login_Account_Id', responseJson?.data?.id)
                if(is_otp_verified && userType==='buyer'){
                  localStorage.setItem('user_type','buyer')
                  Cookies.set('account_activated', 'yes');
                  Cookies.set('is_otp_verified', true);
                  Cookies.set('is_document_uploaded', true);
                  Cookies.set('is_bank_details_filled', true);
                  Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                  Cookies.set('Signup_Email', email)
                  Cookies.set('Signup_Phone', phone_number)
                  toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
                  this.setState({disableLogin:false})
                  //@ts-ignore
                  this?.props?.history?.push("/")
                }else if(!is_otp_verified){
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled);
                    Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                    Cookies.set('Signup_Email',  email)
                    Cookies.set('Signup_Phone', phone_number)
                    this.setState({disableLogin:false})
                    localStorage.setItem('user_type',userType)
                    //@ts-ignore
                    this?.props?.history?.push("/otp-verification")
                }
                else if((is_otp_verified && !is_document_uploaded) || (is_otp_verified && is_document_uploaded && !is_bank_details_filled)){
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled)
                    Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                    Cookies.set('Signup_Email', email)
                    Cookies.set('Signup_Phone', phone_number)
                    localStorage.setItem('user_type',userType)
                    this.setState({disableLogin:false})
                    //@ts-ignore
                    this?.props?.history?.push("/docsCompany")
                }
                else if(is_otp_verified && is_document_uploaded && !is_bank_details_filled){
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled)
                    Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                    Cookies.set('Signup_Email', email)
                    Cookies.set('Signup_Phone', phone_number)
                    localStorage.setItem('user_type',userType)
                    this.setState({disableLogin:false})
                    //@ts-ignore
                    this?.props?.history?.push("/docsCompany")
                }
                else if(is_otp_verified && is_document_uploaded && is_bank_details_filled && !activated)
                { 
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled);
                    Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                    Cookies.set('Signup_Email', email)
                    Cookies.set('Signup_Phone', phone_number)
                    localStorage.setItem('user_type',userType)
                    toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
                    this.setState({disableLogin:false})
                    //@ts-ignore
                    this?.props?.history?.push("/profileReview")
                }
                else if(is_otp_verified && is_document_uploaded && is_bank_details_filled && activated)
                { 
                    Cookies.set('account_activated', 'yes');
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled);
                    Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
                    Cookies.set('Signup_Email', email)
                    Cookies.set('Signup_Phone', phone_number)
                    localStorage.setItem('user_type','merchant')
                    toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
                    this.setState({disableLogin:false})
                    //@ts-ignore
                    this?.props?.history?.push("/dashboard")
                }
            } 
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors || responseJson ;
        console.log("printing login error",errors)
        this.setState({disableLogin:false})
        if (errors[0]?.failed_login ) {
          // this.setState({ loginLoader: false })
          // console.log("printing login error", errors[0])
          toast.error(errors[0]?.failed_login,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }else if(errors?.success === false){
          toast.error(errors?.message,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }

      //   if (errors[0]?.token === 'Invalid token' || errors[0]?.token==="Token has Expired" ) {
      //     Cookies.remove('Login_Token')
      //      //@ts-ignore
      //      this.props.history.push({
      //       pathname: '/login'
      //     })
      //     // this.setState({ loginLoader: false })
      //     toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      // } else
      // {
      //     toast.error(errors,{position: toast.POSITION.BOTTOM_RIGHT})
      // }


      }
    }
    // Customizable Area End
  }
}
