// src/packages/web/web-mocks/fbsdkMock.js

import React from 'react';

const FBShareButton = () => {
  console.log('FBShareButton rendered on the web (mock implementation)');
  return <button>Share on Facebook</button>;
};

const FacebookSDK = {
  FBShareButton,
};

const AccessToken = {
    // You can define other properties that you might need
    token: 'mock_access_token',
    userID: 'mock_user_id',
  };

 // Mock implementation of LoginManager
const LoginManager = {
    logOut: () => {
      console.log('User logged out (mock implementation)');
      // Simulate logout behavior
      return Promise.resolve(); // Simulate successful logout
    },
    setLoginBehavior: (behavior) => {
      console.log('LoginManager.setLoginBehavior called with behavior:', behavior);
      // Simulate setting the login behavior (just log it for now)
    },
    logInWithPermissions: async (permissions) => {
      console.log('LoginManager.logInWithPermissions called with permissions:', permissions);
      // Simulate successful login with a mock access token
      return new Promise((resolve) => {
        resolve({
          isCancelled: false,
          accessToken: AccessToken,
        });
      });
    },
  };

export { FacebookSDK, FBShareButton, AccessToken, LoginManager };
