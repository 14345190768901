// web-mocks/safeAreaContextMock.js

import React from 'react';

// Mock implementation of SafeAreaProvider
const SafeAreaProvider = ({ children }) => {
  console.log('SafeAreaProvider rendered (mock implementation)');
  return <div>{children}</div>; // Wrap children in a div
};

// Mock implementation of SafeAreaView
const SafeAreaView = ({ children }) => {
  console.log('SafeAreaView rendered (mock implementation)');
  return <div style={{ padding: '20px' }}>{children}</div>; // Add padding to simulate safe area
};

// Mock implementation of useSafeAreaInsets
const useSafeAreaInsets = () => {
  console.log('useSafeAreaInsets called (mock implementation)');
  return { top: 20, right: 0, bottom: 0, left: 0 }; // Return mock insets
};

// Export the mocked components and hooks
export { SafeAreaProvider, SafeAreaView, useSafeAreaInsets };
