import { Dimensions } from "react-native";

type DimensionObject = {
  wh: number;
  ww: number;
  sh: number;
  sw: number;
  hp: (x: number) => number;
  wp: (x: number) => number;
}

const screenSize = Dimensions.get("screen");
const windowSize = Dimensions.get("window");

const dimensions: DimensionObject = {
  sh: screenSize.height,
  sw: screenSize.width,
  wh: windowSize.height,
  ww: windowSize.width,
  hp: (multiplier: number) => (screenSize.height / 100) * multiplier,
  wp: (multiplier: number) => (screenSize.width / 100) * multiplier
}

type Colors = {
  primary: string;
  white: string;
  black: string;
  transparent: string;
  greyText: string;
  red: string;
  yellow: string;
  inputBg: string;
  calendarYellow: string;
  primaryLight: string;
  lightGreen: string;
  borderColor: string;
  inputTextColor: string;
  loginTextColor: string;
  trajactionColor: string;
  modalTransparentColor: string;
  orange: string;
}

const Colors: Colors = {
  primary: "#1b7b42",
  white: "#ffffff",
  black: "#000000",
  transparent: "transparent",
  greyText: "#a2a2a2",
  red: "#fc7480",
  yellow: "#f9f3dc",
  inputBg: "#fae89f",
  calendarYellow: "#Fdd028",
  primaryLight: "#DFEAE2",
  lightGreen: "#8BBB4F",
  borderColor: '#ebebeb',
  inputTextColor: '#919191',
  loginTextColor: '#ffa04d',
  trajactionColor: "#FAE3EC",
  modalTransparentColor: 'rgba(14, 14, 14, 0.4)',
  orange: "#FF8500"
}

type ColorName = keyof Colors; 

type Fonts = {
  REG: "Poppins-Regular";
  MED: "Poppins-Medium";
  SBD: "Poppins-SemiBold";
  LGT: "Poppins-Light";
  EBD: "Poppins-ExtraBold";
  BLD: "Poppins-Bold";
}

type FontName = keyof Fonts;

const fonts: Fonts = {
  REG: 'Poppins-Regular',
  MED: 'Poppins-Medium',
  SBD: 'Poppins-SemiBold',
  LGT: 'Poppins-Light',
  EBD: 'Poppins-ExtraBold',
  BLD: 'Poppins-Bold',
}

const BASE_URL: string = "https://seedcartecommerce-76707-ruby.b76707.stage.eastus.az.svc.builder.ai";

export type {ColorName, FontName}
export {
  Colors,
  dimensions,
  fonts,
  BASE_URL
}