/**
 * WeOwnMyChat
 */
/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const COLOR_CONST = {
    whiteColor: '#ffffff',
    blackColor: '#000000',
    bluecolor: "blue",
    greencolor: "green",
    skybluecolor: "skyblue",
    borderColor: '#ebebeb',
    inputTextColor: '#919191',
    loginTextColor: '#ffa04d',
    lightgreycolor: "lightgrey",
    redColor: "red",
    purplecolor: "purple",
    lightpinkColor: "lightpink",
    transperentcolor: "#ffffff00",
    trajactionColor: "#FAE3EC",
    modalTransparentColor: 'rgba(14, 14, 14, 0.4)',
    organge: "#FF8500",
    darkGreyColor: "#909090"
};

export default COLOR_CONST

export const FONTS = {
    PoppinsDisplayRegular: 'Poppins-Regular',
    PoppinsDisplayMedium: 'Poppins-Medium',
    PoppinsDisplaySemiBold: 'Poppins-SemiBold',
    PoppinsLight: 'Poppins-Light',
    PoppinsExtraBold: 'Poppins-ExtraBold',
    PoppinsDisplayBold: 'Poppins-Bold',
}