import React from 'react';

// Customizable Area Start
import { Formik,Form } from 'formik';
import * as Yup from 'yup';
import {Checkbox} from '@material-ui/core';
import {
	StyleSheet
} from 'react-native';

import CountryCodeSelector from '../../country-code-selector/src/CountryCodeSelector.web';

import EmailAccountRegistrationController, {
    Props,
} from './EmailAccountRegistrationController.web';
import { configJSON } from './EmailAccountRegistrationController';
import '../assets/signup.scss'
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import CustomInput from '../../../components/src/CustomInput';
import CustomButton from '../../../components/src/CustomButton';
import { Link } from "react-router-dom";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import { FormControl, FormHelperText} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Divider from '@material-ui/core/Divider';
import SocialMediaLogin from '../../../blocks/social-media-account-login/src/SocialMediaAccontLoginScreen.web-v1'
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start

		// Customizable Area End
	}
	render() {
        // Customizable Area Start
		return (
      <div className="container">
        <div className="banner w-50">
          <img className="logo" src={Logo} />
          <img className="banner-img" src={AuthBanner} />
          <p className="rights-reserved">
            Grow Cart © 2021. All Rights Reserved
          </p>
        </div>
        <div className="auth-form-wrapper w-50">
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              phone: "",
              password: "",
              reTypePassword: "",
              ref_code: "",
              remeberMe:""
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string()
                .required(configJSON.nameRequire)
                .min(8, configJSON.fullNameInvalid)
                .max(20, configJSON.fullNameInvalid)
                .matches(configJSON.nameReg,configJSON.nameInvalid),
              email: Yup.string()
                .required(configJSON.emailRequire)
                .matches(
                  configJSON.emailRegex,
                  configJSON.errorEmailNotValid
                ),
              phone: Yup.string()
                .required(configJSON.phoneRequire)
                .matches(configJSON.phoneRegex, configJSON.phoneInvalid),
              password: Yup.string()
                .required(configJSON.passwordRequire)
                .min(8, configJSON.passwordMinlength)
                .matches(
                  configJSON.passwordRegex,
                  configJSON.passwordInValid
                ),
              reTypePassword: Yup.string()
                .required(configJSON.confirmPasswordRequire)
                .oneOf(
                  [Yup.ref("password"), null],
                  configJSON.confirmPasswordInvalid
                ),
                ref_code: Yup.string()
                .matches(/^[a-zA-Z0-9]*$/,configJSON.refInvalid),
                remeberMe: Yup.string()
                .required(configJSON.termsAndCondition)
            })}
            onSubmit={(values): any => {
              this.doSignup(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue
            }) => (
              //@ts-ignore
              <Form>
                <div className="auth-form">
                  <div className="signup-link">
                    <p>
                      Already have an account ? &nbsp;
                      <Link style={{cursor:'pointer'}} to="/login">Login</Link>
                    </p>
                  </div>
                  <div className="login-form">
                    <h2 className="welcome">Welcome {this.state?.userType==='buyer'?'Buyer':'Merchant'} !</h2>
                    <p style={{ color: "#565555" }}>Signup your account</p>
                    <br />
                    <div>
                      <h4 className="input-lable">Name</h4>
                      <CustomInput
                        className="w-100"
                        id="name"
                        placeholder="Name"
                        variant="outlined"
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                        helperText={errors.fullName}
                        error={
                          touched.fullName && errors.fullName ? true : false
                        }
                      />
                    </div>
                    <br />
                    <div>
                      <h4 className="input-lable">Email Id</h4>
                      <CustomInput
                        className="w-100"
                        id="email"
                        placeholder="Email ID"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={errors.email}
                        error={touched.email && errors.email ? true : false}
                      />
                    </div>
                    <br />
                    <FormControl>
                  <h5 style={{marginBottom: 5}} className="banke-details">Phone</h5>
                    <PhoneInput
                    //@ts-ignore
                      id='phone'
                      name="phone"
                      country={'in'}
                      value={''}
                    //   onlyCountries={['in']}
                      onChange={(e) => setFieldValue('phone', e)}
                      error={touched.phone && errors.phone ? true : false}
                      helperText={errors.phone}
                      countryCodeEditable={false}
                      isValid={(value, country:any) =>
                        {
                          const phoneLenght = value.replace(country.countryCode, "")?.length
                          if(phoneLenght < 10 && phoneLenght>0) return 'Please enter valid phone number'
                          else return true
                        }}
                    />
                   {touched.phone && errors.phone && <FormHelperText id="my-helper-text">{errors.phone}</FormHelperText>}
                  </FormControl>
                  <br />
                    <div>
                        <br />
                      <h4 className="input-lable">Password</h4>
                      <CustomInput
                        className="w-100"
                        id="password"
                        placeholder="Password"
                        variant="outlined"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        helperText={errors.password}
                        error={
                          touched.password && errors.password ? true : false
                        }
                      />
                    </div>
                    <br />
                    <div>
                      <h4 className="input-lable">Confirm Password</h4>
                      <CustomInput
                        className="w-100"
                        id="confirm-password"
                        placeholder="Confirm Password"
                        variant="outlined"
                        type="password"
                        name="reTypePassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reTypePassword}
                        helperText={errors.reTypePassword}
                        error={
                          touched.reTypePassword && errors.reTypePassword
                            ? true
                            : false
                        }
                      />
                    </div>
                    <br />
                    <div>
                      <h4 className="input-lable">Referral Code(if available)</h4>
                      <CustomInput
                        className="w-100"
                        id="referral"
                        placeholder="Referral Code"
                        variant="outlined"
                        name="ref_code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ref_code}
                        helperText={errors.ref_code}
                        error={
                          touched.ref_code && errors.ref_code ? true : false
                        }
                      />
                    </div>
                    <br />
                    {/* <div>
                        <CustomButton variant="contained" style={{ background: "#50ae65" }} 
                        onClick={(e) => {
                        handleSubmit();
                        }}
                        disabled={this.state.countryCodeSelected!==''?false:true}>
                        SIGNUP
                        </CustomButton>
                    </div> */}

                    <p className="remember-me-section">
                    <div className='div-rember'>
                      <Checkbox
                        className="remember-checkbox"
                        name="remeberMe"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        onChange={(e:any)=>{
                          if(values.remeberMe){
                            
                            setFieldValue("remeberMe","")
                          }else{
                            
                            setFieldValue("remeberMe",true)
                          }
                          
                        }}
                      />
                      
                       <p>I accept </p>&nbsp;
                       <Link to='/terms' target="_blank" rel="noopener noreferrer">
                        <p className="forgot-password" >
                          Terms & Condition &nbsp;
                        </p>
                        </Link>
                       <p>and  </p>&nbsp;
                       <Link to='/privacy' target="_blank" rel="noopener noreferrer">
                        <p className="forgot-password" >
                          Privacy Policy
                        </p>
                        </Link>
                        </div>
                    </p>
                    {this.state.termsAndContion&&
                    <p className='terms-condition'>Please agree terms and condition</p>
                    }
                    <br />
                    <div>
                      <CustomButton
                        className="login-button"
                        name="login-loading"
                        id="login-loading"
                        variant="contained"
                        onClick={(e) => {
                          
                          if(!values.remeberMe){
                          this.setState({termsAndContion:true})
                          }else{
                            this.setState({termsAndContion:false})
                          }
                          handleSubmit();
                          
                        }}
                      >
                        SIGNUP
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
        // Customizable Area End
	}

	async componentDidMount() {
		// Customizable Area Start
		this.getValidations();
		// Customizable Area End
	}
}

const styles = StyleSheet.create({
	// Customizable Area Start
	// container: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// 	padding: 16,
	// 	maxWidth: '100%',
	// 	backgroundColor: '#fff',
	// },
	// inputsDiv: {
	// 	paddingLeft: 50,
	// 	paddingRight: 50,
	// },
	// bannerImage: {
	// 	width: '50%',
	// 	maxWidth: '50%',
	// 	flex: 1,
	// },
	// titleWhySignUp: {
	// 	marginBottom: 32,
	// 	fontSize: 16,
	// 	textAlign: 'left',
	// 	marginVertical: 8,
	// },
	// titleOtpInfo: {
	// 	marginBottom: 32,
	// 	fontSize: 16,
	// 	textAlign: 'left',
	// 	marginVertical: 8,
	// },
	// bgInput: {
	// 	flexDirection: 'row',
	// 	fontSize: 16,
	// 	textAlign: 'left',
	// 	backgroundColor: '#00000000',
	// 	marginTop: 24,
	// 	borderWidth: 1,
	// 	borderColor: '#767676',
	// 	borderRadius: 2,
	// 	includeFontPadding: true,
	// 	padding: 10,
	// },

	// inputWeb: {
	// 	flex: 1,
	// 	flexDirection: 'row',
	// 	marginTop: 10,
	// 	marginBottom: 20,
	// 	fontSize: 18,
	// 	padding: 10,
	// 	borderColor: '#999',
	// 	includeFontPadding: true,
	// 	borderWidth: 1,
	// 	borderRadius: 10,
	// },

	// inputWeb1: {
	// 	flex: 1,
	// 	flexDirection: 'row',
	// 	marginTop: 10,
	// 	marginBottom: 20,
	// 	fontSize: 18,
	// 	padding: 10,
	// 	borderColor: '#999',
	// 	includeFontPadding: true,
	// 	borderWidth: 1,
	// 	borderRadius: 10,
    //     backgroundColor:'black'
	// },
	// inputLabel: {
	// 	fontSize: 16,
	// 	fontWeight: 'bold',
	// },

	// bgRectBorder: {
	// 	borderWidth: 1,
	// 	borderColor: '#767676',
	// 	borderRadius: 2,
	// 	marginBottom: 10,
	// },
	// bgPasswordInput: {
	// 	flex: 1,
	// 	fontSize: 16,
	// 	textAlign: 'left',
	// 	backgroundColor: '#00000000',
	// 	minHeight: 40,
	// 	includeFontPadding: true,
	// 	marginTop: 10,
	// 	paddingLeft: 0,
	// },
	// passwordShowHide: {
	// 	alignSelf: 'center',
	// },
	// bgPasswordContainer: {
	// 	flexDirection: 'row',
	// 	backgroundColor: '#00000000',
	// 	marginBottom: 16,
	// 	borderWidth: Platform.OS === 'web' ? 0 : 1,
	// 	borderBottomWidth: 1,
	// 	borderColor: '#767676',
	// 	borderRadius: 2,
	// 	paddingLeft: 5,
	// 	paddingRight: 5,
	// 	zIndex: -1,
	// },
	// imgPasswordShowhide: Platform.OS === 'web' ? { height: 30, width: 30 } : {},
	// keyboardPadding: { flex: 1 },
	// btnLegalTermsAndCondition: { color: '#6200EE' },
	// btnLegalPrivacyPolicy: { color: '#6200EE', marginLeft: 'auto' },
	// leagalText: { marginTop: 10, fontSize: 16 },
	// headline: {
	// 	flex: 1,
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// 	textAlign: 'center',
	// },
	// signUpText: {
	// 	fontSize: 32,
	// 	// color: '#6200EE',
	// 	fontWeight: 'bold',
	// },
	// Customizable Area End
});
