Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account_block/accounts/sms_confirmations";
exports.apiGetSignupOtpEndPoint = "account_block/accounts/send_otps"
exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
// Customizable Area End
