//Customizable Area Start
import React,{ useEffect,useState,Fragment } from "react";
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import Box from "@material-ui/core/Box";
import { Button,Typography,Modal } from '@material-ui/core';
import "../assets/TotalEarningsWeb.scss"; 
import moment from "moment";
//@ts-ignore
import html2pdf from "html2pdf.js"; 
const growCart = require("../../../components/src/GrowchartIcon.jpeg").default;
const closeIcon = require('../../../components/src/close.png').default
import 'react-table-v6/react-table.css';
import '../../../components/src/styles.scss';
import CustomReactTable from '../../../components/src/CustomReactTableWithServerSidePagination';
import DashboardFooter from '../../../components/src/DashboardFooter';
import axios from 'axios';
//@ts-ignore
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { translate } from "framework/src/Translation";
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';
const configURL = require('../../../framework/src/config');

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export interface Props{
  history:any;
}
export default function TotalEarningsWeb(props:any){
  const [totalEarningsData, setTotalEarningsData] = useState<any>([]);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [recordStartIndex, setRecordStartIndex] = useState<number>(0);
  const [recordEndIndex, setRecordEndIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [totalEarningsDataId,setTotalEarningsDataId] = useState<any>(null);
  const [branchName,setBranchName] = useState<string>("TotalEarnings");
  const [menu,setMenu] = useState<boolean>(false);
  const [keepTotalEarnings,setKeepTotalEarnings] = useState<boolean>(true);
  const { language } = useAppState();
    const refresh = useRefresh();
//   const [allTotalLengthOfOrder,setAllTotalLengthOfOrder] = useState<any>();
  const columns = [
    {
        Header:translate("order_id"),
        accessor:'order_number',
        Cell:(row:any) => (
            <div className="order-id-clickable" >
            {row?.original?.attributes?.order_number}
            </div>
        )
    },
    {
        Header: translate("date"),
        accessor: 'order_date',
        //@ts-ignore
        Cell: row => (
            <div>
            {moment(new Date(row?.original?.attributes?.order_date)).format('DD/MM/YYYY')}
            </div>
    )
    },
    {
        Header: translate("time"),
        accessor: 'order_date',
        //@ts-ignore
        Cell: row => (
            <div>
            {moment(new Date(row?.original?.attributes?.order_date)).format('hh:mm a')}
            </div>
    )
    },
    {
        Header: translate("total_amount"),
        accessor: 'amount',
        Cell: (row:any) => (
            <div>
                ₹ {row?.original?.attributes?.amount}
            </div>
          )
    },
    {
        Header: translate("action"),
        accessor: 'status',
        Cell: (row:any) => (
            //@ts-ignore
                <Button variant='primary' className='download-invoice-modal-btn' onClick={()=>{
                    setTotalEarningsDataId(row.original.id)
                    setOpenModal(true)
                }}>
                    Download Invoice
                </Button>
        )
        ,
    },
  ];
  const downloadInvoice = () => {
    const invoice = document.getElementById("pdf");
        var opt = {
            filename: 'Invoice.pdf',
            margin: [0,-100,0,-100],
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().from(invoice).set(opt).save();
    }
    const setBranchNam = (num: any) => {
        setBranchName(num);
      };
    const showSidebar = (flag: boolean) => {
        setMenu(flag);
      };
    const  keepTotalEarns = (value: any) => {
        setKeepTotalEarnings(value);
      };
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
    
        return (
          <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box
                //@ts-ignore
                sx={{ p: 3,marginTop:"30px" }}
              >
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      useEffect(()=>{
        setLoading(true);
        axios.get(configURL.baseURL+`/bx_block_dashboard/earning`,
        {headers:{
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: Cookies.get("Login_Token"),
        },
        params: {
            // earning:"todays",
            page: currentPage,
            per_page: recordsPerPage,
         }
        },
        )
        .then((res:any)=>{
            if(res.data){
              setLoading(false);
              setTotalEarningsData(res.data?.data);
            //   setAllTotalLengthOfOrder(res.data?.total_entries);
              setTotalEntries(res.data?.total_entries);
              setRecordStartIndex(res.data?.start_from);
              setRecordEndIndex(res.data?.end_to);
            }
            else{
                setLoading(false);
                setTotalEntries(0);
                // setAllTotalLengthOfOrder(0);
            }
            
        })
        .catch(err=>{
          setLoading(false)
            toast.error('something went wrong')
        });
        
    },[currentPage]);

    useEffect(() => {
        refresh();
      }, [language]);
    return(
      <div className="containerDas">
        <div className="bannerDas">
        {Cookies.get('Login_Token') && <SidebarWeb
           //@ts-ignore
            openSidebar={menu} changeIconValue={showSidebar} setBranchName={setBranchNam} keepTotalEarnings={keepTotalEarnings} keepTotalEarn={keepTotalEarns} history={props?.history}/>}
        </div>
        <div className={`auth-form-wrapper ${!Cookies.get('Login_Token') && 'w-50'}`}>

            {/* <NavbarWeb branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} history={props?.history} searchedValue={searchedValue} setSearchedValue={setSearchedValue}/> */}
            <NavbarWeb changeIconValue={showSidebar} totalAmountOfOrder={props.location.state} branchName={branchName} history={props?.history} />
            <Box className="table-box">
            <TabPanel value={0} index={0}>
					<CustomReactTable
						id='order-list'
						data={totalEarningsData}
						loading={loading}
						recordsPerPage={recordsPerPage}
						columns={columns}
						totalEntries={totalEntries}
						recordStartIndex={recordStartIndex}
						recordEndIndex={recordEndIndex}
						currentPage={currentPage}
						changePage={(e, page) => setCurrentPage(page)}
            noDataText={translate("total_earning_not_found")}
					/>
					<DashboardFooter />
				</TabPanel>
                
            </Box>
        </div>
         {/* @ts-ignore */}
         {openModal && <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <div style={{position:'relative'}}>
                        <img src={closeIcon} alt="" style={{width:'25px', height: '25px', position:'absolute', right: '20px', top:'0px', cursor:'pointer'}} onClick={()=>{
                            setOpenModal(false)
                        }}/>
                        <div id="pdf">
                            <div className="invoice-main-div">
                                {totalEarningsData.length !== 0 &&
                                    totalEarningsData?.map((data: any) => {
                                        if (data.id === totalEarningsDataId) {
                                            return (
                                                <div key={data.id} style={{ margin: "0 5px" }}>
                                                    <div className="invoice-header">
                                                        <img src={growCart} className="invoice-logo" />
                                                    </div>
                                                    <div className="invoice-title">
                                                        <p className="invoice-title-text">{translate("invoice")}</p>
                                                    </div>
                                                    <div className="top-invoice-div">
                                                        <div className="date-no-div">
                                                            <div className="invoice-no-div">
                                                                <p className="text-title">
                                                                    Invoice No:
                                                                </p>
                                                                <p className="no-date">
                                                                    {data.attributes.order_number}
                                                                </p>
                                                            </div>
                                                            <div className="invoice-no-div">
                                                                <p className="text-title">{translate("date")}: </p>
                                                                <p className="no-date">
                                                                    {moment(
                                                                        new Date(
                                                                            data.attributes?.order_date
                                                                        )
                                                                    ).format("DD/MM/YYYY")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="biller-div">
                                                            <p className="text-title">Bill To:</p>
                                                            <p className="cust-name">
                                                                {data.attributes.account.attributes.fullname.trim()}
                                                            </p>
                                                            <p className="cust-addr">
                                                                {data.attributes.delivery_addresses[0].address.trim()}{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0]
                                                                        .address_line_2
                                                                }{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].landmark
                                                                }{" "}
                                                                {data.attributes.delivery_addresses[0].city.trim()}
                                                                ,{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].state
                                                                }
                                                                ,{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].country
                                                                }{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].zip_code
                                                                }
                                                                .
                                                            </p>
                                                            <p className="cust-phone">
                                                                +
                                                                {
                                                                    data.attributes.account.attributes
                                                                        .full_phone_number
                                                                }
                                                            </p>
                                                            <p className="cust-email">
                                                                {
                                                                    data.attributes.account.attributes
                                                                        .email
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <th>{translate("product_description")}</th>
                                                            <th>{translate("quantity")}</th>
                                                            <th>{translate("price")}</th>
                                                            <th>{translate("amount")}</th>
                                                        </tr>
                                                        <Fragment>
                                                            {data.attributes.order_items?.map(
                                                                (item: any) => (
                                                                    <tr key={item.id}>
                                                                        <td className="desc-td">
                                                                            {
                                                                                item.attributes
                                                                                    .catalogue
                                                                                    .attributes.name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {item.attributes.quantity}
                                                                        </td>
                                                                        <td>
                                                                            {item.attributes.unit_price}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.attributes
                                                                                    .total_price
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </Fragment>
                                                        <tr>
                                                            <td colSpan={3} className="total">
                                                            {translate("tax")}:
                                                            </td>
                                                            <td>{data.attributes.tax_charges}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3} className="total">
                                                            {translate("total")}:
                                                            </td>
                                                            <td>{data.attributes.total}</td>
                                                        </tr>
                                                    </table>
                                                    <div className="invoice-footer">
                                                        <p className="invoice-footer-text">
                                                            *** {translate("thank_you")} ***
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                        <Button className='download-invoice' onClick={()=>{
                                downloadInvoice();
                            }}>
                                {translate("download_invoice")}
                        </Button>
                    </div> 
                </Modal>}
      </div>
    )
}


//Customizable Area End
