import { set_user_data } from "./actions";
import { AppState, AppAction } from "./types";

export const initState: AppState =  {
  auth: false,
  id: "",
  name: "",
  email: "",
  mobile: "",
  token: "",
  language: "en",
  role: "",
  navigationLocation: "",
}

export function appContextReducer(state = initState, action: AppAction): AppState{
  console.log("Current state before action:", state); // Log current state
  console.log("Action dispatched:", action); // Log action
  switch(action.type){
    case set_user_data:
      // return {
      //   ...state,
      //   ...action.payload
      // };
      const updatedState = {
        ...state,
        ...action.payload,
      };
      console.log("Updated state:", updatedState); // Log the updated state
      return updatedState;
    default:
      return state;
  }
}