// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"
//@ts-ignore
import _ from 'lodash'
export const configJSON = require("./config.js");

export interface Props {
  title: string;
  productList: any;
  showViewAllButton: boolean;
  onViewAllClick: () => void; 
  onProductClick: (productId: number) => void;
}

interface S {
  featImgLoaded: any
}

interface SS {
  id: any;
}

export default class BuyerProductDetailController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
    ];

    this.state = {
      featImgLoaded: []
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  static contextType?: Context<any> | undefined = AppContext;

  async componentDidMount() {
  }

  async componentDidUpdate(prevProps: any) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if(!_.isEqual(prevProps?.productList, this.props.productList) ){
      const tempArr:any = [];
      this.props.productList.map(() => tempArr.push({load: false}))
      this.setState({featImgLoaded: tempArr})
    }
  }

  async receive(from: string, message: Message) {
	}

  getLocalizedName(item: any): string {
    const { language } = this.context.state;
    // const { language } = this.context;
// console.log("printing cotext", this.context.state)
    if (language === 'hi' && item.attributes.hindi_name) {
      return item.attributes.hindi_name;
    } else if (language === 'kn' && item.attributes.kannada_name) {
      return item.attributes.kannada_name;
    }
    
    return item.attributes.name;
  }

}
// Customizable Area End