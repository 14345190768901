//Customizable Area Start
import React from 'react';
import {Link } from 'react-router-dom'
import BuyerNavbarWeb from '../../../blocks/dashboard/src/BuyerNavbar.web';
import ProductsListingController from './ProductsListingController.web';
import { Typography, CircularProgress } from '@material-ui/core';
//@ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../assets/ProductsListing.scss';
import Footer from '../../dashboard/src/Footer.web';
const prodSkel = require('../../../blocks/dashboard/assets/prodSkel.png').default;
import ProductCard from './ProductCard.web';
import { translate } from 'framework/src/Translation';

export default class ProductsListing extends ProductsListingController {
	constructor(props: any){
		super(props);
	}

    handleProductClick = (id: string) => {
        //@ts-ignore
        this.props.history.push(`/product-details/${id}`);
      };
	render() {
		return (
			<>
                <BuyerNavbarWeb />
                {!this.state.catLoading && (
                    <>
                        <div className="home-path">
                            <Typography
                                className="home"
                                onClick={() => {
                                    //@ts-ignore
                                    this.props.history.push("/");
                                }}
                            >
                                Home {" >  "}
                            </Typography>
                            {/* @ts-ignore */}
                            {this.props.match.params.id !== "category" ? (
                                <span style={{ color: "#888", fontFamily: "Poppins" }}>
                                    {/* @ts-ignore */}
                                    {this.props.match.params.id}
                                </span>
                            ) : (
                                <span style={{ color: "#888", fontFamily: "Poppins" }}>
                                    {/* @ts-ignore */}
                                    {this.props.location.state.name}
                                </span>
                            )}
                        </div>
                        <div className="top-prod-container">
                            <div className="title">
                                <Typography
                                    style={{
                                        fontSize: "32px",
                                        //@ts-ignore
                                        fontWeight: "600",
                                    }}
                                >
                                    {/* @ts-ignore */}
                                    {this.props.match.params.id === "top"
                                        ? translate("top_products")
                                        //@ts-ignore
                                        : this.props.match.params.id === "featured"
                                        ? translate("featured_products") :
                                        //@ts-ignore
                                         this.props.match.params.id === "recently-sold"
                                        ? translate("recently_sold")
                                        //@ts-ignore
                                        : this.props.location.state.name}
                                </Typography>
                            </div>
                            {!this.state.loader ? (
                                <>
                                    <div className="product-section">
                                        {this.state.dashboardData.length !== 0 ? 
                                        (this.state.dashboardData.map(
                                                (data: any, i: any) => {
                                                    return (
                                                        <ProductCard
                                                            key={data.id}
                                                            data={data}
                                                            isRecentlySold={this.props.match.params.id === "recently-sold"}
                                                            onProductClick={this.handleProductClick}
                                                            prodSkel={prodSkel}
                                                        />
                                                    );
                                                }
                                            )
                                        ) : (
                                            <Typography>No Products Found!!</Typography>
                                        )}
                                    </div>
                                    {!this.state.productsListEnd && (
                                        <div
                                            id="loader"
                                            style={{ display: "block", margin: "10px 48%" }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <CircularProgress style={{ margin: "10% 49%" }} />
                            )}
                        </div>
                    </>
                )}
                {/* @ts-ignore */}
                {!this.state.loader && <Footer hasData={this.state.dashboardData} />
}
            </>
		);
	}
}
//Customizable Area End
