import React from "react";

import {
  StyleSheet,
  Image,
  TouchableOpacity,
  View
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { checked } from "./assets";
import { translate } from "framework/src/Translation";
import Typography from "../../../components/src/Typography";
import { Colors, dimensions } from "../../../components/src/constants";
import Header from "../../../components/src/RNHeader";
import { AppLoader } from "../../../components/src/Index";
import LanguageSupportController, {
  Props,
  configJSON
} from "./LanguageSupportController";

export default class LanguageSupport extends LanguageSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start\
  renderLanguage = ({ tag, name }: { tag: string, name: string }) => {
    return(
      <TouchableOpacity
        onPress={() => this.changeLanguage(tag)}
        style={styles.item}
      >
        {(this.context.state.language === tag)
        ?<Image source={checked} style={styles.image} />
        :<View style={styles.unchecked} />}
        <Typography>{name}</Typography>
      </TouchableOpacity>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.container}>
        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
        <Header backIcon title={translate("language")} navigation={this.props.navigation} />
        <View style={styles.wrapper}>
          {this.renderLanguage({ tag: "en", name: "English" })}
          {this.renderLanguage({ tag: "hi", name: "हिन्दी" })}
          {this.renderLanguage({ tag: "kn", name: "ಕನ್ನಡ" })}
        </View>
        <AppLoader isFetching={this.state.loading} {...this.props} />
        {/* Merge Engine UI Engine Code */}
        {/* Customizable Area End */}
        </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white
  },
  wrapper: {
    flex: 1,
    padding: dimensions.wp(5)
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: Colors.borderColor,
    borderWidth: 1,
    marginBottom: dimensions.hp(2),
    padding: dimensions.wp(5),
    borderRadius: dimensions.wp(2)
  },
  unchecked: {
    height: dimensions.wp(6),
    width: dimensions.wp(6),
    borderWidth: 1,
    borderColor: Colors.borderColor,
    borderRadius: dimensions.wp(3),
    marginRight: dimensions.wp(3)
  },
  image: {
    height: dimensions.wp(6),
    width: dimensions.wp(6),
    resizeMode: "contain",
    marginRight: dimensions.wp(3),
    tintColor: Colors.orange
  }
});
// Customizable Area End
