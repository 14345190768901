//Customizable Area Start
import React from 'react';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerOrdersController from './BuyerOrdersController.web';
import BuyerSidebar from './BuyerSidebar.web';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	TextField,
	CircularProgress,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import '../assets//BuyerOrders.scss';
import FooterWeb from '../../dashboard/src/Footer.web';
import { translate } from 'framework/src/Translation';
const searchIcon = require('../../../components/src/serachIcon.png').default;
const filter_icon = require('../assets/filter_icon.png').default;

const styles = (theme: any) => ({
	root: {
		width: '20rem',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	noBorder: {
		border: 'none',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class BuyerOrders extends BuyerOrdersController {
	constructor(props: any) {
		super(props);
	}

	render() {
		//@ts-ignore
		const { classes } = this.props;
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
					{/* @ts-ignore */}
					<BuyerSidebar activeTab={4} />

					<div className='profile-container buyer-orders-list-container'>
						<div className='orders-top'>
							<Typography className='order-heading'>{translate("all_orders")}</Typography>
							<div className='search-filter-part'>
							<TextField
								id='searchbar'
                                className='buyer-order-search'
								placeholder='Search'
								variant='outlined'
								value={this.state.searchVal}
								onChange={(e) => {
									this.setState({
										searchVal: e.target.value,
									});
									setTimeout(() => {
										this.searchOrders();
									}, 0);
								}}
								InputProps={{
									startAdornment: <img src={searchIcon} alt='' />,
									//@ts-ignore
									classes: {
										notchedOutline: classes.noBorder,
									},
								}}
							/>
							<div style={{ position: 'relative' }}>
								<div
									className='filter-orders-con'
									onClick={() => {
										this.setState({
											isFilterModalOpen: !this.state.isFilterModalOpen,
										});
									}}
								>
									<img src={filter_icon} alt='' width={20} height={20} />
									<Typography className='filter-text'>{translate("filter")}</Typography>
								</div>
								{this.state.isFilterModalOpen && (
									<div className='filter-modal'>
										<div
											className='overlay1'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
												});
											}}
										/>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 1,
                                                    filterFrequency: 'week'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_week")}
										</Typography>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 1,
                                                    filterFrequency: 'month'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_month")}
										</Typography>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 3,
                                                    filterFrequency: 'month'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_3_months")}
										</Typography>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 6,
                                                    filterFrequency: 'months'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_6_months")}
										</Typography>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 1,
                                                    filterFrequency: 'year'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_year")}
										</Typography>
										<Typography
											className='filter-opt'
											onClick={() => {
												this.setState({
													isFilterModalOpen: false,
                                                    filterDuration: 2,
                                                    filterFrequency: 'year'
												});
                                                setTimeout(()=>{

                                                    this.filterOrders()
                                                },0)
											}}
										>
											{translate("last_2_years")}
										</Typography>
									</div>
								)}
							</div>
							</div>
						</div>
						<div className='orders-container'>
							{!this.state.loader ? (
								<>
									{this.state.ordersData.length !== 0 ? (
										this.state.ordersData.map((data: any) => {
											return (
												<div className='order-div'>
													<div className='order-details'>
														<div className='order-img'>
															<img
																src={
																	data.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.web_thumbnail.url || 'https://image.shutterstock.com/image-vector/sample-stamp-rubber-style-red-260nw-1811246308.jpg'
																}
																alt=''
																className='fix-img'
																style={{
																	
																}}
															/>
														</div>
														<div className='order-text-details'>
															<Typography className='ord-prod-name'>
																{
																	data.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.name
																}
															</Typography>
															<Typography className='ord-prod-quantity'>
																{
																	data.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.value
																}{' '}
																{
																	data.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.quantity
																}
															</Typography>
															<Typography className='ord-prod-price'>
																₹{' '}
																{
																	data.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.price
																}
															</Typography>
														</div>
													</div>
													<div className='order-status'>
														<Typography className='status-text'>
															{data.attributes.status}
														</Typography>
													</div>
												</div>
											);
										})
									) : (
										<Typography className='noorder-heading'>
											{translate("no_orders_found")}
										</Typography>
									)}
                                    {!this.state.ordersEnd && (
                                        <div
                                            id="loader"
                                            style={{ display: "block", margin: "10px 48%" }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )}
								</>
							) : (
								<CircularProgress style={{ margin: '10% 49%' }} />
							)}
						</div>
					</div>
				</div>
				<FooterWeb />
			</>
		);
	}
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerOrders));
//Customizable Area End
