import React from "react";

// Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";

import * as IMG_CONST from "./assets";

import styles from "./NewPasswordStyle";

import LinearGradient from "react-native-linear-gradient";

import NewPasswordController, { Props } from "./NewPasswordController";
import FocusAwareStatusBar from "../../../components/src/FocusAwareStatusBar";
import Toast, { DURATION } from "react-native-easy-toast";
import ApplicationLoader from "../../../components/src/AppLoader/src/AppLoader";
import { translate } from "framework/src/Translation";
import RNButton from "../../../components/src/RNButton";
//Customizable Area End

//Customizable Area Start
export default class NewPassword extends NewPasswordController {
  //Customizable Area End

  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  renderHeader = () => {
    return (
      <View>
        <View style={styles.signupTextContainer}>
          <TouchableOpacity activeOpacity={1} style={styles.backArrowButton} onPress={() => this.props.navigation.replace("EmailAccountLoginBlock")}>
            <Image style={styles.backIcon} source={IMG_CONST.BACK_ARROW} />
          </TouchableOpacity>
          <Text style={styles.signupText}>{translate("reset_password")} </Text>
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  renderEmailTextInput = () => {
    return (
      <View style={styles.emailInputContainer}>
        <Text style={styles.emailText}>{translate("new_password")}</Text>
        <View style={styles.emailField}>
          <TextInput
            style={styles.inputEmailStyle}
            placeholder={translate("new_password")}
            placeholderTextColor="#848484"
            secureTextEntry={true}
            autoCorrect={false}
            value={this.state.newPassword}
            onChangeText={(text) =>{
              let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
              if(check.test(text))
              {
              this.setState(
                { isInvalidPassword: false, newPassword: text },
                () => {}
              )
            }
            else{
              this.setState(
                { isInvalidPassword: true, newPassword: text },
                () => {}
              )
            }
          }
            }
          />
        </View>
        {this.state.isInvalidPassword && (
          <View style={{ alignSelf: "flex-start" }}>
            <Text style={styles.errorText}>{this.state.newPassword.length===0 ? translate("enter_your_password") : translate("password_should_contain_format_text")}</Text>
          </View>
        )}
        <Text style={styles.emailText}>{translate("confirm_new_password")}</Text>
        <View style={styles.emailField}>
          <TextInput
            style={styles.inputEmailStyle}
            placeholder={translate("confirm_new_password")}
            placeholderTextColor="#848484"
            secureTextEntry={true}
            autoCorrect={false}
            value={this.state.confirmNewPassword}
            onChangeText={(text) =>{
              if(this.state.newPassword != text)
              {
              this.setState(
                { isInvalidConfirmPassword: true, confirmNewPassword: text },
                () => {}
              )
              }
              else{
                this.setState(
                  { isInvalidConfirmPassword: false, confirmNewPassword: text },
                  () => {}
                )
              }
            }
            }
          />
        </View>
        {this.state.isInvalidConfirmPassword && (
          <View style={{ alignSelf: "flex-start" }}>
            <Text style={styles.errorText}>{this.state.confirmNewPassword.length===0 ? translate("please_enter_confirm_password") : translate("confirm_password_does_not_match")}</Text>
          </View>
        )}
      </View>
    );
  };

  renderSubmitButton = () => {
    return (
      <RNButton onPress={() => this.onPressUpdateButton()} children={translate("update")} />
      // <TouchableOpacity onPress={() => this.onPressUpdateButton()} style={styles.loginButtonContainer}>
      //   <LinearGradient
      //     start={{ x: 0.3, y: 0 }}
      //     end={{ x: 0.6, y: 0 }}
      //     colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
      //     style={styles.loginView}
      //   >
      //     <Text style={styles.loginText}>UPDATE</Text>
      //   </LinearGradient>
      // </TouchableOpacity>
    );
  };

  renderPasswordResetSuccessModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isShowPasswordReset}
        onRequestClose={() => {
          this.setState({ isShowPasswordReset: false });
        }}
      >
        <View style={styles.modalContainer}>
        <Image style={styles.resetSuccessIcon} source={IMG_CONST.RESET_PASS_SUCCESS}/>
        <Text style={styles.resetPasswordText}>{translate("password_reset")}</Text>
        <Text style={styles.resetPasswordChangedText}>
          {translate("your_password_has_been_changed")}
        </Text>
        <Text style={styles.resetPasswordChangedText}>
          {translate("successfully")}
        </Text>
        <RNButton onPress={() => this.onPressBackToLoginButton()} children={translate("back_to_login")} />
          {/* <TouchableOpacity
            onPress={() => this.onPressBackToLoginButton()}
            style={styles.loginButtonContainer}
          >
            <LinearGradient
              start={{ x: 0.3, y: 0 }}
              end={{ x: 0.6, y: 0 }}
              colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
              style={styles.loginView}
            >
              <Text style={styles.loginText}>{translate("back_to_login")}</Text>
            </LinearGradient>
          </TouchableOpacity> */}
        </View>
      </Modal>
    );
  };
  //Customizable Area End

  render() {
    return (
      //Customizable Area Start
      <View style={styles.container}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"white"}
          isFocused={true}
        />
        {this.renderHeader()}
        {this.renderEmailTextInput()}
        {this.renderSubmitButton()}
        {this.renderPasswordResetSuccessModal()}
        <ApplicationLoader isFetching={this.state.isFetching} {...this.props} />
        <Toast
          ref={(toast) => (this.toastRef = toast)}
          position={"bottom"}
          positionValue={150}
          textStyle={{ fontwidth: "bold", color: "#ffffff" }}
          fadeOutDuration={1000}
        />
      </View>
      //Customizable Area End
    );
  }
}

//Customizable Area Start
//Customizable Area End
