// @ts-ignore
import i18n from "i18n-js";

type Language = "en" | "hi" | "kn";

function memoize(func: Function){

  if(typeof func !== "function"){
    throw new TypeError("Expected a function");
  }

  const memoizedFunction = function(...args: string[]){
    const cache = memoizedFunction.Cache;
    const key = args[0] || "NotFound";
    if (cache.has(key)){
      return cache.get(key);
    }
    const result = func.apply(null, args);
    cache.set(key, result);
    return result;
  }

  memoizedFunction.Cache = new Map;
  return memoizedFunction;
}

const getTranslation: Record<Language, () => NodeRequire> = {
  en: () => require("../../components/src/translations/en.json"),
  hi: () => require("../../components/src/translations/hi.json"),
  kn: () => require("../../components/src/translations/kn.json")
}

const translate = memoize(function (key: string){
  return i18n.t(key);
});

function setI18Config(language: Language) {
  const fallbackLanguage = "en";
  translate?.Cache?.clear?.();
  i18n.translations = { [language]: getTranslation[language || fallbackLanguage]?.() };
  i18n.locale = language;
}

export { setI18Config, translate  };
export type {Language}