//Customizable Area Start
import React, { useEffect, useState, Fragment } from 'react';
import { Box, Typography, Button, Modal, CircularProgress } from '@material-ui/core';
//@ts-ignore
import html2pdf from "html2pdf.js"
const growCart = require("../../../components/src/GrowchartIcon.jpeg").default
import 'react-table-v6/react-table.css';
import '../../../components/src/styles.scss';
import '../assets/InvoiceBilling.scss'
import 'react-toastify/dist/ReactToastify.css';
import CustomReactTable from '../../../components/src/CustomReactTableWithServerSidePagination';
import DashboardFooter from '../../../components/src/DashboardFooter';
import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import moment from "moment";
import useDebounce from '../../../components/src/UseDebounce';
import { translate as t } from 'framework/src/Translation';
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';

const configURL = require('../../../framework/src/config');

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export default function InvoiceBilling(props:any) {
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [totalEntries, setTotalEntries] = useState<number>(0);
    const [recordStartIndex, setRecordStartIndex] = useState<number>(0);
    const [recordEndIndex, setRecordEndIndex] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [invoiceId, setInvoiceId] = useState<any>(null)

    const debouncedSearchedData = useDebounce(props?.insearchedValue, 500);
    const { language } = useAppState();
    const refresh = useRefresh();

    const columns = [
		{
			Header: t("order_id"),
			accessor: 'order_number',
            Cell: (row:any) => (
                <div className="order-id-clickable" >
                {row?.original?.attributes?.order_number}
                </div>
            )
		},
		{
			Header: t("date"),
			accessor: 'order_date',
            //@ts-ignore
            Cell: row => (
                <div>
                {moment(new Date(row?.original?.attributes?.order_date)).format('DD/MM/YYYY')}
                </div>
        )
		},
		{
			Header: t("items"),
			accessor: 'order_items',
            Cell: (row:any) => (
                <div>{
                row?.original?.attributes?.order_items.map((elem:any) => {
                  return(
                    <p>{elem?.attributes?.catalogue?.attributes?.name+' X'+elem?.attributes?.quantity}</p>
                  )
                })}
                </div>
              )
		},
		{
			Header: t("total_amount"),
			accessor: 'amount',
            Cell: (row:any) => (
                <div>
                    ₹ {row?.original?.attributes?.amount}
                </div>
              )
		},
		{
			Header: '',
			accessor: 'status',
			Cell: (row:any) => (
                //@ts-ignore
                    <Button variant='primary' className='download-invoice-modal-btn' onClick={()=>{
                        setInvoiceId(row.original.id)
                        setOpenModal(true)
                    }}>
                        Download Invoice
                    </Button>
            )
            ,
		},
	];

    const downloadInvoice = () => {
        const invoice = document.getElementById("pdf");
            var opt = {
                filename: 'Invoice.pdf',
                margin: [0,-100,0,-100],
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
            };
            html2pdf().from(invoice).set(opt).save();
    }

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box
						//@ts-ignore
						sx={{ p: 3,marginTop:"30px" }}
					>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
    console.log(loading)
	useEffect(() => {
        setLoading(true)
		axios
			.get(
				configURL.baseURL + '/bx_block_order_management/orders/order_listing',
				{headers: {
						"Content-Type": "application/json;charset=UTF-8",
						"Access-Control-Allow-Origin": "*",
						token: Cookies.get("Login_Token"),
					},
                    params: {
                        page: currentPage,
                        per_page: recordsPerPage,
                        search: debouncedSearchedData
                    }
				},
			)
			.then((res: any) => {
                
                setLoading(false)
                setInvoiceData(res.data?.data)
                setTotalEntries(res?.data.total_entries)
                setRecordStartIndex(res?.data.start_from)
                setRecordEndIndex(res?.data.end_to)
                setLoading(false)  
            })
            .catch(err=>{
                toast.error('something went wrong')
                setLoading(false)
            });
	}, [currentPage, debouncedSearchedData]);

    useEffect(() => {
        refresh();
      }, [language]);

    return (
		<div>
			<Box
				//@ts-ignore
				// sx={{ width: '100%' }}
				className="table-box"
			>
				<TabPanel value={0} index={0}>
					<CustomReactTable
						id='order-list'
						data={invoiceData}
						loading={loading}
                        noDataText={t("no_invoice_yet")}
						recordsPerPage={recordsPerPage}
						columns={columns}
						totalEntries={totalEntries}
						recordStartIndex={recordStartIndex}
						recordEndIndex={recordEndIndex}
						currentPage={currentPage}
						changePage={(e, page) => setCurrentPage(page)}
                        />
					<DashboardFooter />
				</TabPanel>
			</Box>
             {/* @ts-ignore */}
             {openModal && <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <div style={{position:'relative'}}>
                        <img src="http://simpleicon.com/wp-content/uploads/cross.png" alt="" style={{width:'50px', height: '50px', position:'absolute', right: '10px', top:'-20px', cursor:'pointer'}} onClick={()=>{
                            setOpenModal(false)
                        }}/>
                        <div id="pdf">
                            <div className="invoice-main-div">
                                {invoiceData.length !== 0 &&
                                    invoiceData?.map((data: any) => {
                                        if (data.id === invoiceId) {
                                            return (
                                                <div key={data.id} style={{ margin: "0 5px" }}>
                                                    <div className="invoice-header">
                                                        <img src={growCart} className="invoice-logo" />
                                                    </div>
                                                    <div className="invoice-title">
                                                        <p className="invoice-title-text">{t("invoice")}</p>
                                                    </div>
                                                    <div className="top-invoice-div">
                                                        <div className="date-no-div">
                                                            <div className="invoice-no-div">
                                                                <p className="text-title">
                                                                    Invoice No:
                                                                </p>
                                                                <p className="no-date">
                                                                    {data.attributes.order_number}
                                                                </p>
                                                            </div>
                                                            <div className="invoice-no-div">
                                                                <p className="text-title">{t("date")}: </p>
                                                                <p className="no-date">
                                                                    {moment(
                                                                        new Date(
                                                                            data.attributes?.order_date
                                                                        )
                                                                    ).format("DD/MM/YYYY")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="biller-div">
                                                            <p className="text-title">{t("bill_to")}:</p>
                                                            <p className="cust-name">
                                                                {data.attributes.account.attributes.fullname.trim()}
                                                            </p>
                                                            <p className="cust-addr">
                                                                {data.attributes.delivery_addresses[0].address.trim()}{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0]
                                                                        .address_line_2
                                                                }{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].landmark
                                                                }{" "}
                                                                {data.attributes.delivery_addresses[0].city.trim()}
                                                                ,{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].state
                                                                }
                                                                ,{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].country
                                                                }{" "}
                                                                {
                                                                    data.attributes
                                                                        .delivery_addresses[0].zip_code
                                                                }
                                                                .
                                                            </p>
                                                            <p className="cust-phone">
                                                                +
                                                                {
                                                                    data.attributes.account.attributes
                                                                        .full_phone_number
                                                                }
                                                            </p>
                                                            <p className="cust-email">
                                                                {
                                                                    data.attributes.account.attributes
                                                                        .email
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <table>
                                                        <tr>
                                                            <th>{t("product_description")}</th>
                                                            <th>{t("quantity")}</th>
                                                            <th>{t("price")}</th>
                                                            <th>{t("amount")}</th>
                                                        </tr>
                                                        <Fragment>
                                                            {data.attributes.order_items?.map(
                                                                (item: any) => (
                                                                    <tr key={item.id}>
                                                                        <td className="desc-td">
                                                                            {
                                                                                item.attributes
                                                                                    .catalogue
                                                                                    .attributes.name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {item.attributes.quantity}
                                                                        </td>
                                                                        <td>
                                                                            {item.attributes.unit_price}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.attributes
                                                                                    .total_price
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </Fragment>
                                                        <tr>
                                                            <td colSpan={3} className="total">
                                                                {t("tax")}:
                                                            </td>
                                                            <td>{data.attributes.tax_charges}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3} className="total">
                                                                {t("total")}:
                                                            </td>
                                                            <td>{data.attributes.total}</td>
                                                        </tr>
                                                    </table>
                                                    <div className="invoice-footer">
                                                        <p className="invoice-footer-text">
                                                            *** {t("tahnk")} ***
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                        <Button className='download-invoice' onClick={()=>{
                                downloadInvoice();
                            }}>
                                {t("download_invoice")}
                        </Button>
                    </div> 
                </Modal>}
		</div>
	);
}
//Customizable Area End
