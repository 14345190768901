// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
//@ts-ignore
import _ from "lodash";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config.js");
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

export interface Props { }

module SelectedpaymentOptions {
  export enum Action {
    paymentTypeCOD = configJSON.paymentTypeCOD,
    paymentTypeRazorPay = configJSON.paymentTypeRazorPay
  }
}
interface S {
  activeStep: number,
  loading: boolean,
  cartItems: any,
  orderData: any,
  radioBtn: number;
  name: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  addressList: any;
  selectedAddressId: boolean | string;
  selectedpaymentOption: SelectedpaymentOptions.Action;
  removeItemModal: boolean;
  itemOnfocus: any;
  couponCode: string;
  couponLoader: boolean;
  couponApplied: boolean;
  woCouponOrderData: any;
  updateCart: boolean;
  cartUpdateFlag: boolean;
  razorPayOrderId: null | string
}

interface SS {
  id: any;
}

export default class BuyerProductDetailController extends BlockComponent<Props, S, SS> {
  getCartListApiCallId: string = '';
  updateCartItemApiCallId: string = '';
  removeCartItemApiCallId: string = '';
  getAllAddressListApiCallId: string = '';
  createOrderApiCallId: string = '';
  capturePaymentApiCallId: string = '';
  verifyCouponId: string = '';
  getRazorpayOrderIdApiId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeStep: 1,
      loading: false,
      cartItems: [],
      orderData: {},
      radioBtn: 1,
      name: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      addressList: [],
      selectedAddressId: false,
      selectedpaymentOption: configJSON.paymentTypeCOD,
      removeItemModal: false,
      itemOnfocus: {},
      couponCode: '',
      couponLoader: false,
      couponApplied: false,
      woCouponOrderData: {},
      updateCart: false,
      cartUpdateFlag: false,
      razorPayOrderId: null
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  static contextType?: Context<any> | undefined = AppContext;

  async componentDidMount() {
    //@ts-ignore
    if(this.props.location.state === 'verified'){
        toast.success('Phone number verified successfully!')
    }
    //@ts-ignore    
    this.props.history.push({state:''})
    if (Cookies.get('Login_Token')) {
      this.getCartItemList();
      this.getAllAddressList();
      this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
      // this.loadScript("https://checkout.razorpay.com/v1/checkout.js")
      this.setState({
        loading: true,
        couponApplied: localStorage.getItem('Coupon_Code') ? true : false,
        couponCode: localStorage.getItem('Coupon_Code') || '',
        //@ts-ignore
        orderData: JSON.parse(localStorage.getItem('Orders_Data'))
      })
    }

  }
  
  setActiveStep = (step: number) => {
    this.setState({
      activeStep: step
    })
  }

  verifyCoupon = () => {
    this.setState({
        couponLoader:true
    })
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: Cookies.get('Login_Token')
      };
      
      const data = {
        "apply_coupon_code": this.state.couponCode
      };
  
      const httpBody = {
        data: data
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.verifyCouponId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_shopping_cart/verify_coupon_code"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  removeCoupon = () => {
    this.setState({
        couponApplied: false,
        couponCode: '',
        orderData: this.state.woCouponOrderData
    })
    localStorage.removeItem('Coupon_Code')
    localStorage.removeItem('Orders_Data')
  }

  handleCountinue = () => {
    switch (this.state.activeStep) {
      case 1:
        return this.setActiveStep(this.state.activeStep + 1)

      case 2:

        return this.setActiveStep(this.state.activeStep + 1)

      case 3:
        const foundCartItemIds = this.state.cartItems.map((item: any) => item.id)
        if(this.state.selectedpaymentOption === configJSON.paymentTypeRazorPay) return this.getRazorpayOrderIdApi()
        else return this.createOrder(foundCartItemIds, this.state.selectedAddressId, this.state.selectedpaymentOption)


      default:
        break;
    }
  }

  loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  displayRazorpay = async (razorPayOrderId: string) => {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const result = await axios.post("http://localhost:5000/payment/orders");

    if (!razorPayOrderId) {
      alert("Server error! Are you online?");
      return;
    }

    const { total } = this.state.orderData;
    if (total && razorPayOrderId) {
      const options = {
        key: configJSON.razorpaySecrateKey, // Enter the Key ID generated from the Dashboard
        amount: total.toString(),
        currency: 'INR',
        name: "Soumya Corp.",
        description: "Test Transaction",
        // image: { logo },
        order_id: razorPayOrderId,
        handler: async (response: any) => {
          this.sendCapturePaymentData(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature)
          // const result = await axios.post("http://localhost:5000/payment/success", data);
          // alert(result.data.msg);
        },
        modal:{
          ondismiss:()=>{
            this.closeLoaderIcon();
          }
        },
        
        
        // prefill: {
        //   name: "Soumya Dey",
        //   email: "SoumyaDey@example.com",
        //   contact: "9999999999",
        // },
        // notes: {
        //   address: "Soumya Dey Corporate Office",
        // },
        theme: {
          color: "#2d752f",
        },
      };
      //@ts-ignore
      const paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', async (response: any) => {
        toast.error(`Payment failed for order: ${response?.error?.metadata?.order_id}. reason: ${response?.error?.reason}, ${response?.error?.description} `, { position: toast.POSITION.TOP_RIGHT, autoClose: 10000 })
      });
      paymentObject.open();
    }
    else {
      this.setState({loading: false});
      toast.error(`Can not proceed to payment now please try again later...`, { position: toast.POSITION.TOP_RIGHT, autoClose: 10000 });
    } 
  }

  allowNextStepOrNot = (): boolean => {
    // if (this.state.activeStep === 1) {
    //   return 
    // }
    // else if (this.state.activeStep === 2) {
    //   return this.state.selectedAddressId ? false : true
    // }
    // else return false
    switch (this.state.activeStep) {
      case 1:
        if (_.isEmpty(this.state.cartItems)) return true;
        if(Cookies.get('is_otp_verified') === 'false'){
            return true
        }
        else return this.state.cartItems.some((item: any) => item?.attributes?.out_of_stock == true)
      case 2:
        return this.state.selectedAddressId ? false : true
      default:
        return false
    }
  }
  errorMessageAsPerStep = () => {
    switch (this.state.activeStep) {
      case 1:
            if (_.isEmpty(this.state.cartItems)) return 'Please add some items in cart to continue';
            else if(Cookies.get('is_otp_verified') === 'false'){
                return 'Please verify phone number to continue'
            }
        else return 'Some of your cart-items are out of stock, please remove those items to continue'
      case 2:
        return 'Please select any delivery address to continue'
      case 3:
        return ''
      default:
        break;
    }
  }
  setRadioBtn = (rad: number) => {
    this.setState({ radioBtn: rad });
  };

  getCartItemList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCartItemsListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRazorpayOrderIdApi = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRazorpayOrderIdApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.couponApplied ? `${configJSON.getRazorpayOrderIdApiEndPoint}?applied_coupon_code=${this.state.couponCode}` :
      configJSON.getRazorpayOrderIdApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCartItemQuantity = (cartItemId: any, action: string, quantity: number) => {
    this.setState({
      loading: true,
      updateCart: !this.state.updateCart
    })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };
    const attrs = {
      action: action,
      quantity: quantity
    };

    const data = {
      type: "cart_item",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCartItemQuantityApiEndPoint(cartItemId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  closeLoaderIcon = () =>{
      this.setState({loading:false})
  }
  sendCapturePaymentData = (razorpayOrderId: string, razorpayPaymentId: string, razorpaySignature: string) => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };

    const data = {
      razorpay_order_id: razorpayOrderId,
      razorpay_payment_id: razorpayPaymentId,
      razorpay_signature: razorpaySignature
    };

    const httpBody = {
      data: {
        ...data
      },
      order: {
        delivery_address_id: this.state.selectedAddressId,
        amount: this.state.orderData.total
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.capturePaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.couponApplied ?  `${configJSON.capturePaymentApiEndPoint(':id')}?applied_coupon_code=${this.state.couponCode}` : configJSON.capturePaymentApiEndPoint(':id')
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createOrder = (cartItemIds: any, deliveryAddressId: any, paymentMethod: SelectedpaymentOptions.Action) => {
   this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };

    const data = {
      delivery_address_id: deliveryAddressId,
      payment_method: paymentMethod,
      cart_item_ids: cartItemIds,
      apply_coupon_code: this.state.couponApplied ? this.state.couponCode : null
    };

    const httpBody = {
      order: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeCartItem = (cartItemId: any) => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeCartItemApiEndPoint(cartItemId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllAddressList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAddressListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAddressesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  async componentWillReceiveProps(nextProps: any) {

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.getCartListApiCallId) {

          // * if response is from GET cart list api
          if (responseJson) {
            this.setState({
              cartItems: responseJson?.data,
              orderData: this.state.couponApplied ? this.state.orderData : responseJson?.order_summary,
              woCouponOrderData: responseJson?.order_summary,
              loading: false
            })
          } else {
            this.setState({
              loading: false
            })
          }
        }
        if (apiRequestCallId === this.updateCartItemApiCallId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          this.setState({
            cartUpdateFlag: !this.state.cartUpdateFlag
          })
          this.getCartItemList();
          this.state.couponApplied && this.verifyCoupon();
          toast.success('Item quantity updated..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        if (apiRequestCallId === this.removeCartItemApiCallId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          this.setState({
            cartUpdateFlag: !this.state.cartUpdateFlag
          })
          this.getCartItemList();
          this.setState({ removeItemModal: false });
          toast.success('Item removed from cart..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        if (apiRequestCallId === this.getAllAddressListApiCallId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          // this.getCartItemList();
          if (responseJson?.data) {
            const foundDefaultAdd = responseJson?.data?.find((item: any) => item?.attributes?.is_default)?.id
            this.setState({
              addressList: [...responseJson?.data],
              selectedAddressId: foundDefaultAdd ? foundDefaultAdd : false
            })
          }
          // toast.success('Item removed from cart..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        if(apiRequestCallId === this.verifyCouponId) {
            this.setState({
                couponLoader:false
            })
            if(responseJson?.data){
                this.setState({
                    couponApplied: true,
                    orderData: responseJson?.data
                })
                localStorage.setItem('Coupon_Code', this.state.couponCode)
                localStorage.setItem('Orders_Data', JSON.stringify(this.state.orderData))
            }
            if(responseJson?.message){
                toast.error(responseJson.message)
            }
        }
        if (apiRequestCallId === this.createOrderApiCallId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          // this.getCartItemList();
          if (this.state.selectedpaymentOption === configJSON.paymentTypeRazorPay) {
            
            // this.displayRazorpay(responseJson.data.razorpay_order_id)
            
          } else this.setState({ activeStep: this.state.activeStep + 1, loading: false,cartUpdateFlag: !this.state.cartUpdateFlag });
          toast.success('Order Created Successfully..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        if (apiRequestCallId === this.capturePaymentApiCallId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          // this.getCartItemList();
          this.setState({ activeStep: this.state.activeStep + 1, loading: false,cartUpdateFlag: !this.state.cartUpdateFlag });
          toast.success('Payment received Successfully..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
          toast.success('Order Created Successfully..', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        if (apiRequestCallId === this.getRazorpayOrderIdApiId) {
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          // this.getCartItemList();
          this.setState({ razorPayOrderId: responseJson.data.razorpay_order_id  });
          this.displayRazorpay(responseJson.data.razorpay_order_id)
        }
      }
      else {
        // ? error response
        responseJson?.errors !== "There is no push notification." && toast.error('Something went wrong please try again later..!', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        if (errorReponse === undefined) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    }
  }

  getDisplayName(product: any): string {
    const { language } = this.context.state;
    
    if (language === 'hi' && product.hindi_name) {
      return product.hindi_name;
    } else if (language === 'kn' && product.kannada_name) {
      return product.kannada_name;
    } else {
      return product.name;
    }
  }

}
// Customizable Area End
