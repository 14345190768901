import React from "react";
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle, TextStyle,Platform } from "react-native";
import { normalize } from "react-native-elements";
import LinearGradient from "react-native-linear-gradient";
import { dimensions, Colors, ColorName } from "./constants";
import Typography from "./Typography";
import { FONTS } from "framework/src/AppFonts";
import scale from "framework/src/Scale";

type Props = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  gradientStyle?: StyleProp<ViewStyle>;
  textColor?: ColorName;
  colors?: string[];
}

const RNButton:React.FC<Props> = ({ children, onPress, style, textStyle, textColor, gradientStyle, colors }) => {

  return(
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={onPress}
    >
        <LinearGradient
          start={{ x: 0.2, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={colors || ["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={[styles.loginView, gradientStyle]}
        >
          <Typography
            style={[styles.text,styles.loginText, textStyle]}
            color={textColor || "white"}
          >
            {children}
          </Typography>
        </LinearGradient>
      
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.transparent,
    borderRadius: normalize(10),
    paddingVertical: normalize(10),
    paddingHorizontal: 15,
    marginBottom: dimensions.hp(1.5),
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: FONTS.PoppinsDisplayRegular,
    textTransform: "uppercase"
  },
  loginView: {
    width: scale(335),
    height: scale(55),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(10),
  },
  loginText: {
    fontSize: scale(18),
    fontFamily: FONTS.PoppinsDisplayMedium,
  },
});

export default RNButton;