import { StyleSheet, Platform } from "react-native";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import scale, { verticalScale } from "../../../framework/src/Scale";
import {  dimensions } from "../../../components/src/constants";


export default StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: COLOR_CONST.whiteColor
    },

    orderText: {
      fontSize: scale(25),
      marginTop: verticalScale(10),
      marginLeft: scale(15)
    },

    AboutText: {
      //fontSize: scale(13),
      //fontWeight: "bold",
      color: "#FF8500",
      textAlign: "center",
      marginHorizontal:scale(10)
    },
  
    ServicesText: {
      // fontWeight: "bold",
      // fontSize: scale(13),
      color: "#636363",
      textAlign: "center",
      marginHorizontal:scale(10)
    },
  
    buttonView: {
      flexDirection: "row",
      height: scale(34),
      backgroundColor: "#fff",
      borderBottomColor:COLOR_CONST.lightgreycolor,
      borderBottomWidth:.5,
      paddingLeft:scale(12)
      
    },
  
    editLine: {
      width: scale(80),
      marginTop: verticalScale(9),
      borderWidth: 2,
      backgroundColor: "#FF8500",
      borderColor: "#FF8500",
    },
  
    editBottomLine: {
      width: scale(74),
      marginTop: verticalScale(12),
      borderWidth: 0.5,
      backgroundColor: "#E1E1E1",
      borderColor: "#E1E1E1",
    },
    input: {
      //flex: 1
      marginHorizontal: scale(15),
      width:dimensions.wp(92.5),
      marginVertical: scale(18)
    },
    card: {  
      backgroundColor: 'white',  
      borderRadius: 8,  
      
    },  
    elevation: {  
      shadowColor: '#52006A',  
      elevation: 10,  
    },  
    empty: {
      height: dimensions.hp(10),
      alignItems: "center",
      justifyContent: "center"
    },
  });