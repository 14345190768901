import { ImageSourcePropType } from "react-native";

const facebookIcon = require("./facebookIcon.png");
const cartIcon = require("./Cart.png");
const notificationIcon = require("./Notification.png");
const searchIcon = require("./search.png");
const editIcon = require("./edit.png");
const backIcon = require("./backArrowIcon.png");
const logoutIcon = require("./Logout.jpg");
const filterIcon = require("./filter.png");
const shareIcon = require("./share.png")
const orangeFilterIcon = require("./orange_filter.png")

const icons: Record<string, ImageSourcePropType> = {
  facebook: facebookIcon,
  cart: cartIcon,
  notification: notificationIcon,
  search: searchIcon,
  edit: editIcon,
  back: backIcon,
  logout: logoutIcon,
  filter: filterIcon,
  organgeFilter: orangeFilterIcon,
  share: shareIcon
}

export default icons;