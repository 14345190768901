import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Keyboard } from "react-native";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  route: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFetching: boolean;
  newPassword: string;
  confirmNewPassword: string;
  email:string;
  token: any;
  isInvalidPassword: boolean;
  isInvalidConfirmPassword: boolean;
  isShowPasswordReset: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiResetPasswordCallId: string = "";
  validationApiCallId: string = "";
  toastRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ] 
    this.state = {
        newPassword: '',
        confirmNewPassword: '',
        email:'',
        token: null,
        isInvalidPassword: false,
        isInvalidConfirmPassword: false,
        isShowPasswordReset: false,
        isFetching: false
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log("@@@ Params on New Password screen =========>", this.props.route.params)
    const email = this.props.route.params.userId[1].slice(0, -6);
    const token = this.props.route.params.userId[2].slice(0, -6);
    console.log("@@@ email on new password screen =======>", email, token);
    this.setState({email, token})
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiResetPasswordCallId) {
          this.resetPasswordAPISuccessCallBack(responseJson);
        } 
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiResetPasswordCallId) {
          this.resetPasswordAPIFailureCallBack(responseJson);
        } 
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse)
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzksImV4cCI6MTYzMzc3MDA1Nn0.t5w6tWVzcnLD-J9wTpKf8iVql5JKz3IKrSccg7408S869WeVRzmxWbwFe3UnpWPLf5fLbbY8_mrNN3IQH_JARw';
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressUpdateButton = async () => {
    Keyboard.dismiss();
    if (this.state.newPassword.trim().length === 0) {
      this.setState({ isInvalidPassword: true });
      return;
    }
    if (this.state.confirmNewPassword.trim() !== this.state.newPassword.trim()) {
      this.setState({ isInvalidConfirmPassword: true });
      return;
    }
    this.setState({ isFetching: true });
    let forgotEmail = {
      data: {
          new_password: this.state.newPassword,
          token: this.state.token,
      }
    };
    this.apiResetPasswordCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_forgot_password/passwords`,
      body: forgotEmail,
    });
  }

  resetPasswordAPISuccessCallBack = async (res: any) => {
    console.log("@@@ Forgot Password Successs CallBack =============", res);
    this.setState({ isFetching: false, isShowPasswordReset: true });
    // this.resetStateInfo()
  };

  resetPasswordAPIFailureCallBack = (error: any) => {
    console.log("@@@ Forgot Password Failure CallBack =============", error);
    this.setState({ isFetching: false }, () => {
      let errorMessage = String(this.parseApiErrorResponse(error));
      this.toastRef.show(errorMessage);
    });
  };

  onPressBackToLoginButton = () => {
    this.setState({ isShowPasswordReset: false }, () => {
      this.props.navigation.navigate('EmailAccountLoginBlock')
    });
  }
}
