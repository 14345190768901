export const APP_LOGO = require("../assets/appLogo.png").default;
export const BACK_ARROW = require("../assets/backArrowIcon.png").default;
export const RIGHT_ICON = require("../assets/rightIcon.png").default;
export const MERCHANT_IMAGE = require("../assets/merchantImage.png").default;
export const BUYER_IMAGE = require("../assets/buyerImage.png").default;
export const GO_ARROW_ICON = require("../assets/goArrowIcon.png").default;
export const GOOGLE_ICON = require("../assets/googleIcon.png").default;
export const FACEBOOK_ICON = require("../assets/facebookIcon.png").default;
export const imgPasswordVisible = require("../assets/ic_password_visible.png").default;
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png").default;
export const imgProfileReview = require("../assets/profileReview.jpg").default;
export const imgUploadArrow= require("../assets/uploadArrow.jpg").default;
