import React from "react";
import { ScrollView } from "react-native";

const HorizontalScrollbar: React.FC = ({ children }) => {

  return(
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {children}
    </ScrollView>
  );
}

export default HorizontalScrollbar;