// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { boolean, number } from "yup";
export const configJSON = require("./config.js");
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from "react";
import {AppContext} from "../../../components/src/context/AppContext"

toast.configure()
export interface Props {
  navigation: any;
  id: string;
  history: any
}

interface ProductImage {
  id: string;
  url: string
}

interface ProductData {
 id?: number | string;
 name: string;
 price?: number | string;
 quantity?: string;
 value?: number;
 description?:  string;
 images?: [ProductImage] | null;
 category?: any;
 out_of_stock?: boolean | null
}

interface S {
  progress:boolean;
  activeTab: number;
  showLogoutModal: boolean;
  activeImageIndex: number;
  loading: boolean;
  productData: ProductData;
  relatedProductList: any;
  cartData: any;
  foundItem: any;
  updatingCart: boolean;
  cartUpdateFlag: boolean
}

interface SS {
  id: any;
}

export default class BuyerProductDetailController extends BlockComponent<Props, S, SS> {
	productDetailApiCallId: string = '';
  relatedProductListApiCallId: string = '';
  getCartListApiCallId: string = '';
  addToCartApiCallId: string = '';
  updateCartItemApiCallId: string = '';
  removeCartItemApiCallId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      progress:false,
      activeTab: 1,
      showLogoutModal: false,
      activeImageIndex: 0,
      loading: false,
      productData: {name: ' '},
      relatedProductList: [],
      cartData: [],
      foundItem: {},
      updatingCart: false,
      cartUpdateFlag: false
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  static contextType?: Context<any> | undefined = AppContext;

  async componentDidMount() {
    //@ts-ignore
  const {match: {params: {productId}}} = this.props
   this.getProductDetailsData(productId);
   this.getRelatedProductList(productId);
  {Cookies.get('Login_Token') && this.getCartItemList();} 
  //  this.addItemToCart(productId, quantity);
   this.setState({loading: true})
  }

  setActiveImageIndex = (index: number) => {
    this.setState({activeImageIndex: index})
  }
  checkItemAddedInCart = () => {
    // this.setState({activeImageIndex: index})
    const foundItem = this?.state?.cartData?.find((item: any) => 
    item.attributes.catalogue_id == this.state.productData.id) ? true : false;
    return foundItem
  }
  copyLink = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success('URL coppied...',{position: toast.POSITION.TOP_RIGHT, autoClose:3000})
  }

  getProductDetailsData = (productId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productDetailApiEndPoint + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRelatedProductList = (productId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.relatedProductListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.relatedProductListApiEndPoint + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCartItemList = () => {
    this.setState({
      loading: false,
    });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCartItemsListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addItemToCart = (productId: any, quantity: number) => {
    this.setState({
      updatingCart: true
    })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };
    const attrs = {
      catalogue_id: productId,
      quantity: quantity
    };

    const data = {
      type: "cart_item",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCartItemQuantity = (cartItemId: any, action: string, quantity: number) => {
    this.setState({
      updatingCart: true
    })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };
    const attrs = {
      action: action,
      quantity: quantity
    };

    const data = {
      type: "cart_item",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCartItemQuantityApiEndPoint(cartItemId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeCartItem = (cartItemId: any) => {
    this.setState({
      updatingCart: true
    })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeCartItemApiEndPoint(cartItemId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
			var responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
			if (responseJson && !responseJson.errors && !responseJson.error) {
        // * if got valid response
        if (apiRequestCallId === this.productDetailApiCallId){
          // ? if response is from product detail api
          if (responseJson.data.length === 0) {
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              productData: {...responseJson.data.attributes, id: responseJson.data.id},
              loading: false,
            });
          }
        }
        if (apiRequestCallId === this.relatedProductListApiCallId){
          // * if response is from related product list api
          if (responseJson.data.length === 0) {
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              relatedProductList: [...responseJson.data],
              loading: false,
            });
          }
        }
        if (apiRequestCallId === this.getCartListApiCallId){
          // * if response is from GET cart list api
            this.setState({
              cartData: [...responseJson.data],
              updatingCart: false,
            });
            //@ts-ignore
            const {match: {params: {productId}}} = this.props
           const foundCartItem = this?.state?.cartData?.find((item: any, i: number) => 
           item?.attributes?.catalogue?.id == productId)
           if(foundCartItem) {
            this.setState({
              foundItem: foundCartItem,
            });
           }
           else this.setState({
            foundItem: {},
          });
        }
        if (apiRequestCallId === this.addToCartApiCallId){
          // * if response is from Add item to cart api
          // * getting cart data again when there is any update in cart.
          this.getCartItemList();
            this.setState({
                cartUpdateFlag: !this.state.cartUpdateFlag
              // relatedProductList: [...responseJson.data],
              // updatingCart: false,
            });
        }
        if (apiRequestCallId === this.updateCartItemApiCallId){
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          this.getCartItemList();
            this.setState({
                cartUpdateFlag: !this.state.cartUpdateFlag
              // relatedProductList: [...responseJson.data],
              // updatingCart: false,
            });
        }
        if (apiRequestCallId === this.removeCartItemApiCallId){
          // * if response is from update cart item quantity api.
          // * getting cart data again when there is any update in cart.
          this.getCartItemList();
            this.setState({
                cartUpdateFlag: !this.state.cartUpdateFlag
              // relatedProductList: [...responseJson.data],
              // updatingCart: false,
            });
    toast.success('Item removed from cart..',{position: toast.POSITION.TOP_RIGHT, autoClose:3000})

        }
			} else {
        // ? error response
    // toast.error('Something went wrong please try again later..!',{position: toast.POSITION.TOP_RIGHT, autoClose:3000})
				if (errorReponse === undefined) {
					this.setState({
						updatingCart: false,
					});
				} else {
					this.setState({
						updatingCart: false,
					});
				}
			}
		}
	}

  getLocalizedName(item: any): string {
    if (!item) return '';
    
    const { language } = this.context.state;
    
    if (language === 'hi' && item.hindi_name) {
      console.log("hindi name", item.hindi_name)
      return item.hindi_name;
    } else if (language === 'kn' && item.kannada_name) {
      return item.kannada_name;
    }
    
    return item.name || '';
  }

  getLocalizedDescription(item: any): string {
    if (!item) return '';
    
    const { language } = this.context.state;
    
    if (language === 'hi' && item.hindi_description) {
      return item.hindi_description;
    } else if (language === 'kn' && item.kannada_description) {
      return item.kannada_description;
    }
    
    return item.description || '';
  }

}
// Customizable Area End