import React from "react";
import { normalize } from "react-native-elements";
import { View, TouchableOpacity, Image, StyleSheet, StyleProp, ImageStyle } from "react-native";

import icons from "./images";
import { APP_LOGO } from "../../blocks/email-account-registration/src/assets";
import { Colors, dimensions } from "./constants";
import Typography from "./Typography";

type Props = {
  type?: string;
  title?: string;
  backIcon?: boolean;
  navigation?: {
    goBack: Function;
    navigate: (s: string) => void;
  };
  cartCount?: number;
  rightIcon?: string;
  rightIconAction?: () => void;
  unread?: boolean;
  iconStyle?: StyleProp<ImageStyle>;
}

const DashBoardView: React.FC<Props> = ({ navigation, cartCount, unread }) => {

  const viewNotifications = () => navigation?.navigate?.("Notifications");
  const viewCart = () => navigation?.navigate?.("Cart");

  return(
    <>
      <View style={styles.fullFlex}>
        <Image source={APP_LOGO} style={styles.logo} />
      </View>
      <View style={styles.row}>
        <TouchableOpacity
          onPress={viewNotifications}
          style={styles.positionedIcon}
        >
          <Image source={icons.notification} style={styles.navIcon} />
          {unread && <View style={styles.unread} />}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={viewCart}
          style={styles.positionedIcon}
        >
          <Image source={icons.cart} style={styles.navIcon} />
          {(cartCount! > 0) ? (
            <View style={styles.count}>
              <Typography size={8} color="white">{cartCount}</Typography>
            </View>
          ): null}
        </TouchableOpacity>
      </View>
    </>
  );
}

const DefaultView: React.FC<Props> = ({
  backIcon,
  title,
  navigation,
  rightIcon,
  rightIconAction,
  iconStyle
}) => {
  
  return(
    <>
      <View style={styles.row}>
        {backIcon? (
          <TouchableOpacity 
          onPress={()=> navigation?.goBack}
          >
            <Image source={icons.back} style={[styles.navIcon, styles.marginRight]} />
          </TouchableOpacity>
        ): null}
        {(title && title.length > 0)? (
          <Typography size={18}>{title}</Typography>
        ): null}
        <View style={{flex: 1}} />
        {(rightIcon && rightIcon.length > 0)? (
          <TouchableOpacity onPress={rightIconAction}>
            <Image source={icons[rightIcon]} style={[styles.navIcon, iconStyle]} />
          </TouchableOpacity>
        ): null}
      </View>
    </>
  );
}

const Header: React.FC<Props> = (props) => {

  const renderHeader = () => {
    switch(props.type){
      case "home":
        return <DashBoardView {...props} />;
      default:
        return <DefaultView {...props} />;
    }
  }

  return(
    <View style={[
      styles.container,
      styles.rowBetween,
      props.backIcon? styles.bottomBorder: {}
    ]}>
      {renderHeader()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: normalize(40),
    width: "100%",
    paddingHorizontal: dimensions.wp(5)
  },
  logo: {
    height: normalize(25),
    aspectRatio: 4.5
  },
  navIcon: {
    height: dimensions.wp(5),
    width: dimensions.wp(5),
    resizeMode: "contain",
    marginLeft: dimensions.wp(3)
  },
  rowBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  fullFlex: {
    flex: 1
  },
  bottomBorder: {
    borderBottomColor: `${Colors.black}44`,
    borderBottomWidth: 1
  },
  marginRight: {
    marginRight: dimensions.wp(3),
    marginLeft: 0
  },
  positionedIcon: {
    position: "relative"
  },
  count: {
    position: "absolute",
    height: dimensions.hp(2),
    width: dimensions.hp(2),
    borderRadius: dimensions.hp(1),
    backgroundColor: Colors.orange,
    alignItems: "center",
    justifyContent: "center",
    top: -10,
    right: -10
  },
  unread: {
    position: "absolute",
    height: dimensions.hp(1.4),
    width: dimensions.hp(1.4),
    borderRadius: dimensions.hp(.70),
    backgroundColor: Colors.orange,
    top: 0,
    right: 0,
    borderColor: Colors.white,
    borderWidth: 2
  }
});

export default Header;