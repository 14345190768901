import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  FlatList,
  ScrollView,
  TouchableWithoutFeedback,
  Modal,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import * as IMG_CONST from "./assets";
import scale, { verticalScale } from "../../../framework/src/Scale";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import OrderDetailViewController, {
  Props,
  configJSON
} from "./OrderDetailViewController";

export default class OrderDetailView extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  renderHeader = () => {
    return (
      <View>
        <View style={{ flexDirection: "row", marginTop: verticalScale(30), justifyContent: "space-between" }}>
          <View style={styles.signupTextContainer}>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.backArrowButton}
              onPress={() => this.props.navigation.goBack()}
            >
              <Image style={styles.backIcon} source={IMG_CONST.BACK_ARROW} />
            </TouchableOpacity>
            <Text style={styles.signupText}>Order Details</Text>
          </View>
          <View style={{ width: 30, height: 30, backgroundColor: "red", marginRight: scale(20) }} />
        </View>
        <View style={styles.horizontalLine} />
      </View>
    );
  };

  orderIdAndDateView = () => {
    return (
      <View style={styles.orderIdAndDateMainView}>
        <View style={styles.orderIdAndDateView}>
          <Text style={{ color: "gray" }}>Order Id</Text>
          <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>#2831767823</Text>
        </View>
        <View style={styles.orderIdAndDateView}>
          <Text style={{ color: "gray" }}>Order Date</Text>
          <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>18-july-2021</Text>
        </View>
        <View style={styles.orderIdAndDateView}>
          <Text style={{ color: "gray" }}>OrderId</Text>
          <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>273289232</Text>
        </View>
        <View style={styles.orderIdAndDateView}>
          <Text style={{ color: "gray" }}>Order Total</Text>
          <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>$ 510</Text>
        </View>
      </View>
    )
  }

  OrderItem = (item: any, index: any) => {
    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: verticalScale(15) }}>
          <View style={{ width: scale(80), height: scale(80), borderRadius: scale(10), backgroundColor: "red" }}></View>
          <View style={{ width: scale(230), height: scale(80), borderRadius: scale(10), justifyContent: "space-evenly" }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Text style={{ fontSize: scale(15), fontFamily: FONTS.PoppinsDisplayRegular }}>Green Fresh Capcicum</Text>
              <Text style={{ fontSize: scale(15), fontFamily: FONTS.PoppinsDisplayRegular }}>$ 150</Text>
            </View>
            <Text style={{ fontSize: scale(15), fontFamily: FONTS.PoppinsDisplayRegular }}>beans Farm</Text>
            <Text style={{ fontSize: scale(15), fontFamily: FONTS.PoppinsDisplayRegular }}>QTY : 3</Text>
          </View>
        </View>
        <View style={{ width: scale(330), height: scale(1), borderWidth: 0.3, borderColor: "#e8e6e6", alignSelf: 'center', marginTop: verticalScale(15) }} />
      </View>
    )
  }

  orderItemView = () => {
    return (
      <View>
        <Text style={styles.itemTextstyle}>Items (06)</Text>
        <View style={styles.showItemsMainView}>
          <Text style={styles.estimatedDeliveryText}>Estimated Delivery Date: July 25,2021</Text>
          <FlatList
            data={this.state.OrderItem}
            renderItem={({ item, index }) => this.OrderItem(item, index)}
          />
        </View>
      </View>
    )
  }

  shippingAddressView = () => {
    return (
      <View>
        <Text style={styles.shippingAddressText}>Shipping Address</Text>
        <View style={styles.shippingAddressMainView}>
          <View style={styles.shippingAddressView}>
            <Text style={{ color: "#595858" }}>Gr Flr, 98/2, Neelkanth,</Text>
            <Text style={{ color: "#595858" }}>Below Flyover, New Marine Line,</Text>
            <Text style={{ color: "#595858" }}>Mumbai - 400002,</Text>
            <Text style={{ color: "#595858" }}>Maharashtra, India</Text>
          </View>
        </View>
      </View>
    )
  }

  paymentInformationView = () => {
    return (
      <View>
        <Text style={styles.paymentInformationText}>Payment Information</Text>
        <View style={styles.paymentInformationMainView}>
          <View style={{ marginLeft: scale(10) }}>
            <Text style={styles.paymentMethodText}>Payment Method</Text>
            <Text style={styles.creditDebitCardText}>Credit / Debit Card</Text>
          </View>
          <View style={styles.paymentInformationViewLine} />
          <View style={{ marginLeft: scale(10) }}>
            <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>Billing Address</Text>
            <Text style={{ color: "#595858", marginTop: verticalScale(5) }}>Gr Flr, 98/2, Neelkanth,</Text>
            <Text style={{ color: "#595858" }}>Below Flyover, New Marine Line,</Text>
            <Text style={{ color: "#595858" }}>Mumbai - 400002,</Text>
            <Text style={{ color: "#595858" }}>Maharashtra, India</Text>
          </View>
        </View>
      </View>
    )
  }

  orderSummaryView = () => {
    return (
      <View style={{ marginBottom: scale(100) }}>
        <Text style={styles.orderSummaryText}>Order Summary</Text>
        <View style={styles.orderSummaryMainView}>
          <View style={{ height: scale(150), justifyContent: "space-evenly" }}>
            <View style={styles.orderSummaryDataView}>
              <Text style={{ color: "gray" }}>Items</Text>
              <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>$ 450</Text>
            </View>
            <View style={styles.orderSummaryDataView}>
              <Text style={{ color: "gray" }}>Shipping Charges</Text>
              <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>$ 50</Text>
            </View>
            <View style={styles.orderSummaryDataView}>
              <Text style={{ color: "gray" }}>Tax</Text>
              <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>$ 10</Text>
            </View>
            <View style={styles.orderSummaryDataView}>
              <Text style={{ color: "gray" }}>Total</Text>
              <Text style={{ fontFamily: FONTS.PoppinsDisplaySemiBold }}>$ 510</Text>
            </View>
          </View>
          <View style={styles.paymentInformationViewLine} />
          <View style={styles.orderSummaryDataView}>
            <Text style={{ fontSize: scale(20), fontFamily: FONTS.PoppinsDisplayRegular }}>Order Total</Text>
            <Text style={{ fontFamily: FONTS.PoppinsDisplayBold, fontSize: scale(20) }}>$ 510</Text>
          </View>
        </View>
      </View>
    )
  }

  renderSubmitButton = () => {
    return (
      <View
        style={styles.loginButtonContainer}
      >
        <LinearGradient
          start={{ x: 0.3, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={styles.loginView}
        >
          <View style={{ width: scale(340), height: scale(50), borderWidth: 1, borderColor: "#fff", borderRadius: scale(10), flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={{ color: "#fff", fontFamily: FONTS.PoppinsDisplayRegular, marginLeft: scale(15), fontSize: scale(15) }}>Shipped</Text>
            <TouchableOpacity onPress={() => this.setState({ modalVisible: true })} style={{ width: 20, height: 20, backgroundColor: "red", marginRight: scale(15) }}></TouchableOpacity>
          </View>
        </LinearGradient>
      </View>
    );
  };

  renderChatPopupModal = () => {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.modalVisible}
        onRequestClose={() => {
          this.setState({ modalVisible: false });
        }}
      >
          <View style={{ flex: 1, backgroundColor: "#000000AA", justifyContent: "flex-end" }}>
            <View style={{ height: "80%", backgroundColor: '#fff' }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: verticalScale(10) }}>
                <Text style={{ fontSize: scale(18), fontFamily: FONTS.PoppinsDisplaySemiBold, marginLeft: scale(10) }}>My Order Duration Filter</Text>
                <TouchableOpacity onPress={() => this.setState({ modalVisible: false })}>
                <Image source={IMG_CONST.CLOSE} style={{ width: scale(15), height: scale(15), marginRight: scale(10) }} />
                </TouchableOpacity>
              </View>
              <View style={{marginTop:verticalScale(10)}}>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>Processing</Text>
              </View>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>On Hold</Text>
              </View>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>Completed</Text>
              </View>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>Cancelled</Text>
              </View>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>Shipped</Text>
              </View>
              <View style={{ width: scale(350), height: scale(60),marginTop:verticalScale(5), borderWidth: 1.5, borderColor: "#e8e6e6", borderRadius: scale(10), alignSelf: "center",alignItems:"center" ,flexDirection:"row"}}>
                <View style={{ width: scale(25), height: scale(25), borderRadius: scale(15),marginLeft:scale(15), borderWidth: 1, borderColor: "#e8e6e6" }} />
                <Text style={{marginLeft:scale(10)}}>Failed</Text>
              </View>
              </View>
              {this.bottonView()}
            </View>
          </View>
      </Modal>
    );
  };

  bottonView = () => {
    return(
      <View style={{flexDirection:"row",justifyContent:"space-around",marginTop:verticalScale(15)}}>
        <TouchableOpacity style={{width:scale(160),height:scale(60),borderWidth:1,borderColor:"#e8e6e6",borderRadius:scale(15),justifyContent:"center",alignItems:"center"}}>
          <Text style={{color:"#000",fontFamily:FONTS.PoppinsDisplayRegular}}>CANCEL</Text>
        </TouchableOpacity>
        <LinearGradient
          start={{ x: 0.3, y: 0 }}
          end={{ x: 0.6, y: 0 }}
          colors={["#3c8641", "#5f9f4f", "#86bb5e"]}
          style={{width:scale(160),height:scale(60),borderWidth:1,borderColor:"#e8e6e6",borderRadius:scale(15),justifyContent:"center",alignItems:"center"}}
        >
        <TouchableOpacity style={{width:scale(160),height:scale(60),borderWidth:1,borderColor:"#e8e6e6",borderRadius:scale(15),justifyContent:"center",alignItems:"center"}}>
        <Text style={{color:"#fff",fontFamily:FONTS.PoppinsDisplaySemiBold}}>SAVE</Text>
        </TouchableOpacity>
        </LinearGradient>
      </View>
    )
  }


  render() {
    return (
      // Customizable Area Start
      <View style={styles.container}>
        {this.renderHeader()}
        <ScrollView >
          {this.orderIdAndDateView()}
          {this.orderItemView()}
          {this.shippingAddressView()}
          {this.paymentInformationView()}
          {this.orderSummaryView()}
        </ScrollView>
        {this.renderSubmitButton()}
        {this.renderChatPopupModal()}
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff"
  },

  loginButtonContainer: {
    position: "absolute",
    bottom: verticalScale(0),
    alignSelf: 'center'
  },

  loginView: {
    width: scale(375),
    height: scale(80),
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: scale(10),
    borderTopRightRadius: scale(10)
  },

  loginText: {
    fontSize: scale(15),
    color: COLOR_CONST.whiteColor,
    fontFamily: FONTS.PoppinsDisplayMedium
  },

  signupTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: scale(20),
  },

  backArrowButton: {
    width: scale(30),
    height: scale(35),
  },

  backIcon: {
    width: scale(10),
    height: scale(20),
  },

  signupText: {
    fontSize: scale(20),
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    color: COLOR_CONST.blackColor,
    marginBottom: verticalScale(15),
  },

  horizontalLine: {
    width: scale(375),
    height: scale(2),
    backgroundColor: COLOR_CONST.borderColor,
  },

  orderIdAndDateMainView: {
    width: scale(350),
    height: scale(150),
    borderColor: "#e8e6e6",
    borderWidth: 1.5,
    borderRadius: scale(10),
    alignSelf: "center",
    marginTop: verticalScale(10),
    justifyContent: "space-evenly"
  },

  orderIdAndDateView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: scale(10),
    marginRight: scale(10)
  },

  orderSummaryDataView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: scale(10),
    marginRight: scale(10)
  },

  itemTextstyle: {
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    marginTop: verticalScale(15),
    marginLeft: scale(13)
  },

  showItemsMainView: {
    width: scale(350),
    height: scale(260),
    borderColor: "#e8e6e6",
    borderWidth: 1.5,
    borderRadius: scale(7),
    alignSelf: "center",
    marginTop: verticalScale(10),
  },

  estimatedDeliveryText: {
    fontSize: scale(15),
    color: "gray",
    marginLeft: scale(13),
    marginTop: verticalScale(10)
  },

  shippingAddressText: {
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    marginLeft: scale(13),
    marginTop: verticalScale(15)
  },

  orderSummaryText: {
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    marginLeft: scale(13),
    marginTop: verticalScale(15)
  },

  paymentInformationText: {
    fontFamily: FONTS.PoppinsDisplaySemiBold,
    marginLeft: scale(13),
    marginTop: verticalScale(15)
  },

  shippingAddressMainView: {
    width: scale(350),
    height: scale(130),
    borderColor: "#e8e6e6",
    borderWidth: 1.5,
    borderRadius: scale(7),
    alignSelf: "center",
    marginTop: verticalScale(10),
    justifyContent: "center"
  },

  orderSummaryMainView: {
    width: scale(350),
    height: scale(220),
    borderColor: "#e8e6e6",
    borderWidth: 1.5,
    borderRadius: scale(7),
    alignSelf: "center",
    marginTop: verticalScale(10),
    justifyContent: "space-evenly"
  },

  paymentInformationMainView: {
    width: scale(350),
    height: scale(220),
    borderColor: "#e8e6e6",
    borderWidth: 1.5,
    borderRadius: scale(7),
    alignSelf: "center",
    marginTop: verticalScale(10),
    justifyContent: "space-evenly"

  },

  shippingAddressView: {
    height: scale(100),
    justifyContent: 'space-around',
    marginLeft: scale(10)
  },

  paymentMethodText: {
    fontFamily: FONTS.PoppinsDisplaySemiBold
  },

  creditDebitCardText: {
    fontFamily: FONTS.PoppinsDisplayRegular, color: "#595858"
  },

  paymentInformationViewLine: {
    width: scale(330),
    height: scale(1),
    borderWidth: 0.3,
    borderColor: "#e8e6e6",
    alignSelf: 'center',
  }

});
// Customizable Area End
