import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  View,
  Modal,
  Platform,
  SafeAreaView
} from "react-native";
import COLOR_CONST, { FONTS } from "../../../framework/src/AppFonts";
import scale, { verticalScale } from "../../../framework/src/Scale";
// import ContactusController, { Props } from "./ContactusController";
import { Formik } from "formik";
import * as yup from "yup";
import { translate } from "framework/src/Translation";
import {  dimensions } from "../../../components/src/constants";
import Typography from "../../../components/src/Typography";
import Header from "../../../components/src/RNHeader";
import RNInput from "../../../components/src/RNInput";
import RNButton from "../../../components/src/RNButton";
import { AppLoader } from "../../../components/src/Index";


import ContactusController, { Props } from "./ContactusController";
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    return (
      <SafeAreaView style={styles.container}>
        <Header title={translate("contact_us")} backIcon navigation={this.props.navigation} />
        <View style={styles.wrapper}>
        <Formik
            enableReinitialize
            initialValues={{
              email: this.state.email || "",
              comments: this.state.comments || ""
            }}
            onSubmit={()=> {}}
          >
            {({ values, errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched }) => (
              <View style={styles.formWrapper}>
              <View style={{marginBottom:scale(10)}}>
                <Typography mb={2} size={16} style={{fontWeight:"100"}}  >{translate("email")}</Typography>
                <RNInput
                  value={values.email}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  keyboardType="email-address"
                  errorText={errors.email}
                  touched={touched.email}
                />
              </View>
              <View>
                <Typography mb={2} size={16} style={{fontWeight:"100"}} >{translate("message")}</Typography>
                <RNInput
                  value={values.comments}
                  onChange={handleChange("comments")}
                  onBlur={handleBlur("comments")}
                  keyboardType="default"
                  errorText={errors.comments}
                  touched={touched.comments}
                  multiline={true}
                  containerStyle={{height:scale(150)}}
                  
                />
              </View>
              <View style={{ flexGrow: 1 }} />
              <RNButton onPress={handleSubmit}>{translate("send")}</RNButton>
            </View>
              )}
          </Formik>
          </View>
      </SafeAreaView>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
    marginBottom: 30
  },
  webviewStyle: {
    height: 200
  },
  infoText: {
    fontSize: 16,
    marginVertical: 4
  },
  labelText: {
    fontWeight: "bold"
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "blue"
  },
  viewBtnText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16
  },
  addBtn: {
    backgroundColor: "blue",
    marginBottom: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end"
  },
  wrapper: {
    padding: dimensions.wp(5),
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff"
  },
  formWrapper: {
    height: dimensions.hp(75)
  }
});
// Customizable Area End
