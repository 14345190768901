import React, { useEffect, useMemo, useCallback, memo } from 'react';
import { Typography } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { translate } from '../../../framework/src/Translation';
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';

interface ProductProps {
  data: {
    id: string;
    attributes: {
      id?: string;
      name: string;
      value: string;
      quantity: string;
      price: number;
      sold_quantity?: number;
      images?: Array<{ url: string }>;
      hindi_name: string;
      kannada_name: string;
      hindi_description: string;
      kannada_description: string;
    };
  };
  isRecentlySold?: boolean;
  onProductClick: (id: string) => void;
  prodSkel: string;
}

const getLocalizedName = (
  language: string,
  name: string,
  hindiName: string,
  kannadaName: string
): string => {
  switch (language) {
    case 'hi':
      return hindiName || name;
    case 'kn':
      return kannadaName || name;
    default:
      return name;
  }
};

const ProductImage = memo(({ imageUrl, prodSkel }: { imageUrl?: string, prodSkel: string }) => (
  <LazyLoadImage
    src={imageUrl}
    alt=""
    height={250}
    width={250}
    style={{
      borderRadius: "12px",
    }}
    effect="opacity"
    placeholderSrc={prodSkel}
  />
));

const ProductDetails = memo(({ 
  name, 
  value, 
  quantity, 
  price, 
  soldQuantity, 
  isRecentlySold 
}: { 
  name: string;
  value: string;
  quantity: string;
  price: number;
  soldQuantity?: number;
  isRecentlySold?: boolean;
}) => (
  <div>
    <Typography className="card-prod-name">
      {name}
    </Typography>
    <Typography className="card-prod-quan">
      {value} {quantity}
    </Typography>
    {isRecentlySold && (
      <Typography>
        QTY: {soldQuantity}
      </Typography>
    )}
    <Typography className="card-prod-price">
      ₹{price}
    </Typography>
  </div>
));

const ProductCard: React.FC<ProductProps> = memo(({ 
  data, 
  isRecentlySold, 
  onProductClick,
  prodSkel 
}) => {
  const { language } = useAppState();
  const refresh = useRefresh();
  
  useEffect(() => {
    refresh();
  }, [language]);

  const localizedName = useMemo(() => 
    getLocalizedName(
      language,
      data.attributes.name,
      data.attributes.hindi_name,
      data.attributes.kannada_name
    ),
    [language, data.attributes.name, data.attributes.hindi_name, data.attributes.kannada_name]
  );

  const handleClick = useCallback(() => {
    onProductClick(data.attributes.id || data.id);
  }, [data.attributes.id, data.id, onProductClick]);

  return (
    <div
      className="product-card"
      onClick={handleClick}
    >
      <ProductImage 
        imageUrl={data.attributes.images?.[0]?.url}
        prodSkel={prodSkel}
      />
      <ProductDetails
        name={localizedName}
        value={data.attributes.value}
        quantity={data.attributes.quantity}
        price={data.attributes.price}
        soldQuantity={data.attributes.sold_quantity}
        isRecentlySold={isRecentlySold}
      />
    </div>
  );
});

// Add display name for better debugging
ProductCard.displayName = 'ProductCard';
ProductImage.displayName = 'ProductImage';
ProductDetails.displayName = 'ProductDetails';

export default ProductCard;