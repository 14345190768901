// src/mocks/googleSignInMock.js

const GoogleSignin = {
    configure: () => {
      console.log('GoogleSignin.configure() called on the web (mock implementation)');
    },
    signIn: () => {
      console.log('GoogleSignin.signIn() called on the web (mock implementation)');
      return Promise.resolve({
        user: 'Mock User',
        email: 'mockuser@gmail.com',
      });
    },
    signOut: () => {
      console.log('GoogleSignin.signOut() called on the web (mock implementation)');
      return Promise.resolve();
    },
  };
  
  const GoogleSigninButton = () => {
    console.log('GoogleSigninButton rendered on the web (mock implementation)');
    return <div>Google Sign-In Button (Web)</div>;
  };

  const statusCodes = {
    SIGN_IN_CANCELLED: 'SIGN_IN_CANCELLED',
    IN_PROGRESS: 'IN_PROGRESS',
    PLAY_SERVICES_NOT_AVAILABLE: 'PLAY_SERVICES_NOT_AVAILABLE',
    SIGN_IN_REQUIRED: 'SIGN_IN_REQUIRED',
  };
  
  export { GoogleSignin, GoogleSigninButton, statusCodes };
  