import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BackHandler } from 'react-native';
import * as Validators from '../../../framework/src/Validators';
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFetching: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReviewAndApprovalController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  backHandler: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ] 
    this.state = {
      isFetching: false
      
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    // Customizable Area End
  }

  // Customizable Area Start

  handleBackPress: any = async () => {
    return true;
  }

  async componentWillUnmount() {
    // super.componentWillUnmount();
    // this._unsubscribe.remove();
    // Customizable Area Start
    this.backHandler.remove();
    // Customizable Area Start 
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("@@@ API MESSAGE  LogIn Form =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        console.log("@@@ if LogIn Form Page  =================");
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          // this.getLogInSuccesscallBack(responseJson);
        } 
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          // this.getLogInFailureCallBack(responseJson);
        } 
      } else if (errorReponse) {
        console.log("@@@ API fail====", errorReponse)
        this.setState({ isFetching: false });
      }
    }
    // Customizable Area End
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzksImV4cCI6MTYzMzc3MDA1Nn0.t5w6tWVzcnLD-J9wTpKf8iVql5JKz3IKrSccg7408S869WeVRzmxWbwFe3UnpWPLf5fLbbY8_mrNN3IQH_JARw';
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    console.log("@@@ Login API form====", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressBackButton = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

}
