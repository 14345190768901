//Customizable Area Start
import React, { useState, useRef, useEffect } from "react";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from "react-select";
const configURL = require('../../../framework/src/config');
import { configJSON } from "./ProductsWeb.web";
import axios from "axios";
const closeIcon = require('../../../components/src/close.png').default
//@ts-ignore
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { imgUploadArrow } from "../../email-account-registration/src/assets";
import { translate as t } from "framework/src/Translation";
import { useAppState, useRefresh } from '../../../components/src/context/AppContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }),
);

export default function AddEditProduct(props: any) {
    const classes = useStyles();
    const hiddenFileInput = useRef(null)
    const idRef = useRef<any>(null)

    const { addProd, setAddProd, editProd, setEditProd, rowInfo, updateProdList, setUpdateProdList, allCategories, setAllCategories } = props;

    const [name, setName] = useState('')
    const [hindiName, setHindiName] = useState('')
    const [kannadaName, setKannadaName] = useState('')
    const [category, setCategory] = useState<any>()
    const [quantity, setQuantity] = useState<any>()
    const [value, setValue] = useState<any>(null)
    const [price, setPrice] = useState<any>(null)
    const [description, setDescription] = useState<any>('')
    const [hindiDescription, setHindiDescription] = useState('')
    const [kannadaDescription, setKannadaDescription] = useState('')
    const [selectedImgs, setSelectedImgs] = useState<any>([])
    const [updatedImgs, setUpdatedImgs] = useState<any>([])
    const [loader, setLoader] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)
    const [deletedImageIds, setDeletedImageIds] = useState<any>([])
    const [imgIDs, setImgIDs] = useState<any>([])
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { language } = useAppState();
    const refresh = useRefresh();
    const options = [
        { value: 'kg', label: 'kg' },
        { value: 'gm', label: 'gm' },
        { value: 'Seeds', label: 'Seeds' }
    ]
    const handleImgUpload = (e: any) => {
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                if (editProd) {
                    setUpdatedImgs([...updatedImgs, { id: selectedImgs.length + 1, src: reader.result }])
                    setSelectedImgs([...selectedImgs, { id: selectedImgs.length + 1, src: reader.result }])
                }
                else {
                    setSelectedImgs([...selectedImgs, { id: selectedImgs.length + 1, src: reader.result }])
                }
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const handleRemoveImg = (img: any) => {
        if (imgIDs.includes(img.id)) {
            setDeletedImageIds([...deletedImageIds, img.id])
            const updatedImgIds = imgIDs.filter((id: any) => {
                return img.id !== id
            })
            setImgIDs(updatedImgIds)
            const updatedImg = selectedImgs.filter((imgs: any) => {
                return img.id !== imgs.id
            })
            setSelectedImgs(updatedImg)
        }
        else {
            const updatedAllImg = selectedImgs.filter((imgs: any) => {
                return img.id !== imgs.id
            })
            setSelectedImgs(updatedAllImg)
            const updatedNewImg = updatedImgs.filter((imgs: any) => {
                return img.id !== imgs.id
            })
            setUpdatedImgs(updatedNewImg)
        }
    }

    const handleAddProduct = async () => {
        if (!category || !name || !quantity || !value || !price || selectedImgs.length === 0 || !description) {
            toast.warning('Please enter all required fields!', { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
        }
        else {
            setSaveLoader(true)
            var imgArr: any = []
            if (editProd) {
                for (let i = 0; i < updatedImgs.length; i++) {
                    imgArr.push(updatedImgs[i].src.substr(23))
                }
            }
            if (addProd) {
                for (let i = 0; i < selectedImgs.length; i++) {
                    imgArr.push(selectedImgs[i].src.substr(23))
                }
            }
            let data = {
                "catalogues": {
                    "category_id": category.id,
                    "name": name,
                    "hindi_name": hindiName,
                    "kannada_name": kannadaName,
                    "quantity": quantity.value,
                    "value": value,
                    "price": price,
                    "description": description,
                    "hindi_description": hindiDescription,
                    "kannada_description": kannadaDescription
                },
                "image": {
                    "images": imgArr
                }
            };

            if (addProd) {
                axios.post(`${configURL.baseURL}/${configJSON.productApiEndPoint}`, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        "token": Cookies.get('Login_Token')
                    }
                })
                    .then(res => {
                        setSaveLoader(false)
                        setAddProd(false)
                        setUpdateProdList(!updateProdList)
                        toast.success('Product added successfully', { autoClose: 2000 })
                    })
                    .catch((err: any) => {
                        try {
                            setSaveLoader(false)
                            if (err?.response?.data?.errors[0]?.token) {
                                toast.error(err?.response?.data?.errors[0].token, { position: toast.POSITION.BOTTOM_RIGHT })
                            } else if (err?.message === 'Request failed with status code 500') {
                                toast.error(err?.message, { position: toast.POSITION.BOTTOM_RIGHT })
                            } else if (err?.message === 'Request failed with status code 400') {
                                toast.error(err?.message, { position: toast.POSITION.BOTTOM_RIGHT })
                            }
                        } catch (error) {

                        }
                    })
            }
            if (editProd) {
                let params = ''
                for (let i = 0; i < deletedImageIds.length; i++) {
                    let s = `delete_image[]=${deletedImageIds[i]}&`
                    params += s;
                }
                axios.patch(`${configURL.baseURL}/${configJSON.productApiEndPoint}/${rowInfo.id}?${params.slice(0, -1)}`, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        "token": Cookies.get('Login_Token')
                    }
                })
                    .then(res => {
                        setSaveLoader(false)
                        setEditProd(false)
                        setUpdateProdList(!updateProdList)
                        toast.success('Product updated successfully', { autoClose: 2000 })
                    })
                    .catch((err: any) => {
                        setSaveLoader(false)
                        try {
                            if (err?.response?.data?.errors[0]?.token) {
                                toast.error(err?.response?.data?.errors[0].token, { position: toast.POSITION.BOTTOM_RIGHT })
                            } else if (err?.message === 'Request failed with status code 500') {
                                toast.error(err?.message, { position: toast.POSITION.BOTTOM_RIGHT })
                            } else if (err?.message === 'Request failed with status code 400') {
                                toast.error(err?.message, { position: toast.POSITION.BOTTOM_RIGHT })
                            }
                        } catch (error) {

                        }
                    })
            }
        }
    }

    useEffect(() => {
        if (editProd) {
            let data = rowInfo.attributes;
            let imgArr: any = []
            let imgids: any = []
            setName(data.name)
            setHindiName(data.hindi_name || '')
            setKannadaName(data.kannada_name || '')
            setQuantity({ value: data.quantity, label: data.quantity })
            setCategory({ id: data.category.id, value: data.category.name, label: data.category.name })
            setValue(data.value)
            setPrice(data.price)
            setDescription(data.description)
            setHindiDescription(data.hindi_description || '')
            setKannadaDescription(data.kannada_description || '')
            data.images.map((img: any) => {
                imgArr.push({ id: img.id, src: img.url })
                imgids.push(img.id)
            })
            setSelectedImgs(imgArr)
            setImgIDs(imgids)
        }
        setLoader(false)
    }, [])

    useEffect(() => {
        refresh();
      }, [language]);

    return (
        (!loader ? <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                <DialogTitle id="form-dialog-title">
                    <Typography variant={mobile ? 'subtitle2' : "h5"} style={{ color: 'black', fontFamily: 'Poppins', fontWeight: 600 }}>
                        {addProd ? 'Add new product' : 'Edit product'}
                    </Typography>
                </DialogTitle>
                <div className="detailsCloseIcon" onClick={() => { setAddProd(false); setEditProd(false) }}>
                    <img src={closeIcon} className="img-product-close" alt="" />
                </div>
            </div>
            <DialogContent>
                <form className={classes.root} style={{ display: 'flex', flexDirection: 'column', width: "100%" }} noValidate={false} autoComplete="off">
                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("product_category")}</Typography>
                        <Select
                            id="prodInput"
                            classNamePrefix="Select"
                            isSearchable={true}
                            name="category"
                            options={allCategories}
                            placeholder={t("product_category")}
                            //@ts-ignore
                            value={category}
                            onChange={(value: any) => { setCategory(value) }}
                        />
                    </div>
                    <div style={{ width: '100%' }} >
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("product_name")} (English)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Product Name in English"
                            InputLabelProps={{ style: { color: 'hsl(0deg 0% 50%) !important' } }}
                            variant="outlined"
                            value={name}
                            onChange={(e) => { e.target.value.length <= 100 && setName(e.target.value) }}
                        />
                    </div>
                    <div style={{ width: '100%' }} >
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("product_name")} (Hindi)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Product Name in Hindi"
                            InputLabelProps={{ style: { color: 'hsl(0deg 0% 50%) !important' } }}
                            variant="outlined"
                            value={hindiName}
                            onChange={(e) => { e.target.value.length <= 100 && setHindiName(e.target.value) }}
                        />
                    </div>
                    <div style={{ width: '100%' }} >
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("product_name")} (Kannada)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Product Name in Kannada"
                            InputLabelProps={{ style: { color: 'hsl(0deg 0% 50%) !important' } }}
                            variant="outlined"
                            value={kannadaName}
                            onChange={(e) => { e.target.value.length <= 100 && setKannadaName(e.target.value) }}
                        />
                    </div>

                    {/* Existing Quantity and Value fields remain the same */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                        <div style={{ width: '90%' }}>
                            <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("quantity")}</Typography>
                            <Select
                                id="prodInput"
                                className="subProdInput"
                                classNamePrefix="Select"
                                isSearchable={true}
                                name="quantity"
                                options={options}
                                placeholder='Quantity'
                                //@ts-ignore
                                defaultValue={quantity}
                                onChange={(value: any) => setQuantity(value)}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("value")}</Typography>
                            <TextField
                                id="prodInput"
                                className="prodInput"
                                placeholder={t("value")}
                                variant="outlined"
                                value={value}
                                onChange={(e) => { setValue(e.target.value) }}
                            />
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("price")}</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder={t("unit_price")}
                            variant="outlined"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>

                    {/* Upload Media section remains the same */}
                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("upload_media")}</Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div className="img-upload" onClick={(e) => {
                                //@ts-ignore
                                hiddenFileInput.current.click();
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={imgUploadArrow} alt="" width={20} height={20} />
                                    <Typography variant="body1" style={{ color: "#ff8502", fontWeight: 'bold' }} >{t("upload_caps")}</Typography>
                                </div>
                            </div>
                            {selectedImgs && (
                                selectedImgs.map((img: any) => {
                                    return (
                                        <div className="previewImg" key={img.id}>
                                            <CancelIcon
                                                className="cross-circle-cancel-img"
                                                id={img.id}
                                                ref={idRef}
                                                onClick={() => { handleRemoveImg(img) }}
                                            />
                                            <img
                                                src={img.src}
                                                alt=""
                                                style={{
                                                    width: "120px",
                                                    height: '120px',
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                    borderRadius: '4px'
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            )}
                        </div>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleImgUpload}
                            accept="image/jpeg, image/jpg"
                            style={{ display: 'none' }}
                        />
                    </div>

                    {/* Description fields in different languages */}
                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("description")} (English)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Description in English"
                            variant="outlined"
                            multiline
                            rows={3}
                            value={description}
                            onChange={(e) => e.target.value.length <= 300 && setDescription(e.target.value)}
                        />
                    </div>

                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("description")} (Hindi)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Description in Hindi"
                            variant="outlined"
                            multiline
                            rows={3}
                            value={hindiDescription}
                            onChange={(e) => e.target.value.length <= 300 && setHindiDescription(e.target.value)}
                        />
                    </div>

                    <div style={{ width: '100%' }}>
                        <Typography variant='body1' style={{ fontWeight: 600, color: 'black', fontFamily: 'Poppins', fontSize: 14 }} gutterBottom>{t("description")} (Kannada)</Typography>
                        <TextField
                            id="prodInput"
                            className="prodInput"
                            placeholder="Description in Kannada"
                            variant="outlined"
                            multiline
                            rows={3}
                            value={kannadaDescription}
                            onChange={(e) => e.target.value.length <= 300 && setKannadaDescription(e.target.value)}
                        />
                    </div>
                </form>
            </DialogContent>
            <DialogActions className="cancelButtonCon">
                <Button
                    onClick={() => {
                        (addProd ? setAddProd(false) : editProd ? setEditProd(false) : null)
                    }}
                    variant='outlined'
                    className="ButtonEditw"
                >
                    {t("cancel")}
                </Button>
                <Button onClick={handleAddProduct} disabled={saveLoader} className="saveButton">
                    {saveLoader ? <CircularProgress size={24} className="loader" /> : t("save")}
                </Button>
            </DialogActions>
        </> : null)

    )

}

//Customizable Area End